import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./SubCategoryMasterAction.css";
const SubCategoryMasterAction = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  const [subCatId, setSubCatId] = useState(null);
  const [categoryName, setCategoryName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [name, setName] = useState("");
  const [displayIn, setDisplayIn] = useState("Yes");
  useEffect(() => {
    Notiflix.Loading.remove();
    PostApiCall.postRequest({ columns: "*" }, "getCategoryDetails").then(
      (resultdes) =>
        resultdes.json().then((obj) => {
          setCategoryName(obj.message);
          setSelectedCategory(obj.message[0].FLD_Category_Id);
          Notiflix.Loading.remove();
        })
    );
    Notiflix.Loading.remove();
    if (params.action == "edit" && location.state != undefined) {
      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_Sub_Cat_Id = ${location.state}`,
          columns: "*",
        },
        "getSubCategory"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setSubCatId(obj.message[0].FLD_Sub_Cat_Id);
            setSelectedCategory(obj.message[0].FLD_Cat_Id);
            setName(obj.message.fld_name);
            setDisplayIn(obj.message[0].FLD_Enabled_Flag);
          }
        })
      );
    }
  }, []);
  function onSaveSubCat() {
    PostApiCall.postRequest(
      {
        subCatId: subCatId,
        catId: selectedCategory,
        name: name,
        enabledFlag: displayIn,
      },
      "updatesubcategory"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Sub Category added successfully");
          navigate("/subcategorymaster");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <div className="container py-5">
        <div className="form-container row ">
          <div className="col-md-8 mx-auto shadow p-4">
            <div className="form-box align-items-center d-md-flex border-light mb-4">
              <label>Select Category</label>
              <span style={{ color: "red" }}>*</span>
              <select
                name="cars"
                id="cars"
                className="form-control"
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                }}
              >
                {categoryName.map((item, index) => {
                  return (
                    <>
                      <option value={item.FLD_Category_Id}>
                        {item.FLD_Name} -{" "}
                        {item.FLD_Enabled_Flag == "Yes" ||
                          item.FLD_Enabled_Flag == "Active"
                          ? "Active"
                          : "Inactive"}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="form-box align-items-center d-md-flex border-light mb-4">
              <label>Sub Category Name</label>
              <span style={{ color: "red", marginTop: "1.5rem" }}>*</span>
              <input
                type="text"
                value={name}
                name="name"
                className="form-control"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="form-box align-items-center d-md-flex border-light mb-4">
              <label>Display In Website</label>
              <div className="d-flex">
                <span className="d-flex me-2">
                  <input
                    type="radio"
                    name="status"
                    className="ms-2 me-1"
                    checked={displayIn == "Yes" ? true : false}
                    onChange={() => {
                      setDisplayIn("Yes");
                    }}
                  />
                  Yes
                </span>
                <span className="d-flex">
                  <input
                    type="radio"
                    name="status"
                    className="me-1"
                    checked={displayIn == "No" ? true : false}
                    onChange={() => {
                      setDisplayIn("No");
                    }}
                  />
                  No
                </span>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn ms-auto"
                onClick={() => {
                  onSaveSubCat();
                }}
              >
                submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubCategoryMasterAction;
