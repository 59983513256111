import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { FaRegBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar.css";
// import SideBar from "../SideBar/SideBar";
import TextField from "@mui/material/TextField";
import Notiflix from "notiflix";
import { HiRefresh } from "react-icons/hi";
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import Sidebar from "../SideBar/SideBar";
import SideBarOffline from "../SideBar/SideBarOffline";
import logo from "../../assets/images/dish.png"
import moment from "moment";
import { store } from "../Context/store";

function SiteNavbar() {
  let navigate = useNavigate();
  let lastLogin = JSON.parse(localStorage.getItem("LoginDetail"))?.fld_lastlogin
  const [scroll, setScroll] = useState(false);
  const { setCurrencyCode } = useContext(store)
  // const [Offcanvas1, setOffcanvas1] = useState(false);
  // const handleCloseoffcanvas = () => setOffcanvas1(false);
  // const handleShowoffcanvas = () => setOffcanvas1(true);
  const [isShownUserOptions, setIsShownUserOptions] = useState(false);
  const [isShownNotifications, setIsShownNotifications] = useState(false);
  const [newOrders, setNewOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [greeting, setGreeting] = useState("");
  const [collapsed, setCollapsed] = React.useState(true);
  const [bodyCollapsed, setBodyCollapsed] = React.useState(true);
  const [qrDownloadUrl, setQrDownloadUrl] = React.useState(null);
  const logDetails = JSON.parse(localStorage.getItem("LoginDetail"));

  if (bodyCollapsed == true) {
    var bodyElement = document.getElementsByTagName("html")[0];
    bodyElement.classList.remove("bodycollapse");
  } else {
    var bodyElement = document.getElementsByTagName("html")[0];
    bodyElement.classList.add("bodycollapse");
  }
  const handleSidebarCollapse = () => {
    setCollapsed(!collapsed);
    setBodyCollapsed(!bodyCollapsed);
  };

  const [showChangePassword, setshowChangePassword] = useState(false);

  const handleCloseChangePassword = () => setshowChangePassword(false);
  const handleshowChangePassword = () => setshowChangePassword(true);

  const [showSettings, setshowSettings] = useState(false);

  const handleCloseSettings = () => setshowSettings(false);
  const handleshowSettings = () => setshowSettings(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }

    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      setGreeting("Good Morning");
    } else if (curHr < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
    if (localStorage.getItem("LoginDetail") != null) {
      if (
        JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
        JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3
      ) {
        GetApiCall.getRequest("getOrdersNotifications").then((resultdes) =>
          resultdes.json().then((obj) => {
            var newOrders = 0;
            var pendingOrders = 0;
            var completedOrders = 0;
            if (obj.data?.length != 0) {
              obj.data?.map((count) => {
                if (count.fld_cart_status == "New") {
                  newOrders = count.data;
                  pendingOrders = count.data;
                } else if (count.fld_cart_status == "Accepted") {
                  completedOrders = count.data;
                }
              });
              setNewOrders(newOrders);
              setPendingOrders(pendingOrders);
              setCompletedOrders(completedOrders);
              Notiflix.Loading.remove();
            } else {
              Notiflix.Loading.remove();
            }
          })
        );
      }
    }
    PostApiCall.postRequest(
      {
        whereClause: `where fld_client_id = ${JSON.parse(localStorage.getItem("LoginDetail"))?.fld_client_id}`
      },
      "GetPdfUrl"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setQrDownloadUrl(obj.data[0]?.fld_digital_qr)
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error occur!");
        }
      })
    );
    getClientData();
  }, []);

  const getClientData = () => {
    PostApiCall.postRequest({}, "getClientData").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          setCurrencyCode(obj1.message[0]?.fld_currency);
        } else {
        }
      })
    );
  };
  function onSavePassword() {
    if (oldPassword != "" || newPassword != "" || confirmPassword != "") {
      if (oldPassword != "" && newPassword != "" && confirmPassword != "") {
        if (
          newPassword.toLocaleLowerCase() == confirmPassword.toLocaleLowerCase()
        ) {
          Notiflix.Loading.dots();
          PostApiCall.postRequest(
            {
              userid: JSON.parse(localStorage.getItem("LoginDetail")).fld_userid,
              newpassword: newPassword,
              actiondate: new Date(),
            },
            "UpdateUserPassword"
          ).then((result) =>
            result.json().then((obj) => {
              if (result.status == 200 || result.status == 201) {
                Notiflix.Notify.success("Password changed successfully");
                handleCloseChangePassword();
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
                Notiflix.Loading.remove();
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Something went wrong!");
              }
            })
          );
        } else {
          Notiflix.Notify.failure(
            "New password & Confirm password must be same!"
          );
        }
      } else {
        Notiflix.Notify.failure("Please enter all fields!");
      }
    }
  }
  return (
    <>
      <Navbar
        bg="white"
        expand="false"
        className={scroll ? "fixed-top shadow-sm" : "shadow-sm"}>
        <Container fluid className="flex-nowrap">
          <div className="d-flex align-items-center">
            {/* <Navbar.Toggle
              className="border-0 px-0"
              onClick={handleShowoffcanvas}
            /> */}
            <div
              className="nav-bar-link ms-2 me-3 fs-3"
              onClick={() => handleSidebarCollapse()}>
              <RxHamburgerMenu />
            </div>

            {JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype ==
              2 ?
              <Navbar.Brand href="/SalesDashboard" className="me-0 py-0 ms-3">
                <h3 className="mb-0 text-black fw-bold">
                  {
                    JSON.parse(localStorage.getItem("LoginDetail"))
                      ?.fld_companyname
                  }
                </h3>
              </Navbar.Brand>
              :
              <Navbar.Brand href="/dashboard" className="me-0 py-0 ms-3">
                <h3 className="mb-0 text-black fw-bold">
                  {
                    JSON.parse(localStorage.getItem("LoginDetail"))
                      ?.fld_companyname
                  }
                </h3>
              </Navbar.Brand>
            }

          </div>
          <div className="header-right">
            {JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype ==
              2 ? (
              <></>
            ) : (
              <div className="notification me-3 d-flex">
                <a
                  href="#"
                  className="notification-link"
                  onMouseEnter={() => setIsShownNotifications(true)}>
                  <FaRegBell className="notification-icon" />
                  <span className="badge light text-white">
                    {newOrders + pendingOrders + completedOrders}
                  </span>
                </a>
                <a
                  href="#"
                  className="notification-link ms-3"
                  onClick={() => window.location.reload()}>
                  <HiRefresh className="notification-icon" />
                </a>
                {isShownNotifications && (
                  <div
                    className="notification-dropdown-menu"
                    onMouseLeave={() => setIsShownNotifications(false)}>
                    <Card className="shadow">
                      <Card.Body>
                        <a href="" className="d-block">
                          <p className="text-end text-danger">View All</p>
                        </a>
                        <ul className="notification-list-wrapper">
                          <li>
                            <div className="notification-list">
                              <p className="label">New Orders</p>
                              <span className="count">{newOrders}</span>
                            </div>
                          </li>

                          <li>
                            <div className="notification-list">
                              <p className="label">pending orders</p>
                              <span className="count">{pendingOrders}</span>
                            </div>
                          </li>
                          <li>
                            <div className="notification-list">
                              <p className="label">completed orders</p>
                              <span className="count">{completedOrders}</span>
                            </div>
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </div>
            )}

            <div
              className="header-profile"
              onMouseLeave={() => setIsShownUserOptions(false)}>
              <div className="header-info">
                <small>{greeting}</small>
              </div>
              {/* <img src={Logo} width="20" alt=""></img> */}
              <div
                className="profile-pic"
                onMouseEnter={() => setIsShownUserOptions(true)}>
                {/* <FaRegUser className="profile-icon" /> */}
                {JSON.parse(localStorage.getItem("LoginDetail"))?.fld_storelogo ?
                  <img
                    src={JSON.parse(localStorage.getItem("LoginDetail"))?.fld_storelogo}
                    className="profile-icon"
                    alt=""
                  />
                  : <img src={logo} alt="" />}
              </div>
              {isShownUserOptions && (
                <div className="profile-dropdown-menu">
                  <Card className="shadow">
                    <Card.Body>
                      <div className="header-info header-info-mobile">
                        <small className="me-2">{greeting}</small>
                      </div>
                      <ul className="user-options">
                        {logDetails?.client_mail != "Sales@globaltrendz.com" &&
                          <>
                            <li>
                              <Link to="/profile" className="nav-link">
                                My profile
                              </Link>
                            </li>
                            <li>
                              {qrDownloadUrl !== null ?
                                <a href={qrDownloadUrl} target="_blank" className="nav-link">
                                  Download QR
                                </a>
                                :
                                <a href="javascript:void(0)" className="nav-link download-qr-color-disable" disabled>
                                  Download QR
                                </a>
                              }
                            </li>
                          </>
                        }
                        {/* <li>
                          <Link to="/profile" className="nav-link">
                            Purchase History
                          </Link>
                        </li> */}
                        {/* <li>
                          <Button
                            variant="white"
                            onClick={handleshowSettings}
                            className="nav-link border-0 w-100 text-start"
                          >
                            Settings
                          </Button>
                        </li> */}
                        <li>
                          <Button
                            variant="white"
                            onClick={handleshowChangePassword}
                            className="nav-link border-0 w-100 text-start">
                            Change Password
                          </Button>
                        </li>
                        <li className="border-bottom pb-2">
                          <a
                            href="/"
                            className="nav-link"
                            onClick={() => {
                              localStorage.removeItem("LoginDetail");
                              localStorage.removeItem("access");
                            }}>
                            logout
                          </a>
                        </li>
                        <li className="p-2 fw-bold">Last Login</li>
                        <li className="p-2 pt-1">{moment(lastLogin).format("Do MMMM YYYY, h:mm a")}
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
          </div>

          <Offcanvas
            show={showChangePassword}
            onHide={handleCloseChangePassword}
            placement="end"
            className="kot-offcanvas">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <h5 className="fw-bold text-white">Change Password</h5>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="kot-offcanvas-box">
                <TextField
                  id="outlined-basic"
                  label="Old Password"
                  variant="outlined"
                  className="mt-3 w-100"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="New Password"
                  variant="outlined"
                  className="mt-3 w-100"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Confirm Password"
                  variant="outlined"
                  className="mt-3 w-100"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
              <div className="mt-3">
                <Button
                  variant="white"
                  className="kot-btn"
                  onClick={() => onSavePassword()}>
                  Change Password
                </Button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas
            show={showSettings}
            onHide={handleCloseSettings}
            placement="end"
            className="kot-offcanvas">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <h5 className="fw-bold text-white">Settings</h5>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="kot-offcanvas-box"></div>
              <div className="mt-3">
                <Button variant="white" className="kot-btn">
                  Save Settings
                </Button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
      {JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype == 2 ? (
        <SideBarOffline collapsed={collapsed} setCollapsed={handleSidebarCollapse} />
      ) : (
        <Sidebar collapsed={collapsed} setCollapsed={handleSidebarCollapse} />
      )}
    </>
  );
}

export default SiteNavbar;
