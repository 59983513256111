import Notiflix from "notiflix";
import { useContext, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BiDish, BiDollar, BiReceipt, BiRupee, BiUser } from "react-icons/bi";
import { GiCoins } from "react-icons/gi";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { PiHandCoinsLight } from "react-icons/pi";
import PostApiCall from "../../helper/Api";
import "../../pages/Dashboard/Dashboard.css";
import { store } from "../Context/store";

export default function DashboardCard() {
  const { allCounts, setAllCounts, currencyCode } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      PostApiCall.postRequest(({
        filter: "today"
      }), "GetCountChips").then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.message.length != 0) {
            setAllCounts(obj.message[0]);

            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        })
      );
    }
  }, []);
  return (
    <><Col md={4} lg={3}>
      <Card className="shadow border-light top-four-card px-3 px-lg-4 mb-3 mb-lg-4">
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h2 className="fw-bold text-black">
                  {allCounts.items_count}
                </h2>
                <h6 className="text-black">Menu Items</h6>
              </div>
              <div className="dash-card-icons">
                <BiDish />
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col><Col md={4} lg={3}>
        <Card className="shadow border-light top-four-card px-3 px-lg-4 mb-3 mb-lg-4">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">{allCounts.orders_count}</h2>
                  <h6 className="text-black">Orders</h6>
                </div>
                <div className="dash-card-icons">
                  <BiReceipt />
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col><Col md={4} lg={3}>
        <Card className="shadow border-light top-four-card px-3 px-lg-4 mb-3 mb-lg-4">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">
                    {allCounts.customer_count}
                  </h2>
                  <h6 className="text-black">Customers</h6>
                </div>
                <div className="dash-card-icons">
                  <BiUser />
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col><Col md={4} lg={3}>
        <Card className="shadow border-light top-four-card px-3 px-lg-4 mb-3 mb-lg-4">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">{allCounts.income_count_total}</h2>
                  <h6 className="text-black">Income</h6>
                </div>
                <div className="dash-card-icons">
                  {currencyCode == '$' ? <BiDollar /> : <BiRupee />}
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      {/* <Col lg={12} className="mt-4">
        <Row> */}
      <Col md={4} lg={3}>
        <Card className="shadow border-light px-2 mb-3 mb-lg-0">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">
                    {allCounts.income_count_delivery}
                  </h2>
                  <h6 className="text-black">Delivery</h6>
                </div>
                <div className="dash-card-icons">
                  <MdOutlineDeliveryDining />
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} lg={3}>
        <Card className="shadow border-light px-2 mb-3 mb-lg-0">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">
                    {allCounts.income_count_takeaway}
                  </h2>
                  <h6 className="text-black">Take Away</h6>
                </div>
                <div className="dash-card-icons">
                  <LiaShoppingBagSolid />
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6} lg={3}>
        <Card className="shadow border-light px-2 mb-3 mb-lg-0">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">
                    {allCounts.income_count_shipping}
                  </h2>
                  <h6 className="text-black">Delivery Charges</h6>
                </div>
                <div className="dash-card-icons">
                  <GiCoins />
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6} lg={3}>
        <Card className="shadow border-light px-2 mb-3 mb-lg-0">
          <Card.Body>
            <Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold text-black">
                    {allCounts.income_count_tips}
                  </h2>
                  <h6 className="text-black">Tips</h6>
                </div>
                <div className="dash-card-icons">
                  <PiHandCoinsLight />
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      {/* </Row>
      </Col> */}
    </>
  );
}
