import React from "react";
import "./Menu.css";
import { Col, Container, Row } from "react-bootstrap";

function Menu() {
  return (
    <section className="pb-lg-5 pt-4 margin-left">
      <Container fluid>
        <Row>
          <Col>
            {/* <div className="row"> */}
            <div className="dash-heading">
                      <h5 className="fw-bold text-black">
                      Categories
              </h5>
                <p>??</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Menu;
