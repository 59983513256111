import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "./Footer.css";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialLinkedin,
  SlSocialYoutube,
} from "react-icons/sl";
import Logo from "../../assets/images/grubDigest_logo.png";

function Footer() {
  return (
    <>
      <footer className="bg-light margin-left">
        <div className="bg-light py-lg-3 py-2">
          <Container fluid>
            <Row className="d-flex align-items-center">
              <Col lg={6} className="">
                <div className="copyright ">
                  Copyright &copy;{" "} {moment().format("YYYY")} &nbsp;
                  <a
                    href="http://grubdigest.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none">
                    {" "}
                     Grub Digest
                  </a>
                  . All right reserved.
                </div>
              </Col>
              <Col
                lg={6}
                className="d-flex align-items-center justify-content-lg-end">
                  <p className="fw-bold">
                    Powered By 
                  </p>
                <div className="copyright ps-2">
                  <a
                    href="http://grubdigest.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none">
                    <img src={Logo} className="logo" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
}

export default Footer;
