import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./Kot.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";

import { AiOutlineEye } from "react-icons/ai";
import {
  BsJournalCheck,
  BsReceipt
} from "react-icons/bs";
import { CiReceipt } from "react-icons/ci";
import { MdFoodBank } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { TfiReceipt } from "react-icons/tfi";

import moment from "moment";
import Notiflix from "notiflix";
import Button from "react-bootstrap/Button";
import { BiRupee } from "react-icons/bi";
import { store } from "../../components/Context/store";
import OrderHistoryDrawer from "../../components/OrderHistoryDrawer/OrderHistoryDrawer";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
function Kot() {
  const {
    kotChipsCount,
    setKotChipsCount,
    allKotDetails,
    setAllKotDetails,
    allRecentOrders,
    setAllRecentOrders,
    orderNumber,
    setOrderNumber,
    orderDateTime,
    setOrderDateTime,
    orderType,
    setOrderType,
    customerName,
    setCustomerName,
    customerMobile,
    setCustomerMobile,
    paymentDetails,
    setPaymentDetails,
    address,
    setAddress,
    kotFilterVal,
    setKotFilterVal,
    setOrderDetails,
    landmark,
    setLandmark,
    cartId,
    setCartId,
    currencyCode
  } = useContext(store);
  const [show, setShow] = useState(false);
  const [kotItemDetails, setKotItemDetails] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const firstObjectsMap = new Map();

  useEffect(() => {
    Notiflix.Loading.dots();
    GetKOTChipsCount();
    getKotDetails();
    // GetApiCall.getRequest("GetRecentOrders").then((resultdes) =>
    //   resultdes.json().then((obj) => {
    //     if (obj.data.length != 0) {
    //       setAllRecentOrders(obj.data);
    //
    //       Notiflix.Loading.remove();
    //     } else {
    //       Notiflix.Loading.remove();
    //       Notiflix.Notify.failure("No Records Exits");
    //     }
    //   })
    // );
  }, []);
  const GetKOTChipsCount = () => {
    GetApiCall.getRequest("GetKOTChipsCount").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setKotChipsCount(obj.data[0]);

          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }
  const getKotDetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: ``
      }
      , "GetKOTDetails").then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            setKotItemDetails(obj.data);
            const kotData = [
              ...new Map(
                obj.data.map((item) => [item["fld_cart_id"], item])
              ).values(),
            ];
            setAllKotDetails(kotData);
            //
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        })
      );
  }

  const UpdateOrderStatus = (cartId, status) => {
    PostApiCall.postRequest({
      id: cartId,
      status: status,
    },
      "UpdateOrderStatus"
    ).then((resultcategory) =>
      resultcategory.json().then((obj1) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          getKotDetails();
          GetKOTChipsCount();
        } else {
        }

      })
    );
  }
  const updateOrderStatusAccepted = (cartId, status, address2, mobile, delivery_ext_id, ordertype, ordervalue, customer_name, tip_D) => {

    // if (ordertype == 'Delivery') {
    PostApiCall.postRequest(
      {
        business_name: "Masala of India",
        external_delivery_id: delivery_ext_id,
        pickup_address: "507 NE Northgate Way Bldg G, Seattle, WA 98125",
        pickup_phone_number: "12064171118",
        dropoff_address: address2,
        dropoff_phone_number: mobile,
        order_value: parseFloat(ordervalue).toFixed(2) * 100,
        customer_first_name: customer_name,
        customer_last_name: customer_name,
        tip: tip_D == null ? 0 : parseFloat(tip_D).toFixed(2) * 100,
      },
      "pidge_login_create_order"
    ).then((res) => {
      if (res.status == 200 || res.status == 201) {
        res.json().then((obj) => {
          if (res.status == 200 || res.status == 201) {
            UpdateOrderStatus(cartId, status)
          } else {
            Notiflix.Notify.failure(obj.data.message);
          }
        }
        )
      } else {
        console.log("error")
      }
    }
    );
    // } else {
    //   // UpdateOrderStatus(cartId, status)
    //   console.log()
    // }
  }

  const updateOrderStatusDispatched = (cartId, status, mobile, ordertype, delivery_external_id) => {
    if (ordertype == 'Delivery') {
      PostApiCall.postRequest({
        deliveryNumber: delivery_external_id,
      }, "GetDoordashDeliveryDetails")
        .then(res => res.json().then((obj) => {
          if (res.status == 200 || res.status == 201) {
            PostApiCall.postRequest({
              TrackingUrl: obj.tracking_url,
              phoneNumber: mobile
            }, "smsDispatch").then((res) =>
              res.json().then((obj) => {
                if (res.status == 200 || res.status == 201) {
                  UpdateOrderStatus(cartId, status)
                } else {

                  Notiflix.Notify.failure("Something went wrong, Please try again later.");
                }
              })
            );

          } else {
            UpdateOrderStatus(cartId, status)
          }
        }))

    } else {
      UpdateOrderStatus(cartId, status)
    }
  }
  return (
    <>
      {console.log(allKotDetails)}
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid className="inner-page-height">
          <Row>
            <Col lg={12}>
              <div className="dash-heading mb-3">
                <h5 className="fw-bold text-black">Kitchen Order Tickets</h5>
                <p>A Complete Customer Order Details</p>
              </div>

              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                //   freeMode={true}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper pb-5"
              >
                <SwiperSlide>
                  <Card className="shadow border-light top-four-card px-3 px-lg-4" onClick={() => { setKotFilterVal("NEW") }}>
                    <Card.Body>
                      <Card.Text>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h2 className="fw-bold text-black">
                              {kotChipsCount.fld_total_new_orders}
                            </h2>
                            <h6 className="text-black">New</h6>
                          </div>
                          <div className="dash-card-icons">
                            <CiReceipt />
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="shadow border-light top-four-card px-3 px-lg-4" onClick={() => { setKotFilterVal("Accepted") }}>
                    <Card.Body>
                      <Card.Text>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h2 className="fw-bold text-black">
                              {kotChipsCount.fld_total_accepted_orders}
                            </h2>
                            <h6 className="text-black">Accepted</h6>
                          </div>
                          <div className="dash-card-icons">
                            <BsJournalCheck />
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="shadow border-light top-four-card px-3 px-lg-4" onClick={() => { setKotFilterVal("Dispatched") }}>
                    <Card.Body>
                      <Card.Text>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h2 className="fw-bold text-black">
                              {kotChipsCount.fld_total_dispatched_orders}
                            </h2>
                            <h6 className="text-black">Dispatched</h6>
                          </div>
                          <div className="dash-card-icons">
                            <TbTruckDelivery />
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="shadow border-light top-four-card px-3 px-lg-4" onClick={() => { setKotFilterVal("Delivered") }}>
                    <Card.Body>
                      <Card.Text>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h2 className="fw-bold text-black">
                              {kotChipsCount.fld_total_delivered_orders}
                            </h2>
                            <h6 className="text-black">Delivered</h6>
                          </div>
                          <div className="dash-card-icons">
                            <MdFoodBank />
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="shadow border-light top-four-card px-3 px-lg-4">
                    <Card.Body>
                      <Card.Text>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h2 className="fw-bold text-black">
                              {kotChipsCount.fld_total_toays_orders}
                            </h2>
                            <h6 className="text-black">Today's</h6>
                          </div>
                          <div className="dash-card-icons">
                            <BsReceipt />
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </Col>

            {/* <Card.Body>
                <Card.Text> */}

            {allKotDetails.length != 0 ? (
              allKotDetails.filter((value) => {
                if (value.fld_cart_status == kotFilterVal) {
                  return value
                }
              }).map((kot) => {
                return (
                  <Col md={6} lg={4} className="mb-4">
                    <div className="shadow border-light top-four-card kot-main-card">
                      <div className="kot-card border-bottom pt-4 pb-2 px-4 w-100 bg-light">
                        <TfiReceipt />
                        <div>
                          <h5 className="fw-bold text-black mb-1">
                            {kot.fld_orderNumber}
                          </h5>
                          <p>{kot.CustomerName}</p>
                          <p className="text-black">
                            <b>Placed On</b>{" "}
                            {kot.fld_cart_date +
                              "  " +
                              moment(kot.fld_cart_time).format("HH:MM")}
                          </p>
                        </div>
                      </div>
                      <div className="kot-card-mid-main mx-1">
                        {kotItemDetails.map((item) => {
                          return item.fld_cart_id == kot.fld_cart_id ? (
                            <div className="kot-card-mid mt-3 pb-2 px-3 border-bottom">
                              <p className="text-dark fw-bold mb-1 non-veg-icon">
                                {" "}
                                {item.fld_quantity} x{item.variant_name}
                              </p>
                              {item.spice_level &&
                                <div className="mt-2">
                                  <p>
                                    {item.spice_level}
                                  </p>
                                </div>
                              }
                            </div>
                          ) : (
                            <></>
                          );
                        })}
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100 border-top pt-4 px-4">
                        <div>
                          <h6 className="text-dark fw-bold mb-0">
                            {kot.Total_Item_Quantity} Items
                          </h6>
                          <p className="text-dark">{kot.Payment_Mode}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            variant="primary"
                            onClick={() => {
                              setCartId(kot.fld_cart_id);
                              PostApiCall.postRequest(
                                {
                                  cartid: kot.fld_cart_id,
                                },
                                "getOrderById"
                              ).then((result) =>
                                result.json().then((obj) => {

                                  if (
                                    result.status == 200 ||
                                    result.status == 201
                                  ) {
                                    PostApiCall.postRequest(
                                      {
                                        id: null,
                                        orderid: kot.fld_cart_id,
                                        stateid: null,
                                      },
                                      "getOrderDetails"
                                    ).then((results1) =>
                                      results1.json().then((obj1) => {
                                        if (
                                          results1.status == 200 ||
                                          results1.status == 201
                                        ) {
                                          setOrderDetails(obj1.data);
                                        } else {
                                        }
                                      })
                                    );
                                    setOrderNumber(
                                      obj.data[0].fld_order_number
                                    );
                                    setOrderDateTime(
                                      obj.data[0].fld_cart_date +
                                      "  " +
                                      // moment(
                                      obj.data[0].fld_cart_time
                                      // ).format("HH:MM")
                                    );
                                    setOrderType(obj.data[0].fld_order_type);
                                    setCustomerName(obj.data[0].customer_name);
                                    setCustomerMobile(obj.data[0].fld_mobile);
                                    setPaymentDetails(
                                      obj.data[0].fld_payment_mode
                                    );
                                    setAddress(
                                      obj.data[0].fld_address_1 +
                                      " " +
                                      obj.data[0].fld_address_2
                                    );
                                    setLandmark(obj.data[0].fld_landmark);
                                    handleShow();
                                  }
                                })
                              );
                            }}
                            className="btn kot-btn kot-btn-2 me-2 p-2 "
                          >
                            <AiOutlineEye />
                          </Button>
                          {kot.fld_cart_status == "NEW" &&
                            <button
                              type="button"
                              className="btn kot-btn"
                              onClick={() => {
                                // Notiflix.Loading.dots();
                                updateOrderStatusAccepted(kot.fld_cart_id, "Accepted", kot.fld_address, kot.fld_mobile, kot.delivery_ext_id, kot.fld_order_type, kot.Total_Inclusive_Tax, kot.CustomerName, kot.fld_tip)
                                // PostApiCall.postRequest(
                                //   {
                                //     id: kot.fld_cart_id,
                                //     status: "Accepted",
                                //   },
                                //   "updateOrderStatus"
                                // ).then((results1) =>1
                                //   results1.json().then((obj1) => {
                                //     if (
                                //       results1.status == 200 ||
                                //       results1.status == 201
                                //     ) {
                                //       Notiflix.Notify.success(
                                //         "Order Status Updated Successfully"
                                //       );
                                //       getKotDetails();
                                //       Notiflix.Loading.remove();
                                //       // window.location.reload();
                                //     } else {
                                //       Notiflix.Loading.remove();
                                //       Notiflix.Notify.failure("Error Occurred");
                                //     }
                                //   })
                                // );
                              }}
                            >
                              Accept Order
                            </button>
                          }
                          {kot.fld_cart_status == "Accepted" &&
                            <button
                              type="button"
                              className="btn kot-btn"
                              onClick={() => {
                                Notiflix.Loading.dots();
                                updateOrderStatusDispatched(kot.fld_cart_id, "Dispatched", kot.fld_mobile, kot.fld_order_type, kot.delivery_ext_id)
                              }}
                            >
                              Dispatch Order
                            </button>
                          }
                          {kot.fld_cart_status == "Dispatched" &&
                            <button
                              type="button"
                              className="btn kot-btn"
                              onClick={() => {
                                Notiflix.Loading.dots();
                                UpdateOrderStatus(kot.fld_cart_id, "Delivered")
                              }}
                            >
                              Delivered
                            </button>
                          }
                        </div>
                      </div>
                      <div className="border-top kot-save kot-save-top">
                        <h6 className="text-white fw-bold">
                          Total Order Value {currencyCode} {" "}
                          {parseFloat(kot.fld_order_amount).toFixed(2)}
                        </h6>
                      </div>
                      {/* </Card.Text>
              </Card.Body> */}
                    </div>
                  </Col>
                );
              })
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <p>No new orders.</p>
              </div>
            )}
          </Row>
        </Container>
      </section>
      <OrderHistoryDrawer offcanvasshow={show} offcanvasclose={setShow} />
    </>
  );
}

export default Kot;
