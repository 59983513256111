import { DatePicker } from 'antd';
import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { BsFillArrowRightCircleFill, BsFilterSquare } from "react-icons/bs";
import { FaDownload } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { store } from "../../components/Context/store";
import PostApiCall from "../../helper/Api";
import "./Customers.css";
const { RangePicker } = DatePicker;
function Customers() {
  const { allCustomersList, setAllCustomersList, currencyCode } = useContext(store);
  const [filterRecord, setFilterRecord] = useState(null);
  const [customRange, setCustomRange] = useState(false)
  const [data, setData] = useState([]);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    //sticky filter
    window.addEventListener("scroll", () => {
      setScroll(window.screenY > 50);
    });

    Notiflix.Loading.dots();
    if (
      localStorage.getItem("LoginDetail") != null &&
      (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
        JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)
    ) {
      PostApiCall.postRequest(
        {
          filter: "today",
        },
        "getCustomerListFiltered"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.message.length != 0) {
            setAllCustomersList(obj.message);
            setData(obj.message);
            Notiflix.Loading.remove();
          } else {
            setAllCustomersList(obj.message);
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        })
      );
    }
  }, []);

  function getCustomerDetails(filter, fromdate, todate) {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        filter: filter,
        fromDate: fromdate,
        toDate: todate
      },
      "getCustomerListFiltered"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.message.length != 0) {
          setAllCustomersList(obj.message);
          setData(obj.message);
          Notiflix.Loading.remove();
        } else {
          setAllCustomersList(obj.message);
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }
  const DATE_FORMAT = 'MM-DD-YYYY';
  const handleChange = (date, dateArray) => {
    // console.log(date)
    if (dateArray.length == 2) {
      console.log(dateArray)
      getCustomerDetails("CustomRange", dateArray[0], dateArray[1]);
    }
    // setSelectedDates(dateArray);
  };

  const csvdata = {
    reportcolumns: [
      {
        title: "Customer Name",
        label: "Customer Name",
        field: "fld_name",
        width: 400,
        dataIndex: "fld_name",
        key: "fld_name"
      },
      {
        title: "Customer Mobile",
        label: "Customer Mobile",
        field: "fld_phone_number",
        width: 400,
        dataIndex: "fld_phone_number",
        key: "fld_phone_number"
      },
      {
        title: "Customer Email",
        label: "Customer Email",
        field: "fld_email_address",
        dataIndex: "fld_email_address",
        key: "fld_email_address"
      },
      {
        title: "Total Orders",
        label: "Total Orders",
        field: "total_Orders",
        width: 150,
        dataIndex: "total_Orders",
        key: "total_Orders",
      },
      {
        title: "Total Orders Amount",
        label: "Total Orders Amount",
        field: "total_order_amt",
        width: 150,
        dataIndex: "total_order_amt",
        key: "total_order_amt",
      }
    ],
  };

  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid className="inner-page-height">
          <Row>
            <Col>
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Customers</h5>
                <p>Registerd Customers of Restaurant Name</p>
              </div>
            </Col>
            <Col lg={12} className="mt-4">
              {/* <Col lg={12} className={scroll ? "mt-4 filter-top-fix" : "mt-4"}> */}
              <Card className="shadow border-light">
                <Card.Body>
                  <Card.Text>
                    <div className="row justify-content-lg-between align-items-lg-center">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <h5 className="fw-bold text-black">
                          Filter Data by Date
                        </h5>
                        <p className="text-black">Select Date Range</p>
                      </div>
                      <div className="tab-filter col-lg-9 ps-0">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first">
                          <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="first"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("today");
                                }}>
                                Today
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="second"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("tilldate");
                                }}>
                                Till Date
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="third"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("yesterday");
                                }}>
                                Yesterday
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="forth"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("last7days");
                                }}>
                                Last 7 days
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="fifth"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("last30days");
                                }}>
                                Last 30 days
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="sixth"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("thisweek");
                                }}>
                                This Week
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="seventh"
                                onClick={() => {
                                  setCustomRange(false);
                                  getCustomerDetails("thismonth");
                                }}>
                                This Month
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="eighth"
                                onClick={() => {
                                  setCustomRange(true);
                                }}
                              >
                                Custom Date Range
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Tab.Container>
                      </div>
                    </div>
                  </Card.Text>
                  {customRange &&
                    <div className='text-end mt-3'>
                      <RangePicker
                        format={DATE_FORMAT}
                        onChange={handleChange}
                      />
                    </div>
                  }
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12} className="mt-4">
              <Card className="shadow border-light">
                <Card.Body>
                  <Card.Text>
                    {/* <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="dash-heading">
                      <h5 className="fw-bold text-black">Customer List</h5>
                      <p></p>
                      </div>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <input
                        class="form-control mr-sm-2 customer-search-input"
                        type="search"
                        placeholder="Search by Name, Mobile No., Email"
                        aria-label="Search"
                        onChange={(e) => {
                          const userInput = e.target.value;
                          setFilterRecord(userInput);

                          // Filter the data based on user input
                          const filteredData = allCustomersList.filter((item) =>
                            item.SearchFiled?.toLowerCase().includes(
                              userInput.toLowerCase()
                            )
                          );

                          setData(filteredData);
                        }}
                      />
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="white"
                          className="customers-filter"
                          id="dropdown-basic">
                          <BsFilterSquare />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Alphabetical Order
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Newest
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Oldest
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Highest Order Value
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Lowest Order Value
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Maximum Orders Placed
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Minimum Orders Placed
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <CSVLink
                        data={allCustomersList}
                        filename="CustomersList.csv"
                        class="btn ms-2 search-bar-icon my-2 my-sm-0 download-report"
                        headers={csvdata.reportcolumns}
                        target="_blank"
                      >
                        <FaDownload /> Customer Report

                      </CSVLink>
                    </div>
                    <div>
                      {data.map((customer) => {
                        return (
                          <div className="order-list-view align-items-start">
                            <div className="order-list-view-name">
                              <h6 className="fw-bold">{customer.fld_name}</h6>
                              <p>{customer.fld_address_1}</p>
                            </div>

                            <div className="order-list-view-email">
                              <h6 className="fw-bold">
                                {customer.fld_phone_number}
                              </h6>
                              <p>{customer.fld_email_address}</p>
                            </div>
                            <div>
                              <h6 className="fw-bold">
                                {currencyCode} {" "} {customer.total_order_amt}
                              </h6>
                              <p>{customer.total_Orders} Orders</p>
                            </div>
                            <div>
                              <h6 className="fw-bold">Last Order Placed on</h6>
                              <p>
                                {moment(customer.fld_last_order_placed).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                            <div className="order-item-options align-self-center">
                              <Link
                                to={"/customer-dashboard"}
                                state={{ id: customer.fld_customer_id }}>
                                <BsFillArrowRightCircleFill />
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Customers;
