import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, ProgressBar, Row, Nav, Tab } from "react-bootstrap";
import createDOMPurify from "dompurify";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { RiDeleteBin6Line, RiDeleteBin7Fill } from "react-icons/ri";
import "../../pages/Dashboard/Dashboard.css";
import "./SalesDashboard.css"
import GetApiCall from "../../helper/GETAPI";
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import PostApiCall from "../../helper/Api";
import moment from "moment";
import { MdOutlineCancel } from "react-icons/md";
import { Cropper } from "react-cropper";
import LogoIcon from "../../assets/images/restaurant_type/logo.png";
import lockIcon from "../../assets/images/penguin-lock-icon.png"
import { Checkbox } from 'antd';
import { MdMailOutline } from "react-icons/md";
import { ContactPhone } from "@mui/icons-material";

export default function Sales() {
    const DOMPurify = createDOMPurify(window);
    const [EmailRegex] = useState(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const [NumRegex] = useState(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
    const [last10DaysCustomers, setLast10DaysCustomers] = useState([]);
    const [message, setMessage] = useState("");
    const [clientMessage, setClientMessage] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState(null);
    const [website, setWebsite] = useState("");
    const [salesMessages, setSalesMessages] = useState([])
    const [restaurantId, setRestaurantId] = useState(0);
    const [restaurantName, setRestaurantName] = useState("");
    const [restaurantLogo, setRestaurantLogo] = useState("");
    const [filterRecord, setFilterRecord] = useState("");
    const [imageId, setImageId] = useState(0);
    const [approvalstatus, setApprovalstatus] = useState("");
    const [imageCropper, setImageCropper] = useState(false); // when to crop 
    const [uploadProgress, setUploadProgress] = useState({});
    const [finaltypeCards, setFinaltypeCards] = useState([]);
    const [selectedImageId, setSelectedImageId] = useState(0);
    const [cropperheight, setCropperheight] = useState(1080);
    const [cropperwidth, setCropperwidth] = useState(1080);
    const [cropData, setCropData] = useState("#"); // image data after crop
    const [croppedImageType, setCroppedImageType] = useState("");
    const [croppedImageName, setCroppedImageName] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const cropperRef = React.createRef();
    const [ImageApiUrl] = useState("https://api.grubdigest.com/api-grub/AddImage");
    const [imageurl] = useState("https://grubdigest.com/images/");
    const [image, setImage] = useState(null);
    const [show, setShow] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [imageCount, setImageCount] = useState(null);
    const [resAddress, setResAddress] = useState("");
    const [mailBody, setMailBody] = useState("");
    const handleClose = () => {
        setShow(false);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCloseContactModal = () => {
        setShowContactModal(false);
    };
    // check for whatsapp contact
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setChecked(isChecked);
        // console.log('Checkbox is checked:', isChecked);
    };

    // select and grab an image to crop
    const handleImageChange = (e) => {
        setCropData("#");
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            setCroppedImageName(file.name);
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
            setImageCropper(true)
        }
    };
    let modifiedData = [];
    let schema = JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
        ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
        : ""

    // function to crop an image
    const handleCrop = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            setImage(cropper.Image);
            setCropData(cropper.getCroppedCanvas().toDataURL());
            cropper.getCroppedCanvas().toBlob((blob) => {
                setCroppedImage(blob);
            });
        }
    };
    // first modal and second modal restaurants
    useEffect(() => {
        getSalesData(`where fld_approvalstatus = 'NewBulk' and fld_active='InActive' or fld_active='ContactUpdated'`)
    }, []);
    const getSalesData = (WhereClause) => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest(
            {
                WhereClause: WhereClause
            },
            "getSalesData"
        ).then((resultdes) =>
            resultdes.json().then((obj) => {
                if (obj.message.length > 0) {
                    setLast10DaysCustomers(obj.message);
                    Notiflix.Loading.remove();
                } else {
                    Notiflix.Notify.failure("Please Contact Support")
                    Notiflix.Loading.remove();
                }
            })
        );
    }
    const getSalesDataSingleClient = (clientId) => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest(
            {
                WhereClause: `where fld_client_id = ${clientId}`
            },
            "getSalesData"
        ).then((resultdes) =>
            resultdes.json().then((obj) => {
                if (obj.message.length > 0) {
                    setApprovalstatus(obj.message[0]?.fld_active);
                    setClientMessage(obj.message[0]?.fld_client_message)
                    Notiflix.Loading.remove();
                } else {
                    Notiflix.Notify.failure("Please Contact Support")
                    Notiflix.Loading.remove();
                }
            })
        );
    }
    const GetImageRestaurantType = () => {
        GetApiCall.getRequest("getRestaurantImageType").then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    modifiedData = obj1.message.map((item) => ({
                        ...item,
                        fld_image_url: "",
                        fld_image_id: null,
                    }));
                    // setTypeCards(modifiedData);
                    GetRestaurantImages();
                    Notiflix.Loading.remove();
                }
            })
        );
    };

    const GetRestaurantImages = (id) => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_restaurant_id = ${id ? id : restaurantId}`,
            },
            "getRestaurantImages"
        ).then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    setImageCount(obj1.message.length)
                    if (obj1.message.length > 0 && modifiedData.length > 0) {
                        const cardsWithImage = modifiedData.map((obj) => {
                            if (obj.fld_image_url == "" && obj.fld_type) {
                                const matchedObj = obj1.message.find(
                                    (data) => data.fld_type === obj.fld_type
                                );
                                if (matchedObj) {
                                    return {
                                        ...obj,
                                        fld_image_url: matchedObj.fld_image_url,
                                        fld_image_id: matchedObj.fld_id,
                                    };
                                }
                            }
                            return obj;
                        });
                        if (cardsWithImage) {
                            setFinaltypeCards(cardsWithImage);
                        }
                    } else {
                        setFinaltypeCards(modifiedData);
                    }
                    Notiflix.Loading.remove();
                }
            })
        );
    };

    const getSalesMessages = (id) => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest(
            {
                WhereClause: `where FLD_Restaurant_Id = ${id}`
            },
            "getSalesMessages"
        ).then((resultdes) =>
            resultdes.json().then((obj) => {
                if (obj.message.length > 0) {
                    setSalesMessages(obj.message);

                    Notiflix.Loading.remove();
                } else {
                    // setLast10DaysCustomers(obj.message);
                    Notiflix.Loading.remove();
                }
            })
        );
    }
    const DeleteImage = (id, type) => {
        Notiflix.Loading.dots("");
        PostApiCall.postRequest(
            {
                id: id,
                deletefrom: type
            }
            ,
            "deleteRestaurantImage").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1?.message?.length != 0) {
                            GetImageRestaurantType();
                            Notiflix.Notify.success("Image Successfully deleted")
                            Notiflix.Loading.remove();
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }
    // when restaurant is being deleted or contacted
    const ResContactDelete = (restaurantId, active, approvalstatus) => {
        Notiflix.Loading.dots();
        PostApiCall.postRequest(
            {
                clientid: restaurantId,
                active: active,
            },
            "UpdateRestaurantMaster").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1?.message?.length != 0) {
                            getSalesData(`where fld_approvalstatus = 'NewBulk' and fld_active='InActive'`)
                            Notiflix.Notify.success("Success")
                            Notiflix.Loading.remove();
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }
    const CreateAdminUser = (id, name, address, mobile, email, country, state, city) => {
        Notiflix.Loading.dots("");
        PostApiCall.postRequest(
            {
                restaurantId: id,
                companyName: name,
                address: address,
                mobileNumber: mobile,
                email: email,
                country: country,
                city: city,
                state: state,
            }
            ,
            "CreateAdminUser").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        GetImageRestaurantType();
                        setShowModal(true)
                        Notiflix.Loading.remove();
                    } else {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure("Please Contact Support");
                    }
                })
            );
    }
    const AddRestaurantImage = (imageid, type, imageurl) => {
        PostApiCall.postRequest(
            {
                imageid: imageid,
                restaurantid: restaurantId,
                imagetype: type,
                imageurl: imageurl,
                status: "",
                showontop: "Yes"
            },
            "updateRestaurantImages"
        ).then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    GetImageRestaurantType();
                    Notiflix.Loading.remove();
                }
            })
        );
    };

    // first time registering modal  
    const validateBeforeUpdate = () => {
        // regex for mobile and email
        const mobRegex = /^\d+$/;
        const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!mobile && !email) {
            Notiflix.Notify.failure("Please fill at least one of of the details")
            return;
        }

        if (mobile && !mobRegex.test(mobile)) {
            Notiflix.Notify.failure("Please enter a valid number")
            return;
        }

        if (email && !mailRegex.test(email)) {
            Notiflix.Notify.failure("Please enter a valid email address")
            return;
        }
        UpdateRestaurantMaster("ContactUpdated") // towards second modal (image uploader)
    }
    const UpdateRestaurantMaster = (active, logo = null) => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                email: email,
                mobilenumber: mobile,
                website: website,
                clientid: restaurantId,
                active: active,
                logo: logo,
                image: logo
            },
            "UpdateRestaurantMaster"
        ).then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    getSalesData(`where fld_approvalstatus = 'NewBulk'`)
                    getSalesDataSingleClient(restaurantId);
                    handleCloseContactModal()
                    Notiflix.Loading.remove();
                }
            })
        );
    };

    // before sending it to the third modal - validate the image count
    const handleCountImage = () => {
        // if (imageCount > 0 && restaurantLogo != "") {
        //     UpdateRestaurantMaster("ImagesUploaded")
        // } else {
        //     // console.log("Something went wrong")
        //     Notiflix.Notify.failure("Please upload at least one restaurant image and the restaurant logo")
        // }
        if (imageCount > 0) {
            if (restaurantLogo != "") {
                UpdateRestaurantMaster("ImagesUploaded") // forward only if the images are there
            } else {
                Notiflix.Notify.failure("Please upload the restaurant logo")
            }
        } else {
            Notiflix.Notify.failure("Please upload at least one restaurant image")
        }
    }

    // towards whatsapp web
    const handleClick = () => {
        const tempDiv = document.createElement('div');
        // Set the HTML content to the div
        tempDiv.innerHTML = clientMessage;
        // Extract the text content
        const textContent = tempDiv.textContent || tempDiv.innerText;
        const encodedMessage = encodeURIComponent(textContent);
        // window.open(`https://wa.me/${9896373713}?text=${encodedMessage}`, "_blank")
        window.open(`https://wa.me/+91${mobile}`, "_blank")
    };

    const sendOnboardEmail = () => {
        Notiflix.Loading.dots()
        // console.log("Email Sent")
        PostApiCall.postRequest({
            restaurantId: restaurantId,
            companyName: restaurantName,
            mobileNumber: mobile,
            email: email,
            address: resAddress,
            html: mailBody,
        }, "RestaurantOnboardMailer").then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    // getSalesData(`where fld_approvalstatus = 'NewBulk'`)
                    // getSalesDataSingleClient(restaurantId);
                    // handleCloseContactModal()
                    Notiflix.Notify.success("Email Sent Successfully")
                    Notiflix.Loading.remove();
                }
            })
        );
    }
    return (
        <>
            <section className="py-lg-4 margin-left">
                <Container fluid>
                    <Row>
                        <Col lg={12} className="mb-4">
                            <Card className="shadow border-light">
                                <Card.Body>
                                    <Card.Text>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div className="dash-heading">
                                                <h5 className="fw-bold text-black">Restaurant List</h5>
                                            </div>
                                        </div>
                                        {/* Filter start */}
                                        <Card className="border-light">
                                            <Card.Body>
                                                <Card.Text>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div>
                                                            <h5 className="fw-bold text-black">Filter Restaurants</h5>
                                                            <p className="text-black">Select a status</p>
                                                        </div> */}
                                                        <div className="tab-filter">
                                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                                <Nav variant="pills" className="flex-row">
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            eventKey="first"
                                                                            onClick={() => {
                                                                                getSalesData(`where fld_approvalstatus = 'NewBulk' and fld_active='InActive' or fld_active='ContactUpdated'`)
                                                                            }}
                                                                        >
                                                                            Inactive
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    {/* <Nav.Item>
                                                                        <Nav.Link
                                                                            eventKey="second"
                                                                            onClick={() => {
                                                                                getSalesData(`where fld_approvalstatus = 'NewBulk' and fld_active='ContactUpdated'`)
                                                                            }}
                                                                        >
                                                                            Registered
                                                                        </Nav.Link>
                                                                    </Nav.Item> */}
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            eventKey="second"
                                                                            onClick={() => {
                                                                                getSalesData(`where fld_approvalstatus = 'NewBulk' and fld_active='ImagesUploaded'`)
                                                                            }}
                                                                        >
                                                                            Ready to contact
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            eventKey="third"
                                                                            onClick={() => {
                                                                                getSalesData(`where fld_approvalstatus = 'NewBulk' and fld_active='Contacted'`)
                                                                            }}
                                                                        >
                                                                            Contacted
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            eventKey="fourth"
                                                                            onClick={() => {
                                                                                getSalesData(`where fld_active = 'Active'`)
                                                                            }}
                                                                        >
                                                                            Active
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            eventKey="fifth"
                                                                            onClick={() => {
                                                                                getSalesData(`where fld_active='Deleted'`)
                                                                            }}
                                                                        >
                                                                            Deleted
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>
                                                            </Tab.Container>
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        {/* Filter end */}
                                        {/* search bar start */}
                                        <div className="d-flex justify-content-between align-items-center my-4">
                                            <input
                                                class="form-control mr-sm-2"
                                                type="search"
                                                placeholder="Search by Restaurant Name, Address, Email, Contact Number, Contact Person"
                                                aria-label="Search"
                                                value={filterRecord}
                                                onChange={(e) => {
                                                    const userInput = e.target.value;
                                                    // setFilterRecord(userInput);

                                                    // Filter the data based on user input
                                                    // const filteredData = last10DaysCustomers.filter(item =>
                                                    //     item.fld_name?.toLowerCase().includes(userInput.toLowerCase())
                                                    // );
                                                    setFilterRecord(userInput);
                                                }}
                                            />
                                        </div>
                                        {/* search bar end */}
                                        <div className="order-list-view-main">
                                            {last10DaysCustomers?.length > 0 ? (
                                                last10DaysCustomers?.filter((data) => {
                                                    if (filterRecord == "") {
                                                        return data;
                                                    }
                                                    if (
                                                        filterRecord !== "" &&
                                                        data.fld_restaurantname?.toLowerCase().includes(
                                                            filterRecord?.toLowerCase()
                                                        )
                                                    ) {
                                                        return data;
                                                    }
                                                    if (
                                                        filterRecord !== "" &&
                                                        data.fld_address?.toLowerCase().includes(
                                                            filterRecord?.toLowerCase()
                                                        )
                                                    ) {
                                                        return data;
                                                    }
                                                    if (
                                                        filterRecord !== "" &&
                                                        data.fld_contact_person?.toLowerCase().includes(
                                                            filterRecord?.toLowerCase()
                                                        )
                                                    ) {
                                                        return data;
                                                    }
                                                    if (
                                                        filterRecord !== "" &&
                                                        data.fld_email?.toLowerCase().includes(
                                                            filterRecord?.toLowerCase()
                                                        )
                                                    ) {
                                                        return data;
                                                    }
                                                    if (
                                                        filterRecord !== "" &&
                                                        data.fld_mobilenumber?.toLowerCase().includes(
                                                            filterRecord?.toLowerCase()
                                                        )
                                                    ) {
                                                        return data;
                                                    }
                                                }).map((dat) => {
                                                    return (
                                                        <div className="order-list-view align-items-center">
                                                            <div className="order-list-view-name">
                                                                <h6 className="fw-bold">{dat.fld_restaurantname}</h6>
                                                                <p>{dat.fld_address}</p>
                                                            </div>

                                                            <div className="order-list-view-name">
                                                                <h6 className="fw-bold">
                                                                    {dat.fld_mobilenumber}
                                                                </h6>
                                                                <p>{dat.fld_email}</p>
                                                            </div>
                                                            <div className="order-list-view-name">
                                                                <h6 className="fw-bold">
                                                                    {dat.fld_contact_person}
                                                                </h6>
                                                            </div>
                                                            <div className="order-list-view-name">
                                                                <h6 className="fw-bold">
                                                                    <button
                                                                        type="button"
                                                                        className="default-btn btn btn-white"
                                                                        onClick={() => {
                                                                            // GetRestaurantImages(dat.fld_client_id);
                                                                            setRestaurantId(dat.fld_client_id)
                                                                            if (NumRegex.test(dat.fld_mobilenumber) || EmailRegex.test(dat?.fld_email)) {
                                                                                setApprovalstatus(dat.fld_active);
                                                                                setClientMessage(dat.fld_client_message)
                                                                                CreateAdminUser(
                                                                                    dat.fld_client_id,
                                                                                    dat.fld_restaurantname,
                                                                                    dat.fld_address ? dat.fld_address : "",
                                                                                    dat.fld_mobilenumber,
                                                                                    dat.fld_email,
                                                                                    dat.fld_country,
                                                                                    dat.fld_state,
                                                                                    dat.fld_city
                                                                                )
                                                                                setMobile(dat.fld_mobilenumber)
                                                                                setEmail(dat.fld_email)
                                                                                setRestaurantLogo(dat.fld_image)
                                                                                setWebsite(dat.fld_website)
                                                                                setRestaurantName(dat.fld_restaurantname)
                                                                                setResAddress(dat.fld_address)
                                                                                setMailBody(dat.fld_client_message)
                                                                            } else {
                                                                                // set values for the firs modal
                                                                                setMobile(dat.fld_mobilenumber)
                                                                                setEmail(dat.fld_email)
                                                                                setShowContactModal(true)
                                                                                setRestaurantName(dat.fld_restaurantname)
                                                                            }
                                                                        }}>
                                                                        <FaWhatsapp />
                                                                    </button>
                                                                </h6>
                                                            </div>
                                                            <div className="order-list-view-name">
                                                                <h6 className="fw-bold">
                                                                    {dat.fld_active == "Active" ?

                                                                        <a
                                                                            href={`https://grubdigest.com/restaurant-info/${dat.fld_restaurantname
                                                                                .replace("%", "")
                                                                                .replace("& ", "")
                                                                                .replace(",", "")
                                                                                .replace("$", "")
                                                                                .replace("(", "")
                                                                                .replace(")", "")
                                                                                .replace(/ /g, "-")}-${dat.fld_address
                                                                                    .replace("%", "")
                                                                                    .replace("& ", "")
                                                                                    .replace("(", "")
                                                                                    .replace(")", "")
                                                                                    .replace(",", "")
                                                                                    .replace("$", "")
                                                                                    .replace(/ /g, "-")}/${dat.fld_client_id}`}
                                                                            className="default-btn btn btn-white text-white"
                                                                            target="_blank">
                                                                            View on Grub Digest
                                                                        </a>
                                                                        :
                                                                        <a
                                                                            href={dat.fld_zom}
                                                                            className="default-btn btn btn-white text-white"
                                                                            target="_blank">
                                                                            View on Zomato
                                                                        </a>

                                                                    }
                                                                </h6>
                                                            </div>
                                                            {
                                                                dat.fld_active == "InActive" && (
                                                                    <div className="order-list-view-name">
                                                                        <h6 className="fw-bold">
                                                                            <button
                                                                                type="button"
                                                                                className="default-btn btn btn-white" onClick={() => {
                                                                                    setRestaurantId(dat.fld_client_id)
                                                                                    ResContactDelete(dat.fld_client_id, "Deleted")
                                                                                }}>
                                                                                <RiDeleteBin6Line />
                                                                            </button>
                                                                        </h6>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="order-list-view-name">
                                                                <h6 className="fw-bold customer-message-count">Conversations
                                                                    {dat.conversation_count > 0 &&
                                                                        <span className="customer-message-count-badge">{dat.conversation_count}</span>
                                                                    }
                                                                </h6>
                                                            </div>
                                                            <div className="order-item-options align-self-center">
                                                                <div onClick={() => {
                                                                    setShow(true)
                                                                    setRestaurantId(dat.fld_client_id)
                                                                    setRestaurantName(dat.fld_restaurantname)
                                                                    setRestaurantLogo(dat.fld_image)
                                                                    getSalesMessages(dat.fld_client_id)
                                                                    setApprovalstatus(dat.fld_active)
                                                                }}>
                                                                    <BsFillArrowRightCircleFill />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{ height: "300px" }}
                                                >
                                                    <p>No record available.</p>
                                                </div>
                                            )}
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Modal section */}
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="chat-box-modal"
            >
                <Modal.Header className="justify-content-end" closeButton></Modal.Header>

                <Modal.Body>
                    <div className="main">
                        <div class="header-mod">
                            <div class="center">
                                <div>
                                    <img alt="" class="pfp" src={restaurantLogo ? restaurantLogo : "https://grubdigest.com/static/media/dish.11b14aa5e12f9f3be011.png"} />
                                    <p id="pfpname">{restaurantName}</p>
                                </div>

                            </div>
                        </div>
                        <div class="content">
                            <div>
                                {salesMessages?.map((data) => {
                                    return (
                                        <div class="msg-btn-holder">
                                            <div class="sender-msg msg-btn">
                                                <p>{data.FLD_Message}</p>
                                                <div className="text-end message-date-time">{moment(data.FLD_Created_On).format("DD/MM/YYYY")}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div class="footer">
                            <div className="w-100 p-2 d-flex">
                                <input placeholder="Comments" class="text-box" name="Comments"
                                    onChange={(e) => { setMessage(e.target.value) }}
                                    value={message}
                                />
                                <div class="send-ico" onClick={() => {
                                    if (message) {
                                        Notiflix.Loading.dots();
                                        PostApiCall.postRequest(
                                            {
                                                contactperson: "manmeet",
                                                restaurantid: restaurantId,
                                                message: message
                                            },
                                            "updateSalesMessage"
                                        ).then((results1) =>
                                            results1.json().then((obj1) => {
                                                if (results1.status === 200 || results1.status === 201) {
                                                    Notiflix.Notify.success("Updated Successfully");
                                                    getSalesMessages(restaurantId)
                                                    setMessage("")
                                                    Notiflix.Loading.remove();
                                                } else {
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.failure("Error Occurred");
                                                }
                                            })
                                        );
                                    }
                                }}>
                                    <FaTelegramPlane />
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="chat-box-modal"
            >
                <Modal.Header className="justify-content-end fs-5 text-dark fw-bold" closeButton>
                    {approvalstatus == "ImagesUploaded" || approvalstatus == "Contacted" ?
                        <p>Contact Restaurant - {restaurantName}</p> : <p>Update details -  {restaurantName}</p>
                    }
                </Modal.Header>
                <Modal.Body>
                    {approvalstatus == "ImagesUploaded" || approvalstatus == "Contacted" ?
                        <>
                            {/* last modal after registration and submission of images */}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(clientMessage),
                                }}
                            ></div>
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                    <button className="mt-3 default-btn btn btn-white" onClick={() => { navigator.clipboard.writeText(clientMessage) }}>
                                        copy
                                    </button>

                                    <button
                                        disabled={mobile ? false : true}
                                        className="mt-3 mx-3 default-btn btn btn-white"
                                        onClick={handleClick}>
                                        Connect On <FaWhatsapp />
                                    </button>
                                    <button
                                        disabled={email ? false : true}
                                        className="mt-3 mx-3 default-btn btn btn-white"
                                        onClick={sendOnboardEmail}>
                                        Connect On <MdMailOutline />
                                    </button>
                                </div>

                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                    onClick={() => {
                                        ResContactDelete(restaurantId, "Contacted", "Contacted")
                                    }}
                                >
                                    Already Contacted
                                </Checkbox>
                            </div>
                        </>
                        :
                        < div >
                            {/* second modal after registration with email and mobile number */}
                            <p className="mb-2 text-red">Kindly update the necessary details to facilitate communication with the restaurant. </p>
                            <Row>
                                <Col lg={5}>
                                    {imageCropper === true ?
                                        <div className="crop-input mb-3">
                                            <input type="file" accept="image/*" onChange={handleImageChange} />
                                            {image && (
                                                <div className="croper-main">
                                                    <Cropper
                                                        style={{ height: 400, width: "100%" }}
                                                        ref={cropperRef}
                                                        initialAspectRatio={4}
                                                        preview=".img-preview"
                                                        src={image}
                                                        viewMode={1}
                                                        aspectRatio={cropperwidth / cropperheight}
                                                        background={false}
                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                        guides={true}
                                                    />
                                                    <button variant="danger" onClick={handleCrop} className="mt-3 default-btn btn btn-white">
                                                        Crop
                                                    </button>
                                                </div>
                                            )}
                                            {cropData !== "#" && (
                                                <div className="pb-5">
                                                    <h4 className="tab-section-title">Cropped Image Preview</h4>
                                                    <div className="Cropped-image-height">
                                                        <img src={cropData} alt="Cropped Image" className="w-100 h-100" />
                                                        <div className="mt-3">
                                                            <button
                                                                className="default-btn btn btn-white"
                                                                variant="danger"
                                                                onClick={(e) => {
                                                                    // e.preventDefault()
                                                                    // Notiflix.Loading.dots();
                                                                    const form = new FormData();
                                                                    let filename = croppedImageType === "Logo" ?
                                                                        `${Math.floor(Math.random() * 10000)}-RestLogo-${croppedImageName.replace(/ /g, "")}` :
                                                                        `${Math.floor(Math.random() * 10000)}-${croppedImageName.replace(/ /g, "")}`;
                                                                    form.append("file", croppedImage);
                                                                    form.append("foldername", "restaurant");
                                                                    form.append("schema", schema);
                                                                    form.append("filename", filename); // send the logo filename || cropped logo
                                                                    const xhr = new XMLHttpRequest();
                                                                    xhr.upload.onprogress = (e) => {
                                                                        if (e.lengthComputable) {
                                                                            const progress = Math.round((e.loaded / e.total) * 100);
                                                                            setUploadProgress((prevProgress) => ({
                                                                                ...prevProgress,
                                                                                [selectedImageId]: progress,
                                                                            }));
                                                                        }
                                                                    };
                                                                    xhr.onreadystatechange = () => {
                                                                        if (xhr.readyState === 4) {
                                                                            if (xhr.status === 200 || xhr.status === 201) {
                                                                                // Handle the successful response
                                                                                const response = JSON.parse(xhr.responseText);
                                                                                if (croppedImageType === "Logo") {
                                                                                    setRestaurantLogo(imageurl + `${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp");
                                                                                    UpdateRestaurantMaster("LogoUploaded", imageurl + `${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp");
                                                                                } else {
                                                                                    AddRestaurantImage(
                                                                                        imageId,
                                                                                        selectedImageId,
                                                                                        imageurl + `/${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp"
                                                                                    );
                                                                                }
                                                                                setImageCropper(false);
                                                                                Notiflix.Loading.remove();
                                                                                Notiflix.Notify.success(
                                                                                    "Image successfully uploaded."
                                                                                );
                                                                                setCropData("#");
                                                                            } else {
                                                                                // Handle errors
                                                                                console.error('Error uploading image:', xhr.statusText);
                                                                            }
                                                                        }
                                                                    };
                                                                    xhr.open('POST', ImageApiUrl, true);
                                                                    xhr.send(form);
                                                                }}>
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <img alt="" src={lockIcon} className="img-fluid" />
                                    }
                                </Col>
                                <Col lg={7}>
                                    <Row>
                                        {finaltypeCards
                                            .filter((type) => {
                                                if (
                                                    type.fld_status == "Active" &&
                                                    type.fld_type != "gallery images"
                                                ) {
                                                    return type;
                                                }
                                            })
                                            .map((data) => {
                                                return (
                                                    <Col md={4} className="mb-3" key={data.fld_id}>
                                                        <div className="upload-image rounded position-relative">
                                                            {data.fld_image_url && (
                                                                <>
                                                                    <button
                                                                        className="img-delete-btn main-images"
                                                                        style={{
                                                                            display: uploadProgress[data.fld_id] != undefined && uploadProgress[data.fld_id] < 100 ?
                                                                                "none" : "block"
                                                                        }}
                                                                        onClick={() => {
                                                                            DeleteImage(data.fld_image_id, "RestaurantImages")
                                                                        }}
                                                                    ><MdOutlineCancel />
                                                                    </button>
                                                                    <img
                                                                        src={data.fld_image_url}
                                                                        className="img-fluid w-100 rounded"
                                                                        alt=""
                                                                    />
                                                                </>
                                                            )}
                                                            <div className="text-center">
                                                                <div className="restuarant-docs-p" style={{
                                                                    backgroundColor: uploadProgress[data.fld_id] != undefined && uploadProgress[data.fld_id] < 100 ?
                                                                        "#ffffffa8" : "transparent"
                                                                }}>
                                                                    {
                                                                        uploadProgress[data.fld_id] != undefined &&
                                                                        uploadProgress[data.fld_id] < 100 &&
                                                                        <ProgressBar now={uploadProgress[data.fld_id]} label={`${uploadProgress[data.fld_id]}%`} id={data.fld_id} className="w-75 mx-auto" />}
                                                                    <p
                                                                        className="fw-bold text-capitalize text-dark restaurant-image-cards add-restaurant-upload-icon flex-column justify-content-center"
                                                                        style={{
                                                                            display: data.fld_image_url == "" ? "flex" : "none",
                                                                        }}>
                                                                        <img className="img-fluid mb-2" src={data.fld_icon} />{" "}
                                                                        {data.fld_type}
                                                                    </p>
                                                                    <div
                                                                        className="add-restaurant-upload-btn pos-bottom-0"
                                                                        id={data.fld_id}
                                                                        style={{
                                                                            display: uploadProgress[data.fld_id] != undefined && uploadProgress[data.fld_id] < 100 ?
                                                                                "none" : "block"
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                        }}>
                                                                        {data.fld_image_url == "" ? (
                                                                            <button
                                                                                variant="danger"
                                                                                className="crop-btn text-capitalize default-btn btn btn-white"
                                                                                onClick={() => {
                                                                                    setCropperheight(1080);
                                                                                    setCropperwidth(1080);
                                                                                    setCropData("#");
                                                                                    setSelectedImageId(data.fld_id);
                                                                                    setImageId(data.fld_image_id);
                                                                                    setCroppedImageType("Main")
                                                                                }}>
                                                                                <input
                                                                                    accept="image/*"
                                                                                    id="photo-upload"
                                                                                    className="w-100 h-100 opacity-0"
                                                                                    type="file"
                                                                                    onChange={(e) => {
                                                                                        handleImageChange(e)
                                                                                    }}
                                                                                />
                                                                                Click to upload image
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                variant="danger"
                                                                                className="crop-btn text-capitalize default-btn btn btn-white"
                                                                                onClick={() => {
                                                                                    setCropperheight(1080);
                                                                                    setCropperwidth(1080);
                                                                                    setCropData("#");
                                                                                    setSelectedImageId(data.fld_id);
                                                                                    setImageId(data.fld_image_id);
                                                                                    setCroppedImageType("Main")
                                                                                }}>
                                                                                <input
                                                                                    accept="image/*"
                                                                                    id="photo-upload"
                                                                                    className="w-100 h-100 opacity-0"
                                                                                    type="file"
                                                                                    onChange={(e) => {
                                                                                        handleImageChange(e)
                                                                                    }}
                                                                                />
                                                                                Click to replace image
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}


                                        {/* Logo button */}
                                        <Col md={4} className="mb-3">
                                            <div className="upload-image rounded position-relative">
                                                <img
                                                    alt=""
                                                    htmlFor="photo-upload"
                                                    src={restaurantLogo}
                                                    style={{ borderRadius: "5%" }}
                                                />
                                                <div className="restuarant-docs-p">
                                                    {!restaurantLogo &&
                                                        <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon">
                                                            <img src={LogoIcon} className="img-fluid" />
                                                        </p>
                                                    }


                                                    <div className="add-restaurant-upload-btn add-restaurant-upload-btn-logo-static">
                                                        <button
                                                            variant="danger"
                                                            className="crop-btn text-capitalize position-relative default-btn btn btn-white"
                                                            onClick={() => {
                                                                setCropperheight(388)
                                                                setCropperwidth(718)
                                                                setCroppedImageType("Logo")
                                                            }}>
                                                            <input
                                                                accept="image/*"
                                                                id="photo-upload"
                                                                className="w-100 h-100 opacity-0"
                                                                type="file"
                                                                onChange={(e) => {
                                                                    handleImageChange(e)
                                                                }}
                                                            />
                                                            {restaurantLogo
                                                                ? "Replace Restaurant Logo"
                                                                : "Upload Restaurant Logo"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Row>
                                    <Col lg={12} className="pe-md-0">
                                        <Row>
                                            <Col lg={4} className="pe-md-0 mb-3">
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Contact Number">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Contact Number"
                                                        value={mobile}
                                                        onChange={(e) => {
                                                            setMobile(e.target.value);
                                                        }}
                                                    />
                                                    {!mobile &&
                                                        <p className="text-danger input-alert-msg">
                                                            Please provide a contact number without the country code*
                                                        </p>
                                                    }
                                                </FloatingLabel>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Email Address">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Email Address"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                        }}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Website">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Website"
                                                        value={website}
                                                        onChange={(e) => {
                                                            setWebsite(e.target.value);
                                                        }}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col lg={12} className="text-center">
                                                <button
                                                    type="button"
                                                    className="default-btn btn btn-white w-15"
                                                    // onClick={() => { UpdateRestaurantMaster("ImagesUploaded") }}
                                                    onClick={() => handleCountImage()}
                                                >
                                                    Save & Next
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </div>
                    }
                </Modal.Body>
            </Modal>

            {/* first modal to reguster */}
            <Modal
                show={showContactModal}
                onHide={handleCloseContactModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="chat-box-modal"
            >
                <Modal.Header className="justify-content-end" closeButton>Update contact details - {restaurantName}</Modal.Header>

                <Modal.Body>
                    <Row>
                        <p className="mb-2 text-red">Please provide a valid email address or mobile number to proceed with the user creation process.</p>
                        <Col lg={6} className="mb-3">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Contact Number *">
                                <Form.Control
                                    type="text"
                                    placeholder="Contact Number"
                                    value={mobile}
                                    onChange={(e) => {
                                        setMobile(e.target.value);
                                    }}
                                />
                                <p className="text-danger input-alert-msg">
                                    Please provide a contact number without the country code*
                                </p>
                            </FloatingLabel>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email Address *">
                                <Form.Control
                                    type="text"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col lg={12} className="text-center">
                            <button
                                type="button"
                                className="default-btn btn btn-white w-15"
                                onClick={() => {
                                    validateBeforeUpdate()
                                    // UpdateRestaurantMaster("ContactUpdated")
                                    // handleCloseContactModal()
                                }}
                            >
                                Save
                            </button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
