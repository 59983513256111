import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import uploadImage from "../../assets/images/upload-pang.png";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import { store } from "../Context/store";
import "./ItemAction.css";
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas w-100 d-flex justify-content-center">
    <div className="img-wrap img-upload">
      <img for="photo-upload" src={src} style={{ borderRadius: "5%" }} />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

const ItemAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [previewUrl, setPreviewUrl] = useState("");
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://api.grubdigest.com/api-grub/AddImage"
  );
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [allCategoryId, setAllCategoryId] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [allSubCategoryId, setAllSubCategoryId] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFileName] = useState("");
  const [allFoodType, setAllFoodType] = useState([]);
  const [selectedFoodType, setSelectedFoodType] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [pricing, setPricing] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [serviceInfo, setServiceInfo] = useState("");
  const [calCount, setCalCount] = useState(null);
  const [portionSize, setPortionSize] = useState("");
  const [portionUnit, setPortionUnit] = useState("");
  const [preparationTime, setPreparationTime] = useState(null);
  const [maxAddon, setMaxAddon] = useState("");
  const [maxProperties, setMaxProperties] = useState(1);
  const [spiceLevel, setSpiceLevel] = useState("");
  const [containsAlcohol, setContainsAlcohol] = useState("Yes");
  const [clientId, setClientId] = useState("");
  const [chefsChoice, setChefsChoice] = useState("Yes");
  const [bestSeller, setBestSeller] = useState("Yes");
  const [containsEgg, setContainsEgg] = useState("Yes");
  const [customizable, setCustomizable] = useState("Yes");
  const [enabled, setEnabled] = useState("Yes");

  const [hasAddons, setHasAddons] = useState("No");
  const [hasProperties, setHasProperties] = useState("No");
  const { restaurantType, setRestaurantType } = useContext(store);
  const [PropertiesModal, setPropertiesModal] = useState(false);
  const handleClosePropertiesModal = () => {
    setPropertiesModal(false);
    setHasProperties("No");
  };
  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
  };

  useEffect(() => {
    if (location.state != null) {
      Notiflix.Loading.dots();
      const whereClause1 =
        JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype === 1 ||
          JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype === 3
          ? ""
          : `and fld_client_id = ${JSON.parse(localStorage.getItem("LoginDetail")).fld_client_id
          } `;
      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_Item_Id=${location.state} ${whereClause1}`,
        },
        "GetItemById"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            GetApiCall.getRequest("GetCategoryId").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  GetApiCall.getRequest("GetSubCategoryId").then((resultdes) =>
                    resultdes.json().then((obj) => {
                      GetApiCall.getRequest("getFoodTypeMaster").then(
                        (resultdes) =>
                          resultdes.json().then((obj) => {
                            if (obj.data.length != 0) {
                              setAllFoodType(obj.data);
                            } else {
                              Notiflix.Loading.remove();
                            }
                          })
                      );
                      setAllSubCategoryId(obj.data);
                      Notiflix.Loading.remove();
                    })
                  );
                  setAllCategoryId(obj.data);
                  Notiflix.Loading.remove();
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
            setItemName(obj1.data[0].FLD_Name);
            setSelectedCategoryId(obj1.data[0].FLD_Cat_Id);
            setSelectedSubCategoryId(obj1.data[0].FLD_SubCat_Id);
            setDescription(obj1.data[0].FLD_Description);
            setPreviewUrl(obj1.data[0].FLD_Image);
            setSelectedFoodType(obj1.data[0].FLD_Food_Type);
            setServiceType(obj1.data[0].FLD_Service_Type);
            setPricing(obj1.data[0].FLD_Pricing);
            setDiscountPrice(obj1.data[0].FLD_Discount_Price);
            setCalCount(obj1.data[0].FLD_Cal_Count);
            setPortionSize(obj1.data[0].FLD_Portion_Size);
            setPortionUnit(obj1.data[0].FLD_Portion_Unit);
            setServiceInfo(obj1.data[0].FLD_Service_Info);
            setPreparationTime(obj1.data[0].FLD_Preparation_Time);
            setMaxAddon(obj1.data[0].FLD_Max_Addon);
            setMaxProperties(obj1.data[0].FLD_Max_Properties);
            setSpiceLevel(obj1.data[0].FLD_Spice_Level);
            setClientId(obj1.data[0].fld_client_id);
            setContainsAlcohol(obj1.data[0].FLD_Contains_Alcohol);
            setChefsChoice(obj1.data[0].FLD_Chefs_Choice);
            setBestSeller(obj1.data[0].FLD_Best_Seller);
            setContainsEgg(obj1.data[0].FLD_Contains_Egg);
            setCustomizable(obj1.data[0].FLD_Customisable);
            setEnabled(obj1.data[0].FLD_Enabled_Flag);
            obj1.data[0].FLD_Max_Addon > 0
              ? setHasAddons("Yes")
              : setHasAddons("No");
            obj1.data[0].FLD_Max_Properties > 0
              ? setHasProperties("Yes")
              : setHasProperties("No");
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    } else {
      Notiflix.Loading.dots();
      GetApiCall.getRequest("GetCategoryId").then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            setAllCategoryId(obj.data);
            GetApiCall.getRequest("GetSubCategoryId").then((resultdes) =>
              resultdes.json().then((obj) => {
                GetApiCall.getRequest("getFoodTypeMaster").then((resultdes) =>
                  resultdes.json().then((obj) => {
                    if (obj.data.length != 0) {
                      setAllFoodType(obj.data);
                    } else {
                      Notiflix.Loading.remove();
                    }
                  })
                );
                setAllSubCategoryId(obj.data);
                Notiflix.Loading.remove();
              })
            );
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        })
      );
    }
  }, []);

  function onSaveItem() {
    if (hasAddons === "Yes" && maxAddon <= 0) {
      Notiflix.Notify.failure("Max addons is mandatory");
      return;
    }
    if (hasProperties === "Yes" && maxProperties <= 0) {
      Notiflix.Notify.failure("Max properties is mandatory");
      return;
    }
    if (outputFileName != null && outputFileName != "") {
      Notiflix.Loading.dots();
      var randomnumber = Math.floor(100000 + Math.random() * 900000);
      var fileName = outputFileName.split(".").slice(0, -1).join(".") + ".webp";

      const form = new FormData();
      form.append("file", originalImage);
      form.append(
        "schema",
        JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
          ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
          : ""
      );
      form.append("foldername", "item");
      form.append("filename", randomnumber + "-" + outputFileName);
      let response;
      response = fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((res) => {
        res.json().then((res1) => {
          PostApiCall.postRequest(
            {
              itemId: location.state,
              image: res1.Message,
              name: itemName,
              description: description,
              catId: selectedCategoryId,
              subCatId: selectedSubCategoryId,
              foodType: selectedFoodType,
              serviceType: serviceType,
              pricing: hasProperties ? pricing : null,
              discountPrice: hasProperties ? discountPrice : null,
              serviceInfo: serviceInfo,
              calCount: calCount,
              portionSize: portionSize,
              portionUnit: portionUnit,
              preparationTime: preparationTime,
              spiceLevel: spiceLevel,
              clientId: JSON.parse(localStorage.getItem("LoginDetail"))
                .fld_client_id,
              containsAlcohol: containsAlcohol,
              chefsChoice: chefsChoice,
              bestSeller: bestSeller,
              containsEgg: containsEgg,
              customizable: customizable,
              enabledFlag: enabled,
              createdOn: new Date(),
              createdBy: 1,
              updatedOn: new Date(),
              updatedBy: 1,
              maxaddons: hasAddons ? maxAddon : null,
              maxproperties: hasProperties ? maxProperties : null,
            },
            "updateItemMaster"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                Notiflix.Loading.remove();
                Notiflix.Notify.success("Item added successfully");
                navigate(-1);
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
        });
      });
    } else {
      PostApiCall.postRequest(
        {
          itemId: location.state,
          image:
            (fileName == "" || fileName === null || fileName == undefined) &&
              location.state != null
              ? previewUrl
              : JSON.parse(localStorage.getItem("LoginDetail")).fld_storeurl +
              "images/item/" +
              randomnumber +
              "-" +
              fileName,
          name: itemName,
          description: description,
          catId: selectedCategoryId,
          subCatId: selectedSubCategoryId,
          foodType: selectedFoodType,
          serviceType: serviceType,
          pricing: hasProperties ? pricing : null,
          discountPrice: hasProperties ? discountPrice : null,
          serviceInfo: serviceInfo,
          calCount: calCount,
          portionSize: portionSize,
          portionUnit: portionUnit,
          preparationTime: preparationTime,
          spiceLevel: spiceLevel,
          clientId: JSON.parse(localStorage.getItem("LoginDetail"))
            .fld_client_id,
          containsAlcohol: containsAlcohol,
          chefsChoice: chefsChoice,
          bestSeller: bestSeller,
          containsEgg: containsEgg,
          customizable: customizable,
          enabledFlag: enabled,
          createdOn: new Date(),
          createdBy: 1,
          updatedOn: new Date(),
          updatedBy: 1,
          maxaddons: hasAddons ? maxAddon : null,
          maxproperties: hasProperties ? maxProperties : null,
        },
        "updateItemMaster"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Item added successfully");
            navigate(-1);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }
  }

  async function onUploadImage(obj) {
    Notiflix.Loading.dots("Uploading Images...");
    //

    let response;

    if (outputFileName != undefined) {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(originalImage, options).then((compressedFile) => {
        const form = new FormData();

        form.append("file", compressedFile);
        form.append("foldername", "item");
        form.append("filename", compressedFile.name);
        response = fetch(ImageApiUrl, {
          method: "POST",
          body: form,
        })
          .then((response) => response.json())
          .then((data) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Image successfully uploaded.");
          });
      });
    }
  }

  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Add New Item</h5>
              </div>
            </div>
            <div className="col-lg-5">
              <ImgUpload
                onChange={photoUpload}
                src={
                  previewUrl != "" && previewUrl != undefined
                    ? previewUrl
                    : uploadImage
                }
              />
            </div>
            <div className="col-lg-7">
              <div className="col-12">
                {/* <label className="me-3">Item Name</label>
                <input
                  className="w-100"
                  type="text"
                  value={itemName}
                  onChange={(e) => {
                    setItemName(e.target.value);
                  }}
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Item Name"
                  variant="outlined"
                  className="w-100"
                  value={itemName}
                  onChange={(e) => {
                    setItemName(e.target.value);
                  }}
                />
              </div>

              <div className="mt-3 col-12">
                {/* <select
                  className="me-3 w-100"
                  value={selectedCategoryId}
                  onChange={(e) => {
                    setSelectedCategoryId(e.target.value);
                  }}>
                  Category Id
                  <option>Select Category Id</option>
                  {allCategoryId.map((category, i) => {
                    return (
                      <option value={category.FLD_Category_Id}>
                        {category.FLD_Category_Id}
                      </option>
                    );
                  })}
                </select> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategoryId}
                    onChange={(e) => {
                      setSelectedCategoryId(e.target.value);
                    }}
                    label="Category">
                    {allCategoryId.map((category, i) => {
                      return (
                        <MenuItem value={category.FLD_Category_Id}>
                          {category.FLD_Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-3 col-12">
                {/* <select
                  className="me-3 w-100"
                  value={selectedSubCategoryId}
                  onChange={(e) => {
                    setSelectedSubCategoryId(e.target.value);
                  }}>
                  Sub Category Id
                  <option>Select Sub Category Id</option>
                  {allSubCategoryId.map((subcategory, i) => {
                    return (
                      <option value={subcategory.FLD_Sub_Cat_Id}>
                        {subcategory.FLD_Sub_Cat_Id}
                      </option>
                    );
                  })}
                </select> */}

                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sub Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSubCategoryId}
                    onChange={(e) => {
                      setSelectedSubCategoryId(e.target.value);
                    }}
                    label="Sub Category"
                  >
                    {allSubCategoryId.map((subcategory, i) => {
                      return (
                        <MenuItem value={subcategory.FLD_Sub_Cat_Id}>
                          {subcategory.FLD_Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </div>
              <div className="col-12">
                {/* <label className="me-3">Description</label>
                <input
                  className="w-100"
                  type="text"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                /> */}

                <TextField
                  //  placeholder="Description"
                  multiline
                  rows={4}
                  maxRows={4}
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  className="mt-3 w-100"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-6 mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Food Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFoodType}
                  onChange={(e) => {
                    setSelectedFoodType(e.target.value);
                  }}
                  label="Food Type">
                  {allFoodType.map((foodType, i) => {
                    return (
                      <MenuItem value={foodType.fld_food_type}>
                        {foodType.fld_food_type}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Service Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={serviceType}
                  onChange={(e) => {
                    setServiceType(e.target.value);
                  }}
                  label="Service Type">
                  <MenuItem value="Takeaway">Takeaway</MenuItem>
                  <MenuItem value="Delivery">Delivery</MenuItem>
                  <MenuItem value="Both">Both</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6">
              {/* <label className="me-3">Pricing</label>
                <input
                  className="w-100"
                  type="text"
                  value={pricing}
                  onChange={(e) => {
                    setPricing(e.target.value);
                  }}
                /> */}
              <TextField
                id="outlined-basic"
                label="Pricing"
                type="number"
                variant="outlined"
                className="mt-3 w-100"
                value={pricing}
                onChange={(e) => {
                  setPricing(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              {/* <label className="me-3">Discount Price</label>
                <input
                  className="w-100"
                  type="text"
                  value={discountPrice}
                  onChange={(e) => {
                    setDiscountPrice(e.target.value);
                  }}
                /> */}
              <TextField
                id="outlined-basic"
                label="Discount Price"
                type="number"
                variant="outlined"
                className="mt-3 w-100"
                value={discountPrice}
                onChange={(e) => {
                  setDiscountPrice(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-4">
              {/* <label className="me-3">Calorie Count</label>
                <input
                  className="w-100"
                  type="text"
                  value={calCount}
                  onChange={(e) => {
                    setCalCount(e.target.value);
                  }}
                /> */}

              <TextField
                id="outlined-basic"
                label="Calorie Count"
                variant="outlined"
                className="mt-3 w-100"
                value={calCount}
                onChange={(e) => {
                  setCalCount(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4">
              {/* <label className="me-3">Portion Size</label>
                <input
                  className="w-100"
                  type="text"
                  value={portionSize}
                  onChange={(e) => {
                    setPortionSize(e.target.value);
                  }}
                /> */}

              <TextField
                id="outlined-basic"
                label="Portion Size"
                variant="outlined"
                className="mt-3 w-100"
                value={portionSize}
                onChange={(e) => {
                  setPortionSize(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4">
              {/* <label className="me-3">Portion Unit</label>
                <input
                  className="w-100"
                  type="text"
                  value={portionUnit}
                  onChange={(e) => {
                    setPortionUnit(e.target.value);
                  }}
                /> */}

              <TextField
                id="outlined-basic"
                label="Portion Unit"
                variant="outlined"
                className="mt-3 w-100"
                value={portionUnit}
                onChange={(e) => {
                  setPortionUnit(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              {/* <label className="me-3">Service Info</label>
                <input
                  className="w-100"
                  type="text"
                  value={serviceInfo}
                  onChange={(e) => {
                    setServiceInfo(e.target.value);
                  }}
                /> */}

              <TextField
                id="outlined-basic"
                label="Service Info"
                variant="outlined"
                className="mt-3 w-100"
                value={serviceInfo}
                onChange={(e) => {
                  setServiceInfo(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              {/* <label className="me-3">Preparation Time</label>
                <input
                  className="w-100"
                  type="text"
                  value={preparationTime}
                  onChange={(e) => {
                    setPreparationTime(e.target.value);
                  }}
                /> */}

              <TextField
                id="outlined-basic"
                label="Preparation Time"
                variant="outlined"
                className="mt-3 w-100"
                value={preparationTime}
                onChange={(e) => {
                  setPreparationTime(e.target.value);
                }}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Max Addons"
                type="number"
                variant="outlined"
                className="mt-3 w-100"
                value={maxAddon}
                onChange={(e) => {
                  setMaxAddon(e.target.value);
                }}
              />
            </div>
            <div className="col-6 mt-3">
              {/* <TextField
                id="outlined-basic"
                label="Max Properties"
                type="number"
                variant="outlined"
                className="mt-3 w-100"
                value={maxProperties}
                onChange={(e) => {
                  setMaxProperties(e.target.value);
                }}
              /> */}
            </div>
            <div className="mt-3 col-lg-3">
              {/* <label>Contains Alcohol</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="contains-alcohol"
                      className="ms-2 me-1"
                      checked={containsAlcohol == "Yes" ? true : false}
                      onChange={() => {
                        setContainsAlcohol("Yes");
                      }}
                    />
                    Yes
                  </span>

                  <span className="d-flex">
                    <input
                      type="radio"
                      name="contains-alcohol"
                      className="me-1"
                      checked={containsAlcohol == "No" ? true : false}
                      onChange={() => {
                        setContainsAlcohol("No");
                      }}
                    />
                    No
                  </span>
                </div> */}
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Contains Alcohol
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="contains-alcohol"
                      checked={containsAlcohol == "Yes" ? true : false}
                      onChange={() => {
                        setContainsAlcohol("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="contains-alcohol"
                      checked={containsAlcohol == "No" ? true : false}
                      onChange={() => {
                        setContainsAlcohol("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              {/* <label>Chefs Choice</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="chefs-choice"
                      className="ms-2 me-1"
                      checked={chefsChoice == "Yes" ? true : false}
                      onChange={() => {
                        setChefsChoice("Yes");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="chefs-choice"
                      className="me-1"
                      checked={chefsChoice == "No" ? true : false}
                      onChange={() => {
                        setChefsChoice("No");
                      }}
                    />
                    No
                  </span>
                </div> */}

              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Chefs Choice
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="chefs-choice"
                      checked={chefsChoice == "Yes" ? true : false}
                      onChange={() => {
                        setChefsChoice("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="chefs-choice"
                      checked={chefsChoice == "No" ? true : false}
                      onChange={() => {
                        setChefsChoice("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              {/* <label>Best Seller</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="bestseller"
                      className="ms-2 me-1"
                      checked={bestSeller == "Yes" ? true : false}
                      onChange={() => {
                        setBestSeller("Yes");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="bestseller"
                      className="me-1"
                      checked={bestSeller == "No" ? true : false}
                      onChange={() => {
                        setBestSeller("No");
                      }}
                    />
                    No
                  </span>
                </div> */}

              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Best Seller
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="bestseller"
                      checked={bestSeller == "Yes" ? true : false}
                      onChange={() => {
                        setBestSeller("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="bestseller"
                      checked={bestSeller == "No" ? true : false}
                      onChange={() => {
                        setBestSeller("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              {/* <label>Contains Egg</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="contains-egg"
                      className="ms-2 me-1"
                      checked={containsEgg == "Yes" ? true : false}
                      onChange={() => {
                        setContainsEgg("Yes");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="contains-egg"
                      className="me-1"
                      checked={containsEgg == "No" ? true : false}
                      onChange={() => {
                        setContainsEgg("No");
                      }}
                    />
                    No
                  </span>
                </div> */}

              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Contains Egg
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="contains-egg"
                      checked={containsEgg == "Yes" ? true : false}
                      onChange={() => {
                        setContainsEgg("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="contains-egg"
                      checked={containsEgg == "No" ? true : false}
                      onChange={() => {
                        setContainsEgg("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              {/* <label>Customizable</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="customizable"
                      className="ms-2 me-1"
                      checked={customizable == "Yes" ? true : false}
                      onChange={() => {
                        setCustomizable("Yes");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="customizable"
                      className="me-1"
                      checked={customizable == "No" ? true : false}
                      onChange={() => {
                        setCustomizable("No");
                      }}
                    />
                    No
                  </span>
                </div> */}
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Customizable
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="customizable"
                      checked={customizable == "Yes" ? true : false}
                      onChange={() => {
                        setCustomizable("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="customizable"
                      checked={customizable == "No" ? true : false}
                      onChange={() => {
                        setCustomizable("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              {/* <label>Display In Website</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="status"
                      className="ms-2 me-1"
                      checked={enabled == "Yes" ? true : false}
                      onChange={() => {
                        setEnabled("Yes");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="status"
                      className="me-1"
                      checked={enabled == "No" ? true : false}
                      onChange={() => {
                        setEnabled("No");
                      }}
                    />
                    No
                  </span>
                </div> */}

              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Display In Website
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="status"
                      checked={enabled == "Yes" ? true : false}
                      onChange={() => {
                        setEnabled("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="status"
                      checked={enabled == "No" ? true : false}
                      onChange={() => {
                        setEnabled("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Addons
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="status"
                      checked={hasAddons == "Yes" ? true : false}
                      onChange={() => {
                        setHasAddons("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="status"
                      checked={hasAddons == "No" ? true : false}
                      onChange={() => {
                        setHasAddons("No");
                        setMaxAddon("");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="mt-3 col-lg-3">
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2">
                Properties
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="status"
                      checked={hasProperties == "Yes" ? true : false}
                      onChange={() => {
                        setHasProperties("Yes");
                        setMaxProperties(1);
                        setPropertiesModal(true);
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="status"
                      checked={hasProperties == "No" ? true : false}
                      onChange={() => {
                        setHasProperties("No");
                        setMaxProperties("");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="default-btn btn-white border-0 btn ms-auto"
                onClick={() => {
                  onSaveItem();
                }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={PropertiesModal}
        onHide={handleClosePropertiesModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className="property-yes-modal">
        <Modal.Header
          className="justify-content-end border-0"
          closeButton></Modal.Header>

        <Modal.Body>
          <h6>Enabling the properties will cause the item price to be selected from the pricing associated with those properties. <br />
            This will reset the item price. Do you want to proceed?</h6>
          <div className="text-end mt-4 w-100">
            <button
              type="button"
              className="default-btn btn-white border-0 btn ms-auto"
              onClick={() => {
                setHasProperties("Yes");
                setPricing(null);
                setDiscountPrice(null);
                setPropertiesModal(false);
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="default-btn btn-white border-0 btn ms-2"
              onClick={() => {
                handleClosePropertiesModal();
              }}>
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemAction;
