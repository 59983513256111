import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import uploadImage from '../../assets/images/upload-pang.png'

const CategoryMasterForm = () => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const [previewUrl, setPreviewUrl] = useState("");
  const [updatedItemsData, setUpdatedItemsData] = useState(false);
  const [name, setName] = useState("");
  const [catId, setCatId] = useState(null);
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFileName] = useState("");
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://api.grubdigest.com/api-grub/AddImage"
  );
  const [enabledflag, setEnabledFlag] = useState("Yes");
  const [VariantImage, setVariantImage] = useState("");
  const [categoryList, setcategoryList] = useState([]);

  const ImgUpload = ({ onChange, src }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload fas w-100 d-flex justify-content-center"
    >
      <div className="img-wrap img-upload">
        <img for="photo-upload" src={src} style={{ borderRadius: "5%" }} />
      </div>
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        onChange={onChange}
      />
    </label>
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (name !== "") {
      onPost();
    } else {
      Notiflix.Notify.failure("Please enter Name");
    }
  };
  const onPost = () => {
    Notiflix.Loading.dots();
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    var fileName = outputFileName.split(".").slice(0, -1).join(".") + ".webp";
    PostApiCall.postRequest(
      {
        catid: catId == null ? null : catId,
        name: name,
        image:
          "https://grubdigest.com/images/category/" +
          randomnumber +
          "-" +
          fileName,
        enabledflag: enabledflag,
        createdby: null,
      },
      "updateCategoryMaster"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          const form = new FormData();
          form.append("file", originalImage);
          form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
            ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
            : "");
          form.append("foldername", "category");
          form.append("filename", randomnumber + "-" + outputFileName);
          let response;
          response = fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((res) => {

          });

          Notiflix.Loading.remove();
          Notiflix.Notify.success("Category added successfully");
          navigate(-1);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };


  useEffect(() => {
    if (params.action == "edit" && location.state != undefined) {

      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_Category_Id = ${location.state}`,
          columns: "*",
        },
        "getCategoryList"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {

            setCatId(obj.message[0].FLD_Category_Id);
            setPreviewUrl(obj.message[0].FLD_Image);
            setVariantImage(obj.message[0].FLD_Image);
            setName(obj.message.fld_name);
            setEnabledFlag(obj.message[0].FLD_Enabled_Flag);
          }
        })
      );
    }
  }, []);

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
  };

  return (
    <>

      <div className="row form-container">

        <div className="col-12">
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                {/* <label htmlFor="sw-arrows-first-name">Item Image</label> */}
                <div className="div1">
                  <ImgUpload onChange={photoUpload} src={previewUrl != "" && previewUrl != undefined ? previewUrl : uploadImage} />
                </div>
                (Upload image in png/jpg/webp format maximum size of 1MB* )
              </div>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  {/* <label>
                        Item Name
                        <span style={{ color: "red", marginTop: "1.5rem" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        value={name}
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      /> */}
                  <TextField
                    id="outlined-basic"
                    label="Category Name *"
                    variant="outlined"
                    className="w-100"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Col>
                <Col xl="6" className="mt-4">
                  {/* <div className="form-group">
                        <label htmlFor="CategoryName">
                          Show On Website
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <label className="radio-inline">
                          <input
                            type="radio"
                            name="optradio"
                            checked={enabledflag == "Yes" ? true : false}
                            onChange={() => {
                              setEnabledFlag("Yes");
                            }}
                          />{" "}
                          Yes
                        </label>
                        <label
                          className="radio-inline"
                          style={{ marginLeft: "10px" }}>
                          <input
                            type="radio"
                            name="optradio"
                            checked={enabledflag == "No" ? true : false}
                            onChange={() => {
                              setEnabledFlag("No");
                            }}
                          />{" "}
                          No
                        </label>
                      </div> */}

                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="text-dark me-2"
                  >
                    Show On Website *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          name="bestseller"
                          checked={enabledflag == "Yes" ? true : false}
                          onChange={() => {
                            setEnabledFlag("Yes");
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          name="bestseller"
                          checked={enabledflag == "No" ? true : false}
                          onChange={() => {
                            setEnabledFlag("No");
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </Col>
            <Col xl="12" className="text-end">
              {" "}
              <button
                className="default-btn btn-white border-0 btn ms-auto"
                type="submit"
                onClick={onSubmit}
              >
                Save
              </button>
            </Col>
          </Row>
        </div>
      </div>

    </>
  );
};
export default CategoryMasterForm;
