import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Offcanvas } from "react-bootstrap";
import { BsFillArrowRightCircleFill, BsFillCircleFill } from "react-icons/bs";
import { LuVegan } from "react-icons/lu";
import PostApiCall from "../../helper/Api";
import "../../pages/Dashboard/Dashboard.css";
import { store } from "../Context/store";

export default function RecentOrdersChart() {
  const [show, setShow] = useState(false);
  const { allRecentOrders, setAllRecentOrders, sortType, searchBy, currencyCode } = useContext(store);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderDateTime, setOrderDateTime] = useState("");
  const [orderType, setOrderType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [paymentTrxNo, setPaymentTrxNo] = useState("")
  const [address, setAddress] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [prices, setPrices] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [itemTotal, setItemTotal] = useState("");
  const [couponName, setCouponName] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [offerDiscount, setOfferDiscount] = useState("");
  const [taxes, setTaxes] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [packingCharges, setPackingCharges] = useState("");
  const [tips, setTips] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [totalOrderSavings, setTotalOrderSavings] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      PostApiCall.postRequest(
        {
          filter: "last10orders",
        },
        "GetOrdersListFiltered"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.message.length != 0) {
            setAllRecentOrders(obj.message);

            Notiflix.Loading.remove();
          } else {
            setAllRecentOrders(obj.message);
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    sortBy();
  }, [sortType, allRecentOrders]);

  function sortBy() {
    if (sortType == "Alphabetical Order") {
      setAllRecentOrders(
        allRecentOrders?.sort(function (a, b) {
          return a.customer_name.toLowerCase() > b.customer_name.toLowerCase()
            ? 1
            : -1;
        })
      );
    } else if (sortType == "Newest") {
      var sortedOrder = [];
      sortedOrder = allRecentOrders;

      sortedOrder.sort(function (a, b) {
        var dateA = new Date(a),
          dateB = new Date(b);
        if (dateB < dateA) {
          return -1;
        } else {
          return 1;
        }
      })
      // setAllRecentOrders(
      //   allRecentOrders.sort(function (a, b) {
      //     return new Date(b.fld_order_date_org) - new Date(a.fld_order_date_org)
      //       ? 1
      //       : -1;
      //   })
      // );
    } else if (sortType == "Oldest") {
      setAllRecentOrders(
        allRecentOrders.sort(function (a, b) {
          return new Date(a.fld_order_date_org) - new Date(b.fld_order_date_org)
            ? 1
            : -1;
        })
      );
    } else if (sortType == "Highest Order Value") {

      allRecentOrders.sort(function (a, b) {
        return b.Total_Inclusive_Tax - a.Total_Inclusive_Tax;
      })
      setAllRecentOrders(
        allRecentOrders.sort(function (a, b) {
          return a.Total_Inclusive_Tax - b.Total_Inclusive_Tax;
        })
      );
    } else if (sortType == "") {
      setAllRecentOrders(allRecentOrders);
    }
  }

  return (
    <>
      <Card className="shadow border-light recent-order-section">
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center mb-3 recent-inner">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Recent Orders</h5>
                <p>Latest 10 New Orders</p>
              </div>
            </div>
            <div className="order-list-view-main">
              {allRecentOrders.length > 0 ?
                allRecentOrders?.filter((data) => {// to be checked
                  if (searchBy == "") {
                    return data;
                  }
                  if (
                    searchBy !== "" &&
                    data.SearchFiled.toLowerCase().includes(
                      searchBy.toLowerCase()
                    )
                  ) {
                    return data;
                  }
                })
                  .map((recentorders) => {
                    return (
                      <div className="order-list-view">
                        <div className="d-flex justify-content-start gap-3">
                          <div>
                            <div className="d-flex">
                              <h6 className="text-danger">
                                {recentorders.fld_order_number}
                              </h6>
                            </div>
                            <p>
                              {recentorders.fld_order_date_org +
                                " " +
                                recentorders.fld_time}
                            </p>
                          </div>
                        </div>
                        <div className="recent-orders-name">
                          <h6 className="fw-bold">
                            {recentorders.customer_name}
                          </h6>
                          {recentorders.fld_address_1 != null ?
                            <p>
                              {recentorders.fld_address_1 +
                                "" +
                                recentorders.fld_address_1}
                            </p>
                            : ""}
                        </div>
                        <div>
                          <h6 className="fw-bold">
                            {currencyCode} {" "}
                            {recentorders.Total_Inclusive_Tax}
                          </h6>
                          <p>{recentorders.total_items} Items</p>
                        </div>
                        <div>
                          <h6 className="fw-bold">Order Type</h6>
                          <p>{recentorders.fld_order_type}</p>
                        </div>
                        <div>
                          <h6 className="fw-bold">Payment Details</h6>
                          <p>{recentorders.fld_payment_mode}</p>
                        </div>

                        <div className="order-item-options">
                          <Button
                            variant="white"
                            onClick={() => {
                              setOrderNumber(recentorders.fld_order_number);
                              setOrderDateTime(
                                recentorders.fld_order_date_org +
                                "" +
                                recentorders.fld_time
                              );
                              setOrderType(recentorders.fld_order_type);
                              setCustomerName(recentorders.customer_name);
                              setCouponName(recentorders.fld_code)
                              setCustomerMobile(recentorders.fld_mobile);
                              setPaymentDetails(recentorders.fld_payment_status);
                              setPaymentTrxNo(recentorders.fld_payment_trx_num)
                              setAddress(
                                recentorders.fld_address_1 +
                                "" +
                                recentorders.fld_address_1
                              );
                              Notiflix.Loading.dots();
                              PostApiCall.postRequest(
                                {
                                  id: null,
                                  orderid: recentorders.fld_cart_id,
                                  stateid: null,
                                },
                                "getOrderDetails"
                              ).then((results1) =>
                                results1.json().then((obj1) => {
                                  if (
                                    results1.status == 200 ||
                                    results1.status == 201
                                  ) {
                                    handleShow();
                                    setOrderDetails(obj1.data);
                                    Notiflix.Loading.remove();
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure("Error Occurred");
                                  }
                                })
                              );
                            }}
                            className="recent-order-icon"
                            id="dropdown-basic"
                          >
                            <BsFillArrowRightCircleFill />
                          </Button>
                        </div>
                      </div>
                    );
                  }) : ""}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">Order Details</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="kot-offcanvas-box">
            <h6>Order Number</h6>
            <p>{orderNumber}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Order Date & Time</h6>
            <p>{orderDateTime}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Order Type</h6>
            <p>{orderType}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Customer Details</h6>
            <p>{customerName}</p>
            <p>Mobile # {customerMobile}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Payment Details</h6>
            <p>{paymentTrxNo}</p>
            <p>{paymentDetails}</p>
          </div>
          {orderType != "TakeAway" &&
            <div className="kot-offcanvas-box">
              <h6>Deliver to</h6>
              <p>{address}</p>
            </div>}

          <div className="kot-offcanvas-box mb-5 pb-2">
            <h6 className="border-bottom py-2 border-top ">Order Summary</h6>
            <div className="kot-card-mid-main kot-offcanvas-card-main mx-1">
              {orderDetails.map((order) => {
                return (
                  <div className="kot-card-mid mt-3 pb-2 ">
                    <p
                      className={`text-dark fw-bold ${order.FLD_Food_Type == "NonVegetarian"
                        ? "non-veg-icon"
                        : "veg-icon"
                        } mb-1`}
                    >
                      {" "}
                      {order.FLD_Food_Type == "NonVegetarian" ? (
                        <BsFillCircleFill />
                      ) : order.FLD_Food_Type == "Vegetarian" ? <BsFillCircleFill /> : (
                        <LuVegan />
                      )}{" "}
                      {order.fld_variantname}
                    </p>
                    <div className="d-flex justify-content-between mt-2">
                      <p className="text-dark">
                        {order.fld_quantity} X {currencyCode} {" "}
                        {order.fld_website_discount_price}
                      </p>
                      <p className="text-dark">
                        {currencyCode} {" "}
                        {order.netvalue}
                      </p>
                    </div>
                  </div>
                );
              })}
              {orderDetails[0]?.fld_special_instruction &&
                <>
                  <h6 className="border-bottom py-2 border-top">
                    Additional Instructions
                  </h6>
                  <div>
                    <p className="text-dark py-2">
                      {orderDetails.length != 0
                        ? orderDetails[0].fld_special_instruction
                        : ""}
                    </p>
                  </div>
                </>}

              <h6 className="border-bottom py-2 border-top">Billing Summary</h6>

              <div className="kot-card-mid d-flex justify-content-between mt-2">
                <p className="text-dark fw-bold"> Item Total</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_total_net_amount.toFixed(2)
                    : 0.0}
                </p>
              </div>
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Coupon - {couponName}</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_coupon_discount.toFixed(2)
                    : 0.0}
                </p>
              </div>
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Offer Discount</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {offerDiscount}
                </p>
              </div> */}
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Taxes </p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_total_gst.toFixed(2)
                    : 0.0}
                </p>
              </div>
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Delivery Charges</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_shipping_charge.toFixed(2)
                    : "Free"}
                </p>
              </div>
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Packing Charges </p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {packingCharges}
                </p>
              </div> */}
              {orderDetails[0]?.fld_tip &&
                <div className="kot-card-mid d-flex justify-content-between">
                  <p className="text-dark fw-bold"> Tips</p>
                  <p className="text-dark">
                    {currencyCode} {" "}
                    {orderDetails.length != 0
                      ? orderDetails[0].fld_tip.toFixed(2)
                      : "0"}
                  </p>
                </div>
              }
              <div className="kot-card-mid d-flex justify-content-between border-top border-bottom mt-3 py-3">
                <h5 className="text-dark fw-bold">Grand Total</h5>
                <h5 className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_cart_amount_no_shipping.toFixed(2)
                    : 0.0}
                </h5>
              </div>
              {orderDetails[0]?.fld_coupon_discount &&
                <div className="kot-save ">
                  <h6 className="text-white fw-bold">
                    Total Order Savings {currencyCode} {" "}
                    {orderDetails.length != 0 &&
                      orderDetails[0].fld_coupon_discount != null
                      ? orderDetails[0].fld_coupon_discount.toFixed(2)
                      : 0.0}
                  </h6>
                </div>}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
