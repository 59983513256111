import {
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./KeyStringAction.css";
const KeyStringsAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [displayIn, setDisplayIn] = useState("Yes");

  useEffect(() => {
    if (location.state != null) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_Key_Id=${location.state}`,
        },
        "GetKeyStringsById"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            Notiflix.Loading.remove();
            setName(obj1.data[0].FLD_Key_Name);
            setValue(obj1.data[0].FLD_Key_Value);
            setDisplayIn(obj1.data[0].FLD_Display_In);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }
  }, []);

  function onSaveKeyString() {
    if (name != "" && value != "") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          id: location.state,
          name: name,
          value: value,
          displayIn: displayIn,
        },
        "AddKeyStrings"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Key string added successfully");
            navigate(-1);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }
  }
  return (
    <section className="pb-lg-5 pt-4 margin-left">
      <div className="container-fluid">
        <div className="row form-container">
          <div className="col-12">
            <div className="dash-heading mb-4">
              <h5 className="fw-bold text-black">Add New Key Strings</h5>

            </div>
          </div>
        </div>
        <div className="row px-lg-5 mx-lg-5">
          <div className="col-md-6 mb-3 mb-lg-0">
            {/* <div className="form-box align-items-center d-md-flex border-light mb-4"> */}
            {/* <label className="me-3">Name</label>
                <input
                  className="w-100"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                /> */}

            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              className="w-100"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 mb-3 mb-lg-0">
            {/* <div className="form-box align-items-center d-md-flex border-light mb-4"> */}
            {/* <label className="me-3">Value</label>
                <input
                  className="w-100"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                /> */}
            <TextField
              id="outlined-basic"
              label="Value"
              variant="outlined"
              className="w-100"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </div>
          <div className="col-12 mt-3">
            {/* <label>Display In Website</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="status"
                      className="ms-2 me-1"
                      checked={displayIn == "Yes" ? true : false}
                      onChange={() => {
                        setDisplayIn("Yes");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="status"
                      className="me-1"
                      checked={displayIn == "No" ? true : false}
                      onChange={() => {
                        setDisplayIn("No");
                      }}
                    />
                    No
                  </span>
                </div> */}

            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="text-dark me-2"
            >
              Display In Website
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    name="contains-alcohol"
                    checked={displayIn == "Yes" ? true : false}
                    onChange={() => {
                      setDisplayIn("Yes");
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    name="contains-alcohol"
                    checked={displayIn == "No" ? true : false}
                    onChange={() => {
                      setDisplayIn("No");
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </div>
          <div className="text-end">
            <button
              type="button"
              className="default-btn btn-white border-0 btn ms-auto"
              onClick={() => {
                onSaveKeyString();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default KeyStringsAction;
