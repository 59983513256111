import React from 'react';
import "../Reports.css";
import { Col, Container, Row } from 'react-bootstrap';

function CategoryWise() {
  return (
    <section className="pb-lg-5 pt-4 margin-left">
      <Container fluid className='inner-page-height'>
        <Row>
          <Col>
          <div className="dash-heading">
              <h5 className="fw-bold text-black">Category Wise</h5>
              {/* <p>??</p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CategoryWise;