import React, { useEffect, useState } from "react";
import { ToWords } from "to-words";
import PostApiCall from "../../helper/Api";

function OrderForm({ orderid }) {
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [toWordsValue, setToWordsValue] = useState("");

  var logindetails = JSON.parse(localStorage.getItem("LoginDetail"));
  useEffect(() => {
    getOrderDetails();
    getClientData();
  }, []);
  const getClientData = () => {
    PostApiCall.postRequest({}, "getClientData").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          setClientData(obj1.message);
        } else {
        }
      })
    );
  };
  const getOrderDetails = () => {
    PostApiCall.postRequest(
      {
        cartid: orderid,
      },
      "getOrderById"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setOrderData(obj.data);
          PostApiCall.postRequest(
            {
              id: null,
              orderid: orderid,
              stateid: null,
            },
            "getOrderDetails"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                setOrderDetails(obj1.data);
                const toWords = new ToWords();
                let words = toWords.convert(
                  obj1.data[0]?.fld_cart_amount_no_shipping
                );
                setToWordsValue(words);
              } else {
              }
            })
          );
        }
      })
    );
  };
  return (
    <>
      {orderData?.map((data) => {
        return (
          <div style={{ font: '11pt "Tahoma"', margin: 0, padding: 0 }}>
            <div
              style={{
                width: "21cm",
                minHeight: "29.7cm",
                padding: "1cm",
                margin: "1cm auto",
                border: "1px #d3d3d3 solid",
                background: "white",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
              }}>
              <div
                style={{
                  padding: "0cm",
                  border: "0px #d3d3d3 solid",
                  height: "256mm",
                  width: "100mm",
                  margin: "0 auto",
                }}>
                <div
                  style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    marginBottom: "15pt",
                  }}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: "5pt",
                      color: "#000",
                    }}>
                    {clientData[0]?.fld_restaurantname}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: "5pt",
                      color: "#000",
                    }}>
                    <strong> restaurant service </strong>
                  </p>
                  {/* <p style={{ marginTop: 0, marginBottom: "5pt" }}>
                                        <strong> sac code: 9963210 </strong>
                                    </p> */}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    textTransform: "capitalize",
                    marginBottom: "15pt",
                    color: "#000",
                  }}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: "5pt",
                      color: "#000",
                    }}>
                    {clientData[0]?.fld_address}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: "5pt",
                      color: "#000",
                    }}>
                    phone number - {clientData[0]?.fld_phonenumber}
                  </p>
                  {/* <p style={{ textTransform: "uppercase" }}>CIN: UG2F3GDFD456TG09</p> */}
                  {/* <p style={{ textTransform: "uppercase" }}>
                                        GSTIN: SG2F3GDFD456TG0T
                                    </p> */}
                  {/* <p style={{ marginTop: 0, marginBottom: "5pt" }}>
                                        reg. off. 19 a shivaji marg, nazafgarh road
                                    </p> */}
                  {/* <p style={{ marginTop: 0, marginBottom: "5pt" }}>
                                        new moti nagar delhi - 110015
                                    </p> */}
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: "5pt",
                      color: "#000",
                    }}>
                    {clientData[0]?.fld_store_url}
                  </p>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    textTransform: "capitalize",
                    marginBottom: "15pt",
                  }}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: "5pt",
                      color: "#000",
                    }}>
                    [INVOICE]
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "1rem",
                      color: "#000",
                    }}>
                    <strong>
                      {" "}
                      order no. {data.fld_order_number} | <br />{" "}
                      {data.fld_cart_date} | {data.fld_cart_time}{" "}
                    </strong>
                  </p>
                  {data.fld_order_type == "TakeAway" ? "" :
                    <p
                      style={{
                        textAlign: "left",
                        marginBottom: "0.5rem",
                        color: "#000",
                      }}>
                      <strong> Deliver to </strong> <br />
                      <span style={{ maxWidth: "200px" }}>{data.fld_address_1}</span>
                    </p>
                  }
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "0.5rem",
                      color: "#000",
                    }}>
                    <strong> order type: </strong>
                    <span>{data.fld_order_type}</span>
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "1rem",
                      color: "#000",
                    }}>
                    <b> customer details</b>
                    <br />
                    <span>{data.fld_customer_name}</span>
                    <br />
                    <span>{data.fld_customer_email}</span>
                    <br />
                    <span>{data.fld_customer_mobile}</span>
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "1rem",
                      color: "#000",
                    }}>
                    <b> Payment details</b>
                    <br />
                    <span>
                      <strong>Payment Mode: </strong>
                      {data.fld_payment_mode}
                    </span>
                    <br />
                    <span>
                      <strong>Payment Status: </strong>
                      Success
                    </span>
                    <br />
                    <span>
                      <strong>Transaction Number: </strong>
                      1234567890
                    </span>
                  </p>

                  <div>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginBottom: "15pt",
                      }}>
                      <tbody>
                        <tr>
                          <th
                            colSpan={3}
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "left",
                            }}>
                            Description
                          </th>
                          <th
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "right",
                            }}>
                            Price
                          </th>
                          <th
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "right",
                            }}>
                            Qty
                          </th>
                          <th
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "right",
                            }}>
                            Amount
                          </th>
                        </tr>
                        {orderDetails.map((order, i) => {
                          return (
                            <tr>
                              <td
                                colSpan={3}
                                style={{ padding: "5px 0", textAlign: "left" }}>
                                {order.fld_variantname}
                              </td>
                              <td
                                style={{
                                  padding: "5px 0",
                                  textAlign: "right",
                                }}>
                                {clientData[0]?.fld_currency}{" "}
                                {order.fld_website_discount_price}
                              </td>
                              <td
                                style={{
                                  padding: "5px 0",
                                  textAlign: "right",
                                }}>
                                {order.fld_quantity}
                              </td>
                              <td
                                style={{
                                  padding: "5px 0",
                                  textAlign: "right",
                                }}>
                                {clientData[0]?.fld_currency} {order.netvalue}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <th
                            colSpan={3}
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "left",
                            }}>
                            sub total
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "right",
                            }}>
                            {orderDetails[0]?.item_count}
                          </th>
                          <th
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "right",
                            }}>
                            {clientData[0]?.fld_currency}{" "}
                            {orderDetails[0]?.fld_cart_amount_no_gst}
                          </th>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "left" }}>
                            Add Tip
                          </td>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "right" }}>
                            {clientData[0]?.fld_currency}{" "}
                            {orderDetails[0]?.fld_tip}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "left" }}>
                            Add Shipping
                          </td>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "right" }}>
                            {clientData[0]?.fld_currency}{" "}
                            {orderDetails[0]?.fld_shipping_charge}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "left" }}>
                            Add Tax
                          </td>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "right" }}>
                            {clientData[0]?.fld_currency}{" "}
                            {orderDetails[0]?.fld_total_gst}
                          </td>
                        </tr>
                        {/* <tr>
                                                    <td
                                                        colSpan={3}
                                                        style={{ padding: "5px 0", textAlign: "left" }}
                                                    >
                                                        Add CGST 9%ON (80.00)
                                                    </td>
                                                    <td
                                                        colSpan={3}
                                                        style={{ padding: "5px 0", textAlign: "right" }}
                                                    >
                                                        7.20
                                                    </td>
                                                </tr> */}
                        <tr>
                          <th
                            colSpan={3}
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "left",
                            }}>
                            amount including Tax
                          </th>
                          <th
                            colSpan={3}
                            style={{
                              borderTop: "1px solid #000",
                              borderBottom: "1px solid #000",
                              padding: "5px 0",
                              textAlign: "right",
                            }}>
                            {clientData[0]?.fld_currency}{" "}
                            {orderDetails[0]?.fld_cart_amount_no_shipping}
                          </th>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "left" }}>
                            amount in words
                          </td>
                          <td
                            colSpan={3}
                            style={{ padding: "5px 0", textAlign: "right" }}>
                            {clientData[0]?.fld_currency} {toWordsValue}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p style={{ textTransform: "uppercase", color: "#000" }}>
                    thanks for placing order.
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default OrderForm;
