import Notiflix from "notiflix";
import { useContext } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { store } from "../../components/Context/store";
import PostApiCall from "../../helper/Api";
import "../../pages/Dashboard/Dashboard.css";

export default function DashboardFilter() {
  const { filterDashboard, setFilterDashBoard } = useContext(store);
  const { allCounts, setAllCounts } = useContext(store);

  function updateDashboard(filter) {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null) {
      PostApiCall.postRequest(
        {
          filter: filter,
        },
        "GetCountChips"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.message.length != 0) {
            setAllCounts(obj.message[0]);

            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    }

  }

  return (
    <>
      <Card className="shadow border-light">
        <Card.Body>
          <Card.Text>
            <div className="row justify-content-lg-between align-items-lg-center">
              <div className="col-lg-3 mb-3 mb-lg-0">
                <h5 className="fw-bold text-black">Filter Data by Date</h5>
                <p className="text-black">Select Date Range</p>
              </div>
              <div className="tab-filter col-lg-9 ps-0">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={() => {
                          updateDashboard("today");
                        }}
                      >
                        Today
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onClick={() => {
                          updateDashboard("tilldate");
                        }}
                      >
                        Till Date
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        onClick={() => {
                          updateDashboard("yesterday");
                        }}
                      >
                        Yesterday
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="forth"
                        onClick={() => {
                          updateDashboard("last7days");
                        }}
                      >
                        Last 7 days
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fifth"
                        onClick={() => {
                          updateDashboard("last30days");
                        }}
                      >
                        Last 30 days
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="sixth"
                        onClick={() => {
                          updateDashboard("thisweek");
                        }}
                      >
                        This Week
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="seventh"
                        onClick={() => {
                          updateDashboard("thismonth");
                        }}
                      >
                        This Month
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eighth">Custom Date Range</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
