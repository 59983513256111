import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import "./SideBar.css";
import { AiOutlineDashboard } from "react-icons/ai";
import {
  BiAddToQueue,
  BiDish,
  BiReceipt,
  BiSupport,
  BiUser,
} from "react-icons/bi";
import { BsFileBarGraph } from "react-icons/bs";
import { FaQuora } from "react-icons/fa";
import { FiPrinter } from "react-icons/fi";
import { GoStack } from "react-icons/go";
import {
  MdDinnerDining,
  MdFoodBank,
  MdLockOutline,
  MdOutlineFastfood,
  MdOutlineLiveHelp,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { TfiLayoutSlider } from "react-icons/tfi";
import { TbReportSearch } from "react-icons/tb";
import { RiCoupon2Line } from "react-icons/ri";

const SideBarOffline = (props) => {
  return (
    <>
      <div className="sidenavbar d-flex flex-column justify-content-between">
        <Sidebar collapsed={props.collapsed} className="pt-3">
          <Menu>
            {JSON.parse(localStorage.getItem("LoginDetail"))?.fld_email == "Sales@globaltrendz.com" ?
              <>
                <Link to={"/SalesDashboard"}>
                  <MenuItem className="sidebar-menuitem">
                    <div>
                      <AiOutlineDashboard className="sidebar-icon" /> Sales
                    </div>
                  </MenuItem>
                </Link>
              </>
              :
              <>
                <Link to={"/dashboard"}>
                  <MenuItem className="sidebar-menuitem">
                    <div>
                      <AiOutlineDashboard className="sidebar-icon" /> Dashboard
                    </div>
                  </MenuItem>
                </Link>
                <Link to={"/profile"}>
                  <MenuItem className="sidebar-menuitem">
                    <div>
                      <BiUser className="sidebar-icon" /> Profile
                    </div>
                  </MenuItem>
                </Link>
                <SubMenu
                  label="Menu"
                  className="sidebar-menuitem"
                  icon={<BiDish className="sidebar-icon" />}>
                  <Link to={"/categorymaster"}>
                    <MenuItem>
                      <div>
                        <GoStack className="sidebar-icon" /> Categories
                      </div>
                    </MenuItem>
                  </Link>
                  <Link to={"/itemmasteroffline"}>
                    <MenuItem>
                      <div>
                        <MdOutlineFastfood className="sidebar-icon" /> Items
                      </div>
                    </MenuItem>
                  </Link>
                  <MenuItem disabled>
                    <div>
                      <BiAddToQueue className="sidebar-icon" /> Addons
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  <MenuItem disabled>
                    <div>
                      <MdOutlineFastfood className="sidebar-icon" /> Item Property
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  <MenuItem disabled>
                    <div>
                      <MdFoodBank className="sidebar-icon" /> Food Type
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  <MenuItem disabled>
                    <div>
                      <MdDinnerDining className="sidebar-icon" /> Serving
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  {/* <MenuItem> Pdf</MenuItem> */}
                </SubMenu>
                <MenuItem className="sidebar-menuitem" disabled>
                  <div>
                    <BiUser className="sidebar-icon" /> Customers
                  </div>
                  <MdLockOutline className="sidebar-icon" />
                </MenuItem>
                <MenuItem className="sidebar-menuitem" disabled>
                  <div>
                    <BiReceipt className="sidebar-icon" /> Orders
                  </div>
                  <MdLockOutline className="sidebar-icon" />
                </MenuItem>
                <MenuItem className="sidebar-menuitem" disabled>
                  <div>
                    <FiPrinter className="sidebar-icon" /> KOT
                  </div>
                  <MdLockOutline className="sidebar-icon" />
                </MenuItem>
                <MenuItem className="sidebar-menuitem" disabled>
                  <div>
                    <RiCoupon2Line className="sidebar-icon" /> Offers
                  </div>
                  <MdLockOutline className="sidebar-icon" />
                </MenuItem>
                {/* <SubMenu
              label="CRM"
              className="sidebar-menuitem"
              icon={<PiHandshake className="sidebar-icon" />}
            >
              <Link to={"/email-campaign"}>
                <MenuItem>
                  {" "}
                  <AiOutlineMail className="sidebar-icon" />
                  Email Campaign
                </MenuItem>
              </Link>
              <Link to={"/loyalty-program"}>
                <MenuItem>
                  {" "}
                  <MdOutlineLoyalty className="sidebar-icon" />
                  Loyalty Program
                </MenuItem>
              </Link>
              <Link to={"/reviews"}>
                <MenuItem>
                  {" "}
                  <MdOutlineReviews className="sidebar-icon" />
                  Reviews
                </MenuItem>
              </Link>
              <Link to={"/send-coupons"}>
                <MenuItem>
                  {" "}
                  <RiCoupon3Line className="sidebar-icon" />
                  Send Coupons
                </MenuItem>
              </Link>
            </SubMenu> */}
                <SubMenu
                  label="Reports"
                  className="sidebar-menuitem"
                  icon={<TbReportSearch className="sidebar-icon" />}>
                  <MenuItem disabled>
                    <div>
                      <BsFileBarGraph className="sidebar-icon" />
                      Sales
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  <MenuItem disabled>
                    <div>
                      <GoStack className="sidebar-icon" />
                      Category Wise
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  <MenuItem disabled>
                    <div>
                      <TbReportSearch className="sidebar-icon" />
                      Item Wise
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  label="Website CMS"
                  className="sidebar-menuitem"
                  icon={<TbReportSearch className="sidebar-icon" />}>
                  <MenuItem disabled>
                    <div>
                      <BsFileBarGraph className="sidebar-icon" />
                      Content
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                  <MenuItem disabled>
                    <div>
                      <TfiLayoutSlider className="sidebar-icon" />
                      Banners
                    </div>
                    <MdLockOutline className="sidebar-icon" />
                  </MenuItem>
                </SubMenu>
                {/* <Link to={"/help"}>
              <MenuItem className="sidebar-menuitem">
                <MdOutlineLiveHelp className="sidebar-icon" /> Help
              </MenuItem>
            </Link>
            <Link to={"/faq"}>
              <MenuItem className="sidebar-menuitem">
                <FaQuora className="sidebar-icon" /> FAQ
              </MenuItem>
            </Link>
            <Link to={"/support"}>
              <MenuItem className="sidebar-menuitem">
                <BiSupport className="sidebar-icon" /> Support
              </MenuItem>
            </Link> */}
              </>
            }
          </Menu>
        </Sidebar>
        {/* <Sidebar collapsed={props.collapsed} className="pt-3">
          <Menu>
            <SubMenu
              label="Menu"
              className="sidebar-menuitem"
              icon={<BiDish className="sidebar-icon" />}
            >
              <Link to={"/categorymaster"}>
                <MenuItem>
                  <GoStack className="sidebar-icon" /> Categories
                </MenuItem>
              </Link>
              <Link to={"/itemmasteroffline"}>
                <MenuItem>
                  <MdOutlineFastfood className="sidebar-icon" /> Items
                </MenuItem>
              </Link>
            </SubMenu>
            <Link to={"/profile"}>
              <MenuItem className="sidebar-menuitem">
                <BiUser className="sidebar-icon" /> Profile
              </MenuItem>
            </Link>
          </Menu>

        </Sidebar> */}
        <p className="version-text mx-auto">V - 1.0</p>
      </div>
    </>
  );
};

export default SideBarOffline;
