import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import "./AddonAction.css";
import uploadImage from '../../assets/images/upload-pang.png'

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas w-100 d-flex justify-content-center"
  >
    <div className="img-wrap img-upload">
      <img for="photo-upload" src={src} style={{ borderRadius: "5%" }} />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

const AddonAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allCategoryId, setAllCategoryId] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFileName] = useState("");
  const [addonName, setAddonName] = useState("");
  const [addonGroup, setAddonGroup] = useState("");
  const [enabled, setEnabled] = useState("Yes");
  const [previewUrl, setPreviewUrl] = useState(
    ""
  );
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://api.grubdigest.com/api-grub/AddImage"
  );
  const [allAddonGroup, setAllAddonGroup] = useState([]);
  const [selectedAddonGroup, setSelectedAddonGroup] = useState("");
  const [isAddNew, setIsAddNew] = useState(false);

  function GetAddon() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetAddonData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          const AddonGroupData = [
            ...new Map(
              obj.data.map((item) => [item["FLD_Addon_Group"], item])
            ).values(),
          ];

          setAllAddonGroup(AddonGroupData);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  useEffect(() => {
    GetAddon();
    if (location.state != null) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_Addon_ID=${location.state}`,
        },
        "GetAddonDataById"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            GetApiCall.getRequest("GetCategoryId").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  setAllCategoryId(obj.data);
                  Notiflix.Loading.remove();
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
            setSelectedCategoryId(obj1.data[0].FLD_Cat_Id);
            setAddonName(obj1.data[0].FLD_Addon_Name);
            setSelectedAddonGroup(obj1.data[0].FLD_Addon_Group);
            setEnabled(obj1.data[0].FLD_Enabled_Flag);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    } else {
      Notiflix.Loading.dots();
      GetApiCall.getRequest("GetCategoryId").then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            setAllCategoryId(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        })
      );
    }
  }, []);

  function onSaveAddon() {
    Notiflix.Loading.dots();
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    var fileName = outputFileName.split(".").slice(0, -1).join(".") + ".webp";
    PostApiCall.postRequest(
      {
        addOnId: location.state,
        catId: selectedCategoryId,
        addOnName: addonName,
        addOnGroup: selectedAddonGroup,
        addonImage:
          "https://grubdigest.com/images/addon/" +
          randomnumber +
          "-" +
          fileName,
        enabledFlag: enabled,
        createdOn: new Date(),
        createdBy: 1,
        updatedOn: new Date(),
        updatedBy: 1,
      },
      "updatedaddon"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          const form = new FormData();
          form.append("file", originalImage);
          form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
            ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
            : "");
          form.append("foldername", "addon");
          form.append("filename", randomnumber + "-" + outputFileName);
          let response;
          response = fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((res) => {

          });

          Notiflix.Loading.remove();
          Notiflix.Notify.success("Addon added successfully");
          navigate(-1);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
  };

  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid">
          <div className="row form-container">
            <div className="col-12">
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Add New Item</h5>

              </div>
            </div>
            <div className="col-12 pe-4">
              <div className="row">
                <div className="col-lg-4">
                  <ImgUpload onChange={photoUpload} src={previewUrl != "" && previewUrl != undefined ? previewUrl : uploadImage} />
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCategoryId}
                          onChange={(e) => {
                            setSelectedCategoryId(e.target.value);
                          }}
                          label="Category"
                        >
                          {allCategoryId.map((category, i) => {
                            return (
                              <MenuItem value={category.FLD_Category_Id}>
                                {category.FLD_Name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-lg-6">
                      {/* <label className="me-3">Addon Name</label>
                    <input
                      className="w-100"
                      type="text"
                      value={addonName}
                      onChange={(e) => {
                        setAddonName(e.target.value);
                      }}
                    /> */}

                      <TextField
                        id="outlined-basic"
                        label="Addon Name"
                        variant="outlined"
                        className="w-100"
                        value={addonName}
                        onChange={(e) => {
                          setAddonName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mt-lg-4">
                      {/* <label className="me-3">Addon Group</label>
                      <input
                        className="w-100"
                        type="text"
                        value={addonGroup}
                        onChange={(e) => {
                          setAddonGroup(e.target.value);
                        }}
                      /> */}

                      <FormControl
                        fullWidth
                        style={{ display: isAddNew == true ? "none" : "block" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Addon Group
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="w-100"
                          value={selectedAddonGroup}
                          onChange={(e) => {
                            if (e.target.value == "Add New") {
                              setIsAddNew(true);
                              setSelectedAddonGroup("");
                            }
                            setSelectedAddonGroup(e.target.value);
                          }}
                          label="Addon Group"
                        >
                          {allAddonGroup.map((addon, i) => {
                            return (
                              <MenuItem value={addon.FLD_Addon_Group}>
                                {addon.FLD_Addon_Group}
                              </MenuItem>
                            );
                          })}
                          <MenuItem
                            value="Add New"
                            className="bg-secondary text-white"
                          >
                            Add New
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Addon Group"
                        variant="outlined"
                        className="w-100"
                        style={{ display: isAddNew == true ? "block" : "none" }}
                        value={selectedAddonGroup}
                        onChange={(e) => {
                          setSelectedAddonGroup(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mt-lg-4">
                      {/* <label>Display In Website</label> */}
                      {/* <div className="d-flex">
                        <span className="d-flex me-2">
                          <input
                            type="radio"
                            name="status"
                            className="ms-2 me-1"
                            checked={enabled == "Yes" ? true : false}
                            onChange={() => {
                              setEnabled("Yes");
                            }}
                          />
                          Yes
                        </span>
                        <span className="d-flex">
                          <input
                            type="radio"
                            name="status"
                            className="me-1"
                            checked={enabled == "No" ? true : false}
                            onChange={() => {
                              setEnabled("No");
                            }}
                          />
                          No
                        </span>
                      </div> */}
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="text-dark me-2"
                      >
                        Display In Website
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "Yes" ? true : false}
                              onChange={() => {
                                setEnabled("Yes");
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "No" ? true : false}
                              onChange={() => {
                                setEnabled("No");
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="default-btn btn-white border-0 btn ms-auto"
                  onClick={() => {
                    onSaveAddon();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddonAction;
