 var urlString = "https://api.grubdigest.com/api-grub/";
// var urlString = "https://test.globaltrendz.online/moi-api/";
//var urlString = "http://localhost:8033/api-grub/";
var schema = "grubdigest_master";
if (localStorage.getItem("LoginDetail") != null) {
  schema = JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema;
}
const GetApiCall = {
  getRequest(url) {
    // //
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("access"),
        schema: schema,
      },
    });
  },
};

export default GetApiCall;
