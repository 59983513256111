import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "../../pages/Dashboard/Dashboard.css";
import { store } from "../Context/store";
export default function LatestCustomerList() {
  const [last10DaysCustomers, setLast10DaysCustomers] = useState([]);
  const { currencyCode } = useContext(store);
  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      PostApiCall.postRequest(
        {
          filter: "last7days",
        },
        "getCustomerListFiltered"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.message.length != 0) {
            setLast10DaysCustomers(obj.message);

            Notiflix.Loading.remove();
          } else {
            setLast10DaysCustomers(obj.message);
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, []);

  return (
    <>
      <Card className="shadow border-light">
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Latest Customer List</h5>
                <p>New Customers Registered Today</p>
              </div>
            </div>

            <div className="order-list-view-main">
              {last10DaysCustomers?.length > 0 ? (
                last10DaysCustomers?.map((customerlist) => {
                  return (
                    <div className="order-list-view align-items-start">
                      <div className="order-list-view-name">
                        <h6 className="fw-bold">{customerlist.fld_name}</h6>
                      </div>

                      <div>
                        <h6 className="fw-bold">
                          {customerlist.fld_phone_number}
                        </h6>
                        <p>{customerlist.fld_email_address}</p>
                      </div>
                      <div>
                        <h6 className="fw-bold">
                          {currencyCode} {" "} {customerlist.total_order_amt}
                        </h6>
                        <p>{customerlist.total_Orders} Orders</p>
                      </div>
                      <div>
                        <h6 className="fw-bold">Last Order Placed on</h6>
                        <p>{customerlist.fld_last_order_placed}</p>
                      </div>
                      <div className="order-item-options align-self-center">
                        <Link to={"/customer-dashboard"}
                          state={{ id: customerlist.fld_customer_id }}>
                          <BsFillArrowRightCircleFill />
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "300px" }}
                >
                  <p>No record available.</p>
                </div>
              )}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
