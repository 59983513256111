import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./ItemMaster.css";

const ItemMasterOffline = () => {
  let navigate = useNavigate();
  const [allItemMasterOfflineData, setAllItemMasterOfflineData] = useState([]);

  function GetItemDetailsOffline() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_client_id = ${JSON.parse(localStorage.getItem("LoginDetail")).fld_client_id
          }`,
      },
      "getItemDetails"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data?.length != 0) {
          setAllItemMasterOfflineData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  useEffect(() => {
    GetItemDetailsOffline();
  }, []);

  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid">
          <div className="row form-container">
            <div className="d-flex justify-content-between align-items mb-4">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Item List</h5>
                <p>List of Items</p>
              </div>
              <button
                type="button"
                className="default-btn btn btn-white"
                onClick={() => {
                  navigate("/itemmasteroffline/add");
                }}
              >
                Add New Item
              </button>
            </div>

            {allItemMasterOfflineData?.map((item, i) => {
              return (
                <div className="col-md-3 px-3 mb-4 pb-2">
                  <Card className="shadow h-100 item-card">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <div className="card-content">
                        <img className="img-fluid" src={item.FLD_Image} />
                        <h4>{item.FLD_Name}</h4>
                        <p>{item.FLD_Description}</p>
                      </div>

                      <div className="mt-3 border-top pt-2">
                        <span className={item.FLD_Enabled_Flag}>
                          {item.FLD_Enabled_Flag}
                        </span>
                        <div className="border-top pt-3 d-flex mt-2 justify-content-between align-items-center">
                          <div className="ms-auto">
                            <Button
                              variant="primary"
                              className="btn kot-btn kot-btn-2 mx-2 p-2 "
                              onClick={() => {
                                navigate("/itemmasteroffline/update", {
                                  state: item.FLD_Item_ID,
                                });
                              }}
                            >
                              <AiOutlineEdit />
                            </Button>
                            <Button
                              variant="primary"
                              className="btn kot-btn kot-btn-2 p-2 "
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to Delete",
                                  message:
                                    "Are you sure you want to delete this item?",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        PostApiCall.postRequest(
                                          {
                                            id: item.FLD_Item_ID,
                                            deleteFrom: "ItemMaster",
                                          },
                                          "DeleteMaster"
                                        ).then((results) =>
                                          results.json().then((obj) => {
                                            if (
                                              results.status == 200 ||
                                              results.status == 201
                                            ) {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.success(
                                                "successfully deleted."
                                              );
                                              GetItemDetailsOffline();
                                            } else {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.failure(
                                                "Something went wrong, try again later."
                                              );
                                            }
                                          })
                                        );
                                      },
                                    },
                                    {
                                      label: "No",
                                    },
                                  ],
                                });
                              }}
                            >
                              <RiDeleteBin6Line />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ItemMasterOffline;
