import React from 'react';
import "./AddOns.css";
import { Col, Container, Row } from 'react-bootstrap';

function AddOns() {
return (
    <section className="pb-lg-5 pt-4 margin-left">
    <Container fluid>
        <Row>
        <Col>
        <div className="dash-heading">
            <h5 className="fw-bold text-black">Add On Services</h5>
            <p>??</p>
            </div>
        </Col>
        </Row>
    </Container>
    </section>
);
}

export default AddOns;