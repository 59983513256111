import {
  FormControlLabel, FormLabel, Radio,
  RadioGroup, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UploadIcon from "../../assets/images/upload.png";
import PostApiCall from "../../helper/Api";
import "./BannerManagementAction.css";
import uploadImage from '../../assets/images/upload-pang.png'
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas w-100 d-flex justify-content-center"
  >
    <div className="img-wrap img-upload">
      <img for="photo-upload" src={src} style={{ borderRadius: "5%" }} />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

const BannerManagementAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [previewUrl, setPreviewUrl] = useState(
    ""
  );
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://api.grubdigest.com/api-grub/AddImage"
  );
  const [ImageUploadIcon, setImageUploadIcon] = useState(UploadIcon);
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFileName] = useState("");
  const [bannerLocation, setBannerLocation] = useState("");
  const [bannerStatus, setBannerStatus] = useState("No");
  const [imageAlt, setImageAlt] = useState("");

  useEffect(() => {
    if (location.state != null) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          WhereClause: `where fld_banner_id=${location.state}`,
        },
        "GetBannerById"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            Notiflix.Loading.remove();
            setBannerLocation(obj1.data[0].fld_banner_location);
            setPreviewUrl(obj1.data[0].fld_banner_image);
            setImageAlt(obj1.data[0].fld_alt);
            setBannerStatus(obj1.data[0].fld_banner_status);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }
  }, []);

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
  };

  function onSaveBanner() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    var fileName = outputFileName.split(".").slice(0, -1).join(".") + ".webp";
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: location.state,
        bannerlocation: bannerLocation,
        bannerImage: `https://grubdigest.com/images/${JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
          ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
          : ""}/banner/${randomnumber}-${fileName}`,
        imageAlt: imageAlt,
        status: bannerStatus,
      },
      "AddBanner"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          const form = new FormData();
          form.append("file", originalImage);
          form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
            ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
            : "");
          form.append("foldername", "banner");
          form.append("filename", randomnumber + "-" + outputFileName);
          let response;
          response = fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((res) => {

          });
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Banner added successfully");
          navigate(-1);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid">
          <div className="form-container row ">
            <div className="col-12">
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Add New Banner</h5>

              </div>
            </div>
          </div>

          {/* <div className="col-lg-7"> */}
          <div className="row px-lg-5">
            <div className="col-12 mb-4">
              {/* <label className="me-3">Banner Location</label>
              <input
                className="w-100"
                type="text"
                value={bannerLocation}
                onChange={(e) => {
                  setBannerLocation(e.target.value);
                }}
              /> */}

              <TextField
                id="outlined-basic"
                label="Banner Location"
                variant="outlined"
                className="w-100"
                value={bannerLocation}
                onChange={(e) => {
                  setBannerLocation(e.target.value);
                }}
              />
            </div>
            <div className="col-12 mb-4">
              <ImgUpload onChange={photoUpload} src={previewUrl != "" && previewUrl != undefined ? previewUrl : uploadImage} />
            </div>
            <div className="col-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Image Alt Text"
                variant="outlined"
                className="w-100"
                value={imageAlt}
                onChange={(e) => {
                  setImageAlt(e.target.value);
                }}
              />
            </div>
            <div className="col-12 mb-4">
              {/* <label>Display In Website</label>
              <div className="d-flex">
                <span className="d-flex me-2">
                  <input
                    type="radio"
                    name="status"
                    className="ms-2 me-1"
                    checked={bannerStatus == "Yes" ? true : false}
                    onChange={() => {
                      setBannerStatus("Yes");
                    }}
                  />
                  Yes
                </span>
                <span className="d-flex">
                  <input
                    type="radio"
                    name="status"
                    className="me-1"
                    checked={bannerStatus == "No" ? true : false}
                    onChange={() => {
                      setBannerStatus("No");
                    }}
                  />
                  No
                </span>
              </div> */}

              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="text-dark me-2"
              >
                Display In Website
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="status"
                      checked={bannerStatus == "Yes" ? true : false}
                      onChange={() => {
                        setBannerStatus("Yes");
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      name="status"
                      checked={bannerStatus == "No" ? true : false}
                      onChange={() => {
                        setBannerStatus("No");
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="default-btn btn-white border-0 btn ms-auto"
                onClick={() => {
                  onSaveBanner();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </section>
    </>
  );
};

export default BannerManagementAction;
