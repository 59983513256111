import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import nonveg from "../../assets/images/NonVeg.png";
import veg from "../../assets/images/Veg.png";
import PostApiCall from "../../helper/Api";
import { useNavigate } from "react-router-dom";




const SubCategoryMaster = () => {
    let navigate = useNavigate();
    const [subCatId, setSubCatId] = useState(null);
    const [categoryName, setCategoryName] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    useEffect(() => {
        getSubCategoryList()
    }, []);

    const getSubCategoryList = () => {
        PostApiCall.postRequest(
            {
                WhereClause: "",
                columns: "*"
            },
            "getSubCategory"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setSubCategory(obj.message);
                    setCategoryName(obj.message);
                }
            })
        );
    }
    return (
        <>
            <div className="container my-5 mt-5">
                <div className="row form-container">
                    <div className="mb-3 d-flex justify-content-between border-bottom pb-3 mb-5">
                        <h3 class="main-text fw-bold">Sub Category List</h3>
                        <button
                            type="button"
                            className="default-btn btn btn-white"
                            onClick={() => {
                                navigate("/subcategorymaster/add");
                            }}
                        >
                            Add New Sub Category
                        </button>
                    </div>
                    {subCategory.map((data, i) => {
                        return (
                            <div className="col-md-3 px-3 mb-5">
                                <div className="default-card shadow border-light">
                                    <div className="card-content">
                                        <h4>{data.FLD_Name}</h4>
                                        <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                                            <div className="ms-auto">
                                                <button
                                                    type="button"
                                                    className="default-btn ms-auto me-4"
                                                    onClick={() => {
                                                        navigate('/subcategorymaster/edit', { state: data.FLD_Sub_Cat_Id })
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="default-btn btn btn-white"
                                                    onClick={() => {
                                                        confirmAlert({
                                                            title: "Confirm to Delete",
                                                            message:
                                                                "Are you sure you want to delete this key strings?",
                                                            buttons: [
                                                                {
                                                                    label: "Yes",
                                                                    onClick: () => {
                                                                        PostApiCall.postRequest(
                                                                            {
                                                                                whereClause: `where FLD_Sub_Cat_Id=${data.FLD_Sub_Cat_Id}`,
                                                                            },
                                                                            "deleteSubCategoryMaster"
                                                                        ).then((results) =>
                                                                            results.json().then((obj) => {
                                                                                if (
                                                                                    results.status == 200 ||
                                                                                    results.status == 201
                                                                                ) {
                                                                                    Notiflix.Loading.remove();
                                                                                    Notiflix.Notify.success(
                                                                                        "successfully deleted."
                                                                                    );
                                                                                    getSubCategoryList();
                                                                                } else {
                                                                                    Notiflix.Loading.remove();
                                                                                    Notiflix.Notify.failure(
                                                                                        "Something went wrong, try again later."
                                                                                    );
                                                                                }
                                                                            })
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    label: "No",
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default SubCategoryMaster;
