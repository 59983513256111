import Notiflix from "notiflix";
import { useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import PostApiCall from "../../helper/Api";
import "../../pages/Dashboard/Dashboard.css";
import { store } from "../Context/store";
export default function Revenue() {
  const { totalSales, setTotalSales, currencyCode } = useContext(store);
  const { chartData, setChartData } = useContext(store);
  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      PostApiCall.postRequest(
        {
          WhereClause: "",
        },
        "Get_DashboardYearlySales"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            var month = [];
            var value = [];
            var sales = 0.0;
            obj.data?.map((data1, i) => {
              value.push(data1.total_sales);
              month.push(data1.month);
              sales = sales + data1.total_sales;
            });


            setTotalSales(sales);
            setChartData({
              options: {
                chart: {
                  height: 280,
                  // width: '100%',
                  type: "area",
                  stacked: !0,
                  toolbar: {
                    show: 0,
                  },
                  zoom: {
                    enabled: !0,
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: !0,
                    columnWidth: "10%",
                    endingShape: "rounded",
                  },
                },
                dataLabels: {
                  enabled: 0,
                },
                xaxis: {
                  categories: month,
                },
                legend: {
                  position: "bottom",
                  show: 0,
                },
                colors: ["#eb1c24", "#faebd7"],
                fill: {
                  colors: ["#eb1c24", "#faebd7"],
                },
                stroke: {
                  curve: "smooth",
                },
                // responsive: {
                //     breakpoint: undefined,
                //     options: {},
                // }
              },
              series: [
                {
                  name: "Total Sales",
                  data: value,
                },
              ],
            });

            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, []);
  return (
    <>
      <Card className="shadow border-light h-100">
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="dash-heading">
                  <h5 className="fw-bold text-black">Revenue</h5>
                  <p>Revenue Collection Summary</p>
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-center">
                  <div class="text-center px-2 px-md-5">
                    <p class="fs-14 mb-1">Income</p>
                    <h4 class="text-black fw-bold mb-0 fs-22">
                      {currencyCode} {" "}
                      {totalSales}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Card.Text>
          {chartData != null ? (
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="area"
              height={320}
            />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <p>No record available.</p>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
