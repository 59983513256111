import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./BannerManagement.css";
import Notiflix from "notiflix";
import GetApiCall from "../../helper/GETAPI";
import PostApiCall from "../../helper/Api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import DragDropList from "./DragDropList/DragDropList";
import Sequentialize from "../../assets/images/Sequentialize.png";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

const BannerManagement = () => {
  let navigate = useNavigate();
  const [allBannerData, setAllBannerData] = useState([]);
  const [allActiveBannerData, setAllActiveBannerData] = useState([]);
  const [show, setShow] = useState(false);

  function GetBanners() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetBannerData").then((resultdes) => {
      if (resultdes.status == 200 || resultdes.status == 201) {
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            var allactivebanner = [];
            obj.data.map((banner, i) => {
              if (banner.fld_banner_status == "Active    ") {
                allactivebanner.push(banner);
              }
            });
            setAllActiveBannerData(allactivebanner);
            setAllBannerData(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        });
      }
    });
  }

  useEffect(() => {
    GetBanners();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid className="inner-page-height">
          <Row>
            <Col lg={12}>
              {/* <div className="row"> */}
              <div className="dash-heading d-flex justify-content-between mb-4">
                <div>
                  <h5 className="fw-bold text-black">Banner List</h5>
                  {/* <p>??</p> */}
                </div>
                <div>
                  <button
                    type="button"
                    className="default-btn ms-auto sequence-btn me-3"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    <img
                      alt="sequential-img"
                      src={Sequentialize}
                      className="sequentialize-btn"
                    />
                  </button>
                  <button
                    type="button"
                    className="default-btn btn btn-white"
                    onClick={() => {
                      navigate("/bannermanagement/add");
                    }}
                  >
                    Add New Banner
                  </button>
                </div>
              </div>
            </Col>
            {allBannerData.map((banner, i) => {
              return (
                <div className="col-md-6 col-lg-4 px-3 mb-5">
                  <div className="card default-card shadow border-light banner-m-card">
                    <div className="d-flex align-item-center justify-content-center">
                      <img
                        className="img-fluid"
                        src={banner.fld_banner_image}
                        alt={banner.fld_alt}
                      />
                    </div>
                    <div className="card-content">
                      <h4>{banner.fld_banner_location}</h4>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <span className={banner.fld_banner_status}>
                          {banner.fld_banner_status}
                        </span>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 mx-2 p-2"
                            onClick={() => {
                              navigate("/bannermanagement/update", {
                                state: banner.fld_banner_id,
                              });
                            }}
                          >
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this banner ?",
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      PostApiCall.postRequest(
                                        {
                                          id: banner.fld_banner_id,
                                          deleteFrom: "BannerMaster",
                                        },
                                        "DeleteMaster"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "successfully deleted."
                                            );
                                            GetBanners();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Row>
        </Container>
      </section>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="sequence-offcanvas"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropList
            verticalData={allActiveBannerData}
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BannerManagement;
