import {
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./ItemPropertyMappingMaster.css";

const ItemPropertyMaster = () => {
  let navigate = useNavigate();
  const [allItemPropertyData, setAllItemPropertyData] = useState([]);
  const [sliderHeader, setSliderHeader] = useState("");
  const [propertyId, setPropertyId] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // save property master
  const [propertyName, setPropertyName] = useState("");
  const [enabled, setEnabled] = useState("Yes");

  // end save property master
  function GetItemProperty() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "getItemPropertyMaster"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.message.length != 0) {
          setAllItemPropertyData(obj.message);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  function onSaveProperty() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: propertyId,
        propertyname: propertyName,
        enabledFlag: enabled,
        createdOn: new Date(),
        createdBy: 1,
        updatedOn: new Date(),
        updatedBy: 1,
      },
      "updateProperty"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Item Property added successfully");
          GetItemProperty()
          handleClose()
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  const handleShow = (headerTitle) => {
    setShow(true);
    setSliderHeader(headerTitle)
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }

  useEffect(() => {
    GetItemProperty();
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">{sliderHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="pb-lg-5 pt-4">
            <div className="container-fluid">
              <div className="row form-container">
                <div className="col-md-12 pe-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <TextField
                        id="outlined-basic"
                        label="Property Name"
                        variant="outlined"
                        className="w-100"
                        value={propertyName}
                        onChange={(e) => {
                          setPropertyName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="text-dark me-2"
                      >
                        Display In Website
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "Yes" ? true : false}
                              onChange={() => {
                                setEnabled("Yes");
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "No" ? true : false}
                              onChange={() => {
                                setEnabled("No");
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="default-btn btn-white border-0 btn ms-auto"
                      onClick={() => {
                        onSaveProperty();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid inner-page-height">
          <div className="row form-container">
            <div className="mb-3 d-flex justify-content-between pb-3 mb-3">
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Property List</h5>

              </div>
              <button
                type="button"
                className="default-btn btn btn-white"
                onClick={() => {
                  setPropertyId(null)
                  setPropertyName(null);
                  setEnabled(null);
                  handleShow("Add New Property")
                }}
              >
                Add New Property
              </button>
            </div>

            {allItemPropertyData.map((itemProperty, i) => {
              return (
                <div className="col-md-6 col-lg-3 px-3 mb-5">
                  <div className="default-card property-card shadow border-light">
                    <div className="card-content">
                      <h4>{itemProperty.FLD_Property_Name}</h4>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <span className={itemProperty.FLD_Enabled_Flag}>
                          {itemProperty.FLD_Enabled_Flag}
                        </span>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              setPropertyId(itemProperty.FLD_ID)
                              setPropertyName(itemProperty.FLD_Property_Name);
                              setEnabled(itemProperty.FLD_Enabled_Flag);
                              handleShow(`Edit ${itemProperty.FLD_Property_Name} Property`);

                            }}
                          >
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this property?",
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      PostApiCall.postRequest(
                                        {
                                          id: itemProperty.FLD_ID,
                                          deleteFrom: "PropertyMaster",
                                        },
                                        "DeleteMaster"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "successfully deleted."
                                            );
                                            GetItemProperty();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ItemPropertyMaster;
