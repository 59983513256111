import {
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import "./FoodTypeMaster.css";

const FoodTypeMaster = () => {
  let navigate = useNavigate();
  const [allFoodType, setAllFoodType] = useState([]);
  const [sliderHeader, setSliderHeader] = useState("");
  const [foodTypeId, setFoodtypeId] = useState(null);
  const [foodtype, setFoodType] = useState("");
  const [enabled, setEnabled] = useState("Yes");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function GetFoodType() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("getFoodTypeMaster").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllFoodType(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  const handleShow = (headerTitle) => {
    setShow(true);
    setSliderHeader(headerTitle)
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }

  function onSaveAddon() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: foodTypeId,
        foodtype: foodtype,
        enabledFlag: enabled,
        createdOn: new Date(),
        createdBy: 1,
        updatedOn: new Date(),
        updatedBy: 1,
      },
      "UpdateFoodType"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Food type added successfully");
          GetFoodType()
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  useEffect(() => {
    GetFoodType();
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">{sliderHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="pb-lg-5 pt-4">
            <div className="container-fluid">
              <div className="row form-container">
                <div className="col-md-12 pe-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <TextField
                        id="outlined-basic"
                        label="Food Type"
                        variant="outlined"
                        className="w-100"
                        value={foodtype}
                        onChange={(e) => {
                          setFoodType(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="text-dark me-2"
                      >
                        Display In Website
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "Yes" ? true : false}
                              onChange={() => {
                                setEnabled("Yes");
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "No" ? true : false}
                              onChange={() => {
                                setEnabled("No");
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="default-btn btn-white border-0 btn ms-auto"
                      onClick={() => {
                        onSaveAddon();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid inner-page-height">
          <div className="row form-container">
            <div className="mb-3 d-flex justify-content-between pb-3 mb-3">
              {/* <h3 class="main-text fw-bold">Food Type List</h3> */}
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Food Type List</h5>

              </div>
              <button
                type="button"
                className="default-btn btn btn-white"
                onClick={() => {
                  handleShow("Add New Food Type");
                  setFoodtypeId(null);
                  setFoodType(null);
                  setEnabled(null)
                }}
              >
                Add New Food Type
              </button>
            </div>

            {allFoodType.map((foodtype, i) => {
              return (
                <div className="col-md-6 col-lg-3 px-3 mb-5">
                  <div className="default-card food-type-card shadow border-light">
                    <div className="card-content">
                      <h4>{foodtype.fld_food_type}</h4>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <span className={foodtype.fld_status}>
                          {foodtype.fld_status}
                        </span>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              handleShow("Edit Food Type");
                              setFoodtypeId(foodtype.fld_id);
                              setFoodType(foodtype.fld_food_type);
                              setEnabled(foodtype.fld_status)
                            }}
                          >
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this food type?",
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      PostApiCall.postRequest(
                                        {
                                          id: foodtype.fld_id,
                                          deleteFrom: "FoodTypeMaster",
                                        },
                                        "DeleteMaster"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "successfully deleted."
                                            );
                                            GetFoodType();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default FoodTypeMaster;
