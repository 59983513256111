import Notiflix from "notiflix";
import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import GetApiCall from "../../helper/GETAPI";
import "../../pages/Dashboard/Dashboard.css";
import { store } from "../Context/store";
export default function TrendingItemsChart() {
  const { allTrendingOrders, setAllTrendingOrders, currencyCode } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      GetApiCall.getRequest("GetTrendingItems").then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.data?.length != 0) {
            setAllTrendingOrders(obj.data);

            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    }
    Notiflix.Loading.remove();
  }, []);
  return (
    <>
      <Card className="shadow border-light">
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center mb-lg-3 ">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Trending Items</h5>
                <p>Frequently Ordered Items</p>
              </div>
            </div>
            <div className="order-list-view-main ">
              {allTrendingOrders?.map((item, i) => {
                return (
                  <div className="trending-order-list-view ">
                    <div className="trending-item-view">
                      <img
                        class="mr-3 img-fluid rounded"
                        src={item.fld_item_image}
                        alt="DexignZone"
                      ></img>
                      <div class="number">{i + 1}</div>
                    </div>
                    <div className="w-100">
                      <h6 className="fw-bold">{item.fld_item_name}</h6>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="fw-bold">
                          {currencyCode} {" "}
                          {item.fld_item_price}
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
