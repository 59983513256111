import React, { useState } from "react";
import "./LoginPage.css";
import logo from "../../assets/images/grubDigest_logo.png";
import { FaEye, FaEyeSlash, FaFacebookF, FaInstagram } from "react-icons/fa";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "../../helper/Api";
import { useNavigate } from "react-router-dom";
import LoginPageMobile from "./LoginPageMobile";

function LoginPage() {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [EmailRegex] = useState(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

  function onLogin() {
    if (
      username != "" &&
      password != "" &&
      username != null &&
      password != null
    ) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          email: username,
          password: password,
          action: "login",
          actiondate: new Date(),
        },
        "AuthenticateUser"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            localStorage.setItem("LoginDetail", JSON.stringify(obj.message[0]));
            localStorage.setItem("access", obj.token);
            if (
              obj.message[0].fld_companytype == 1 ||
              obj.message[0].fld_companytype == 3
            ) {
              window.location.href = "/dashboard"
              // navigate("/dashboard");
            } else {
              if (obj.message[0]?.fld_email == "Sales@globaltrendz.com") {
                window.location.href = "/SalesDashboard"
              } else {
                window.location.href = "/profile"
                // navigate("/profile");
              }
            }
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Authentication Failed!");
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please Enter Username and Password!");
    }
  }
  return (
    <>
      <div className="d-lg-none">
        <LoginPageMobile />
      </div>
      <div className="d-none d-lg-block">
        <div className="login-main-page">
          <div className="login-wrapper">
            <div className="login-aside-left">
              <a className="login-logo" href="/">
                <div className="login-logo-inner">
                  <img src={logo} className="img-fluid" alt="" />
                </div>
              </a>
              <div className="login-description">
                <h4 className="extra-text mb-lg-0 mb-2 pt-1">
                  Good <span className="no-text-shadow">Mood</span>, Find{" "}
                  <span className="no-text-shadow">Food</span>
                </h4>
                <p className="fs-12 text-black login-content">
                  We are a one stop solution providing partner for your food business
                </p>
                <ul className="social-icons mt-4 ps-0">
                  <li>
                    <a href="https://www.facebook.com/grubdigest.official/" target="_blank">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/grubdigest.official/" target="_blank">
                      <FaInstagram />
                    </a>
                  </li>
                  {/* <li>
                    <a href="/">
                      <FaLinkedinIn />
                    </a>
                  </li> */}
                </ul>
                <div className="mt-3 mt-md-5">
                  {/* <a className="text-black me-4 fw-bold" href="/">
                    Privacy Policy
                  </a> */}
                  <a className="text-black me-4 fw-bold" href="tel:+7827795895">
                    Contact
                  </a>
                  <a className="text-black fw-bold" href="https://grubdigest.com/" target="_blank">
                    &copy; {moment().format("YYYY")} GrubDigest
                  </a>
                </div>
              </div>
            </div>
            <div className="login-aside-right">
              <div className="row m-0 justify-content-center h-100 align-items-center">
                <div className="col-xl-9 col-xxl-9">
                  <div className="authincation-content">
                    <div className="row no-gutters">
                      <div className="col-xl-12">
                        <div className="auth-form-1">
                          <div className="mb-4">
                            <h3 className="text-white mb-1 fw-bold">
                              Welcome
                            </h3>
                            <p className="text-white">
                              Sign in by entering information below
                            </p>
                          </div>
                          <div className="login-form">
                            <Form.Floating className="mb-3">
                              <Form.Control
                                id="floatingInputCustomuser"
                                type="email"
                                placeholder="name@example.com"
                                value={username}
                                onChange={(e) => {
                                  setUsername(e.target.value);
                                }}
                              />
                              <label htmlFor="floatingInputCustomuser">
                                Username
                              </label>
                            </Form.Floating>
                            <Form.Floating className="mb-3">
                              <Form.Control
                                id="floatingPasswordCustom"
                                className="login-password-input"
                                type={passwordType}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                }}
                              />
                              <span className="login-pass-icon">
                                {passwordType == "password" ?
                                  <FaEyeSlash onClick={() => { setPasswordType("text") }} />
                                  :
                                  <FaEye onClick={() => { setPasswordType("password") }} />
                                }
                              </span>
                              <label htmlFor="floatingPasswordCustom">
                                Password
                              </label>
                            </Form.Floating>
                            <Button
                              variant="light"
                              className="w-100 fw-bold py-2"
                              onClick={() => {
                                onLogin();
                              }}
                            >
                              Login
                            </Button>
                          </div>
                        </div>
                        <div className="reset-password-form d-none">
                          <div className="mb-4">
                            <h3 className="text-white mb-1 fw-bold">
                              Forgot Password?
                            </h3>
                            <p className="text-white">
                              Enter the email address associated with your
                              account.
                            </p>
                          </div>
                          <div className="login-form">
                            <Form.Floating className="mb-3">
                              <Form.Control
                                id="floatingInputCustommail"
                                type="email"
                                placeholder="name@example.com"
                              />
                              <label htmlFor="floatingInputCustommail">
                                Enter Email Address
                              </label>
                            </Form.Floating>
                            <Button
                              variant="light"
                              className="w-100 fw-bold py-2"
                            >
                              Reset Password
                            </Button>
                          </div>
                          <div className="new-account mt-2">
                            <p className="text-white">
                              Already have an account?{" "}
                              <button
                                className="text-white"
                              // onClick={() => {
                              //   onLogin();
                              // }}
                              >
                                Login Now
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
