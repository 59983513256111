import { useState, useContext } from "react";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BiRupee } from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import { LuVegan } from "react-icons/lu";
import NonVegetarian from "../../assets/images/nonveg_icon.png";
import Vegan from "../../assets/images/vegan_icon.png";
import Vegetarian from "../../assets/images/veg_icon.png";
import "./OrderHistoryDrawer.css";
import GetApiCall from "../../helper/GETAPI";
import { store } from "../../components/Context/store";
import Notiflix from "notiflix";
import PostApiCall from "../../helper/Api";
import OrderForm from "../../pages/OrderForm/OrderForm";
export default function OrderHistoryDrawer({ offcanvasshow, offcanvasclose }) {
  const {
    orderNumber,
    setOrderNumber,
    orderDateTime,
    setOrderDateTime,
    orderType,
    setOrderType,
    customerName,
    setCustomerName,
    customerMobile,
    setCustomerMobile,
    paymentDetails,
    setPaymentDetails,
    address,
    setAddress,
    dish,
    setDish,
    quantity,
    setQuantity,
    prices,
    setPrices,
    additionalInstructions,
    setAdditionalInstructions,
    itemTotal,
    setItemTotal,
    couponName,
    setCouponName,
    couponAmount,
    setCouponAmount,
    offerDiscount,
    setOfferDiscount,
    taxes,
    setTaxes,
    deliveryCharges,
    setDeliveryCharges,
    packingCharges,
    setPackingCharges,
    tips,
    setTips,
    grandTotal,
    setGrandTotal,
    totalOrderSavings,
    setTotalOrderSavings,
    orderDetails,
    setOrderDetails,
    landmark,
    kotFilterVal,
    cartId,
    setCartId,
    currencyCode
  } = useContext(store);
  const [show, setShow] = useState(false);

  const handleClose = () => offcanvasclose(false);

  function onChangeOrderStatus(status) {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: cartId,
        status: status,
      },
      "updateOrderStatus"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Notify.success("Order Status Updated Successfully");
          Notiflix.Loading.remove();
          window.location.reload();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <Offcanvas
        show={offcanvasshow}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">Order Details</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="kot-offcanvas-box">
            <h6>Order Number</h6>
            <p>{orderNumber}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Order Date & Time</h6>
            <p>{orderDateTime}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Order Type</h6>
            <p>{orderType}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Customer Details</h6>
            <p>{customerName}</p>
            <p>Mobile # {customerMobile}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6>Payment Details</h6>
            <p>{paymentDetails}</p>
          </div>
          {orderType != 'TakeAway' ?
            <div className="kot-offcanvas-box">
              <h6>Deliver to</h6>
              <p>{address}</p>
              <p className="text-black fw-bold mt-1 mb-0">Landmark</p>
              <p className="border-bottom pb-2">{landmark}</p>
            </div>
            : ""}

          <div className="kot-offcanvas-box mb-5 pb-2">
            <h6 className="border-bottom py-2 border-top ">Order Summary</h6>
            <div className="kot-card-mid-main kot-offcanvas-card-main mx-1">
              {orderDetails?.map((order) => {
                return (
                  <div className="kot-card-mid mt-3 pb-2 ">
                    <p
                      className={`text-dark fw-bold ${order.FLD_Food_Type == "NonVegetarian"
                        ? "non-veg-icon"
                        : "veg-icon"
                        } mb-1`}
                    >
                      {" "}
                      <img
                        src=
                        {order.FLD_Food_Type ==
                          "Vegetarian"
                          ? Vegetarian :
                          order.FLD_Food_Type == "NonVegetarian"
                            ? NonVegetarian
                            : Vegan}
                        className="recipe-type"
                      />{" "}
                      {order.fld_variantname}
                    </p>
                    <div className="d-flex justify-content-between mt-2">
                      <p className="text-dark">
                        {order.fld_quantity} X {currencyCode} {" "}
                        {order.fld_website_discount_price}
                      </p>
                      <p className="text-dark">
                        {currencyCode} {" "}
                        {order.netvalue}
                      </p>
                    </div>

                    {order.spice_level &&
                      <div className="mt-2">
                        <p>
                          {order.spice_level}
                        </p>
                      </div>
                    }
                    {order.ConfigDetails != "#" ? (
                      <>
                        <a
                          href="#"
                          className="see-more small fw-bold">
                          Customize
                        </a>
                        {order.ConfigDetails.split(
                          "#"
                        ).map((config) => {
                          return (
                            <div className="d-flex justify-content-between align-items-center gap-2">
                              <div className="d-flex gap-2">
                                <h6 className="mb-0">
                                  <img
                                    src={
                                      config != null
                                        ? config.split(
                                          ","
                                        )[3] ==
                                          "Vegetarian"
                                          ? Vegetarian
                                          : config.split(
                                            ","
                                          )[3] ==
                                            "NonVegetarian"
                                            ? NonVegetarian
                                            : Vegan
                                        : ""
                                    }
                                    className="recipe-type"
                                  />{" "}
                                  {config != null
                                    ? config.split(
                                      ","
                                    )[1]
                                    : ""}{" "}
                                  X{" "}
                                  {config != null
                                    ? config.split(
                                      ","
                                    )[5]
                                    : ""}
                                </h6>
                              </div>
                              <div className="text-end">
                                <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                  {currencyCode}{" "}
                                  {config != null
                                    ? Number(
                                      config.split(
                                        ","
                                      )[2]
                                    ).toFixed(2)
                                    : ""}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                );
              })}
              {orderDetails[0]?.fld_special_instruction != "" && orderDetails[0]?.fld_special_instruction != null ?
                <>
                  <h6 className="border-bottom py-2 border-top">
                    Additional Instructions
                  </h6>
                  <div>
                    <p className="text-dark py-2">
                      {orderDetails.length != 0
                        ? orderDetails[0].fld_special_instruction
                        : ""}
                    </p>
                  </div>
                </>
                : ""}

              <h6 className="border-bottom py-2 border-top">Billing Summary</h6>

              <div className="kot-card-mid d-flex justify-content-between mt-2">
                <p className="text-dark fw-bold"> Item Total</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_total_net_amount.toFixed(2)
                    : 0.0}
                </p>
              </div>
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Coupon - {couponName}</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_coupon_discount.toFixed(2)
                    : 0.0}
                </p>
              </div> */}
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Offer Discount</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  800
                </p>
              </div> */}
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Taxes </p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_total_gst.toFixed(2)
                    : 0.0}
                </p>
              </div>
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Delivery Charges
                  {/* (FREE) */}
                </p>
                {/* <del className="text-dark"> */}
                {currencyCode} {" "}
                {orderDetails.length != 0
                  ? orderDetails[0].fld_shipping_charge.toFixed(2)
                  : 0.0}
                {/* </del> */}
              </div>
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Packing Charges </p>
                <p className="text-dark">
                  {currencyCode} {" "}
                </p>
              </div> */}
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Tips</p>
                <p className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_tip.toFixed(2)
                    : 0.0}
                </p>
              </div>
              <div className="kot-card-mid d-flex justify-content-between border-top border-bottom mt-3 py-3">
                <h5 className="text-dark fw-bold">Grand Total</h5>
                <h5 className="text-dark">
                  {currencyCode} {" "}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_cart_amount_no_shipping.toFixed(2)
                    : 0.0}
                </h5>
              </div>
              <div className="kot-save ">
                <h6 className="text-white fw-bold">
                  Total Order Savings {currencyCode} {" "}
                  {orderDetails.length != 0 &&
                    orderDetails[0].you_save_amount != null
                    ? orderDetails[0].you_save_amount.toFixed(2)
                    : 0.0}
                </h6>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="mt-3 d-flex justify-content-between kot-order-btn-main">
          {kotFilterVal == "NEW" ?
            <>
              <Button
                variant="white"
                className="kot-btn"
                onClick={() => {
                  onChangeOrderStatus("Rejected");
                }}
              >
                Reject Order
              </Button>
              <Button
                variant="white"
                className="kot-btn"
                onClick={() => {
                  onChangeOrderStatus("Accepted");
                }}
              >
                Accept Order
              </Button>
            </>
            : ""}
          <Button
            variant="white"
            className="kot-btn"
            onClick={() => {
              // window.print();
            }}
          >
            Print Order
          </Button>

          {/* <OrderForm orderid={241} /> */}
        </div>
      </Offcanvas>
    </>
  );
}
