import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import React from "react";

function Image({ image }) {
  const ImageApiUrl = "https://api.grubdigest.com/api-grub/AddImage";

  function onUploadImage(imagename) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(image.file, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Images");
      form.append("filename", image.name);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Uploaded Successfully");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  return (
    <>
      <div className="d-flex flex-column gap-3 me-3 drop-image mt-5">
        <img alt="" src={image.src} className="w-100 h-100" />
        <input
          type="text"
          className="image-alt"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              Notiflix.Loading.dots();
              onUploadImage(e.target.value);
            }
          }}
        />
      </div>
    </>
  );
}

export default Image;
