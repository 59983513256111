import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./ServingAction.css";

const ServingAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [serve, setServe] = useState("");
  const [enabled, setEnabled] = useState("Yes");

  useEffect(() => {
    if (location.state != null) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          WhereClause: `where fld_id=${location.state}`,
        },
        "GetServingById"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            setServe(obj1.data[0].fld_serve);
            setEnabled(obj1.data[0].fld_status);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }
  }, []);

  function onSaveServing() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: location.state,
        serve: serve,
        enabledFlag: enabled,
        createdOn: new Date(),
        createdBy: 1,
        updatedOn: new Date(),
        updatedBy: 1,
      },
      "UpdateServing"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Serving added successfully");
          navigate(-1);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid">
          <div className="row form-container">
            <div className="col-12">
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Add New Serving</h5>

              </div>
            </div>
            <div className="col-12 pe-4">
              <div className="row">
                <div className="col-md-6">
                  {/* <label className="me-3">Serve</label>
                <input
                  className="w-100"
                  type="text"
                  value={serve}
                  onChange={(e) => {
                    setServe(e.target.value);
                  }}
                /> */}

                  <TextField
                    id="outlined-basic"
                    label="Serve"
                    variant="outlined"
                    className="w-100"
                    value={serve}
                    onChange={(e) => {
                      setServe(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6 mt-4 mt-lg-0">
                  {/* <label>Display In Website</label>
                <div className="d-flex">
                  <span className="d-flex me-2">
                    <input
                      type="radio"
                      name="status"
                      className="ms-2 me-1"
                      checked={enabled == "Active" ? true : false}
                      onChange={() => {
                        setEnabled("Active");
                      }}
                    />
                    Yes
                  </span>
                  <span className="d-flex">
                    <input
                      type="radio"
                      name="status"
                      className="me-1"
                      checked={enabled == "Inactive" ? true : false}
                      onChange={() => {
                        setEnabled("Inactive");
                      }}
                    />
                    No
                  </span>
                </div> */}

                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="text-dark me-2"
                  >
                    Display In Website
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          name="contains-alcohol"
                          checked={enabled == "Yes" ? true : false}
                          onChange={() => {
                            setEnabled("Yes");
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          name="contains-alcohol"
                          checked={enabled == "No" ? true : false}
                          onChange={() => {
                            setEnabled("No");
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="default-btn btn-white border-0 btn ms-auto"
                  onClick={() => {
                    onSaveServing();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServingAction;
