import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Offcanvas, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit, AiOutlineFolderView } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { store } from "../../components/Context/store";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import PostApiCall from "../../helper/Api";
import "./CategoryMaster.css";
import noImage from "../../assets/images/no-image.png";

const CategoryMaster = () => {
  let navigate = useNavigate();
  const [categoryList, setcategoryList] = useState([]);
  const [visits, setVisit] = useState(false);
  const [show, setShow] = useState(false);
  const [photos, setPhotos] = useState([]);
  const handleClose = () => setShow(false);
  const { allItemMasterData, setAllItemMasterData } = useContext(store);
  const handleShow = (headerTitle) => {
    setShow(true);
    setSliderHeader(headerTitle)
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }


  //category edit details
  const [updatedItemsData, setUpdatedItemsData] = useState(false);
  const [name, setName] = useState("");
  const [sliderHeader, setSliderHeader] = useState("");
  const [catId, setCatId] = useState(null);
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFileName] = useState("");
  const [previewUrl, setPreviewUrl] = useState(
    "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png"
  );

  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://api.grubdigest.com/api-grub/AddImage"
  );
  const [enabledflag, setEnabledFlag] = useState("Yes");
  const [VariantImage, setVariantImage] = useState("");

  const handleCloseVisitForm = () => {
    setVisit(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name !== "") {
      onPost();
    } else {
      Notiflix.Notify.failure("Please enter Name");
    }
  };

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
  };

  const onPost = () => {
    Notiflix.Loading.dots();
    if (outputFileName != "") {
      var randomnumber = Math.floor(100000 + Math.random() * 900000);
      var fileName = outputFileName.split(".").slice(0, -1).join(".") + ".webp";
      const form = new FormData();
      form.append("file", originalImage);
      form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
        ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
        : "");
      form.append("foldername", "category");
      form.append("filename", randomnumber + "-" + outputFileName);
      let response;
      response = fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((res) => {
        res.json().then((res1) => {
          PostApiCall.postRequest(
            {
              catid: catId == null ? null : catId,
              name: name,
              image: res1.Message,
              enabledflag: enabledflag,
              createdby: null,
            },
            "updateCategoryMaster"
          ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
              if (resultcategory.status == 200 || resultcategory.status == 201) {
                const form = new FormData();
                form.append("file", originalImage);
                form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
                  ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
                  : "");
                form.append("foldername", "category");
                form.append("filename", randomnumber + "-" + outputFileName);
                let response;
                response = fetch(ImageApiUrl, {
                  method: "POST",
                  body: form,
                }).then((res) => {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success("Category added successfully");
                  getCategoryList()
                  handleClose()
                });


              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(obj.data);
              }
            })
          );
        })
      });

      Notiflix.Loading.remove();
      Notiflix.Notify.success("Category added successfully");
      getCategoryList()
      handleClose()

    } else {
      PostApiCall.postRequest(
        {
          catid: catId == null ? null : catId,
          name: name,
          image: previewUrl,
          enabledflag: enabledflag,
          createdby: null,
        },
        "updateCategoryMaster"
      ).then((resultcategory) => resultcategory.json().then((obj) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Category added successfully");
        getCategoryList()
        handleClose()
      }))
    }



  };

  const ImgUpload = ({ onChange, src }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload fas w-100 d-flex justify-content-center"
    >
      <div className="img-wrap img-upload">
        <img for="photo-upload" src={src} style={{ borderRadius: "5%" }} />
      </div>
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        onChange={onChange}
      />
    </label>
  );

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    PostApiCall.postRequest(
      {
        WhereClause: "",
        columns: "*",
      },
      "getCategoryList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          setcategoryList(obj.message);
        }
      })
    );
  };
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">{sliderHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row form-container">

            <div className="col-12">
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    {/* <label htmlFor="sw-arrows-first-name">Item Image</label> */}
                    <div className="div1">
                      <ImgUpload onChange={photoUpload} src={previewUrl} />
                    </div>
                    (Upload image in png/jpg/webp format maximum size of 1MB*)
                  </div>
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col lg={12}>
                      {/* <label>
                        Item Name
                        <span style={{ color: "red", marginTop: "1.5rem" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        value={name}
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      /> */}
                      <TextField
                        id="outlined-basic"
                        label="Category Name *"
                        variant="outlined"
                        className="w-100"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Col>
                    <Col xl="6" className="mt-4">
                      {/* <div className="form-group">
                        <label htmlFor="CategoryName">
                          Show On Website
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <label className="radio-inline">
                          <input
                            type="radio"
                            name="optradio"
                            checked={enabledflag == "Yes" ? true : false}
                            onChange={() => {
                              setEnabledFlag("Yes");
                            }}
                          />{" "}
                          Yes
                        </label>
                        <label
                          className="radio-inline"
                          style={{ marginLeft: "10px" }}>
                          <input
                            type="radio"
                            name="optradio"
                            checked={enabledflag == "No" ? true : false}
                            onChange={() => {
                              setEnabledFlag("No");
                            }}
                          />{" "}
                          No
                        </label>
                      </div> */}

                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="text-dark me-2"
                      >
                        Show On Website *
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              name="bestseller"
                              checked={enabledflag == "Yes" ? true : false}
                              onChange={() => {
                                setEnabledFlag("Yes");
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              name="bestseller"
                              checked={enabledflag == "No" ? true : false}
                              onChange={() => {
                                setEnabledFlag("No");
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xl="12" className="text-end">
                  {" "}
                  <button
                    className="default-btn btn-white border-0 btn ms-auto"
                    type="submit"
                    onClick={onSubmit}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid inner-page-height">
          <div className="row form-container">
            <div className="mb-3 d-flex justify-content-between pb-3 mb-3">
              {/* <h3 class="main-text fw-bold">Category List</h3> */}
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Category List</h5>

              </div>
              <button
                type="button"
                className="default-btn btn btn-white"
                onClick={() => {
                  setCatId(null);
                  setName("");
                  setPreviewUrl('https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png')
                  handleShow("Add New Category")
                }}
              >
                Add New Category
              </button>
            </div>
            {categoryList.map((data, i) => {
              return (
                <div className="col-md-6 col-lg-3 px-3 mb-5">
                  <div className="default-card shadow border-light">
                    <div className="card-content category-card">
                      <div className="d-flex align-item-center justify-content-center">
                        <img
                          className="img-fluid"
                          src={data.FLD_Image == "" || data.FLD_Image == null ? noImage : data.FLD_Image}
                          alt=""
                        ></img>
                      </div>
                      <h5 className="card-item-name">{data.FLD_Name}</h5>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <div className="d-flex flex-column text-start">
                          <span className={data.FLD_Enabled_Flag}>
                            {data.FLD_Enabled_Flag}
                          </span>
                          <span>

                            Items :{" "}
                            {data.fld_item_count}
                          </span>
                        </div>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              Notiflix.Loading.dots();
                              PostApiCall.postRequest(
                                {
                                  whereClause: ` where fld_cat_id = ${data.FLD_Category_Id} `,
                                },
                                "getAllItemDetails"
                              ).then((resultdes) =>
                                resultdes.json().then((obj) => {
                                  if (obj.data.length != 0) {
                                    setAllItemMasterData(obj.data);
                                    Notiflix.Loading.remove();
                                    navigate("/itemmaster");
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure("No Records Exits");
                                  }
                                })
                              );
                            }}
                          >
                            <AiOutlineFolderView />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              handleShow("Edit " + data.FLD_Name + " Category")
                              setName(data.FLD_Name);
                              setPreviewUrl(data.FLD_Image == "" ? noImage : data.FLD_Image)
                              setEnabledFlag(data.FLD_Enabled_Flag)
                              setCatId(data.FLD_Category_Id)
                            }}
                          >
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this category?",
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      PostApiCall.postRequest(
                                        {
                                          id: data.FLD_Category_Id,
                                          deleteFrom: "CategoryMaster",
                                        },
                                        "DeleteMaster"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "successfully deleted."
                                            );
                                            getCategoryList();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Modal
        show={visits}
        onHide={handleCloseVisitForm}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-end"></Modal.Header>

        <Modal.Body>
          <div style={{ height: "60px" }}></div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CategoryMaster;
