import {
  Checkbox, FormControlLabel, FormLabel, Radio,
  RadioGroup
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import {
  Accordion, Button, Card, Col,
  Container, Offcanvas, Row
} from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { RiCouponLine } from "react-icons/ri";
import { store } from "../../components/Context/store";
import GetApiCall from "../../helper/GETAPI";
import "./Offers.css";
function Offers() {
  const [show, setShow] = useState(false);
  const { allOffersDetails, setAllOfferDetails, currencyCode } = useContext(store);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetOfferDetails").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllOfferDetails(obj.data);

          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);
  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid className="inner-page-height">
          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-between">
                <div className="dash-heading">
                  <h5 className="fw-bold text-black">Offers</h5>
                  <p>Manage Promotion Offers</p>
                </div>
                <div>
                  <Button
                    variant="white"
                    onClick={handleShow}
                    className="kot-btn"
                  >
                    Add New Offers
                  </Button>
                </div>
              </div>
            </Col>
            {allOffersDetails.map((offer) => {
              return (
                <Col md={6} lg={3} className="mt-4">
                  <Card className="shadow mt-0 border-light offer-coupon-card" onClick={() => {
                    // handleShow()
                  }}>
                    <Card.Body>
                      <span className="offer-use">
                        Applied {offer.fld_applied_times} times worth{" "}
                        {currencyCode} {" "}
                        {offer.fld_offer_worth}
                      </span>
                      <Card.Text className="px-3">
                        <div className="d-flex align-items-center mb-2">
                          <RiCouponLine className="offer-coupon-card-icon" />
                          <h5 className="fw-bold text-dark mb-0">
                            {offer.fld_percent_off} OFF up to {currencyCode} {" "}
                            {offer.fld_offer_upto}
                          </h5>
                        </div>
                        <div className="">
                          <p className="mb-2">{offer.fld_offer_description}</p>
                          <p className="text-red fw-bold">
                            {offer.fld_offer_eligiblity}
                          </p>
                        </div>

                        <Accordion>
                          <Accordion.Item eventKey="0" className="border-0">
                            <div className="ps-3 pe-2 d-flex justify-content-between align-items-center border-top mt-2">
                              <p className="fw-bold text-dark">
                                {offer.fld_offer_name}
                              </p>
                              <Accordion.Header>
                                View Details <BsChevronDown className="ms-1" />{" "}
                              </Accordion.Header>
                            </div>
                            <Accordion.Body>
                              <ul>
                                <li>
                                  {
                                    offer.fld_offer_terms_and_condition.split(
                                      ","
                                    )[0]
                                  }
                                </li>
                                <li>
                                  {
                                    offer.fld_offer_terms_and_condition.split(
                                      ","
                                    )[1]
                                  }
                                </li>
                                <li>
                                  {
                                    offer.fld_offer_terms_and_condition.split(
                                      ","
                                    )[2]
                                  }
                                </li>
                                <li>
                                  {
                                    offer.fld_offer_terms_and_condition.split(
                                      ","
                                    )[3]
                                  }
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">Add New Offer</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="kot-offcanvas-box">
            <TextField
              id="outlined-basic"
              label="Offer Name"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Caption"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Discount Percentage"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Maximum Discount Applicable"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Minimum Cart Value to Apply Discount"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Minimum Products Required to Apply Discount"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Free Gift"
              variant="outlined"
              className="mt-3 w-100"
            />
            {/* <TextField
              id="outlined-basic"
              label="First Order"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="All Order"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Onetime"
              variant="outlined"
              className="mt-3 w-100"
            /> */}

            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="mt-2 text-dark"
            >
              Number of Times Offer can be Applied
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="mt-2"
            >
              <div>
                <FormControlLabel
                  value="FirstOrder"
                  control={<Radio />}
                  label="First Order"
                />
                <FormControlLabel
                  value="AllOrder"
                  control={<Radio />}
                  label="All Order"
                />
              </div>
              <div>
                <FormControlLabel
                  value="Onetime"
                  control={<Radio />}
                  label="One Time"
                />
                <FormControlLabel
                  value="others"
                  control={<Radio />}
                  label="Others"
                />
              </div>
              <TextField
                id="outlined-basic"
                label="Others"
                variant="outlined"
                className="mt-3 w-100"
              />
            </RadioGroup>

            <TextField
              id="outlined-basic"
              label="Number of Times Discount Can Be Applied"
              variant="outlined"
              className="mt-3 w-100"
            />
            <FormControlLabel
              control={<Checkbox name="FreeShipping" />}
              label="Free Shipping"
              className="mt-2"
            />
            {/* <TextField
              id="outlined-basic"
              label="Free Shipping"
              variant="outlined"
              className="mt-3 w-100"
            /> */}
            <TextField
              id="outlined-basic"
              label="Refferal Discount"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Coupon Code"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Offer Start Date"
              variant="outlined"
              className="mt-3 w-100"
            />
            <TextField
              id="outlined-basic"
              label="Offer End Date"
              variant="outlined"
              className="mt-3 w-100"
            />

            {/* Textarea */}
            <TextField
              id="outlined-basic"
              label="Terms & Conditions"
              variant="outlined"
              className="mt-3 w-100"
            />

            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="mt-3 text-dark"
            >
              Show on Website
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </div>
          <div className="mt-3 text-center w-100">
            <Button variant="white" className="kot-btn">
              Save Offer
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Offers;
