import { Checkbox, Collapse } from "antd";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./Catering.css";
const { Panel } = Collapse;
const Catering = () => {
    let navigate = useNavigate();
    const [categoryList, setcategoryList] = useState([]);
    const [itemData, setItemData] = useState([]);

    useEffect(() => {
        getCategoryList();
        GetItemDetails();
    }, []);

    const getCategoryList = () => {
        PostApiCall.postRequest(
            {
                WhereClause: "",
                columns: "*",
            },
            "getCategoryList"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setcategoryList(obj.message);
                }
            })
        );
    };
    function GetItemDetails() {
        Notiflix.Loading.dots();
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "getAllItemDetails"
        ).then((resultdes) =>
            resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                    setItemData(obj.data);
                    Notiflix.Loading.remove();
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("No Records Exits");
                }
            })
        );
    }
    return (
        <>
            <section className="pb-lg-5 pt-4 margin-left">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-6">
                            <FloatingLabel
                                controlId="floatingInput"
                                className="restaurant-name-input"
                                label="Min Orders">
                                <Form.Control
                                    type="text"
                                    placeholder="Min Persons"

                                    // value={restaurantName}
                                    onChange={(e) => {
                                        // setRestaurantName(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="col-6">
                            <FloatingLabel
                                controlId="floatingInput"
                                className="restaurant-name-input"
                                label="Base Amount">
                                <Form.Control
                                    type="text"
                                    placeholder="Base Amount"

                                    // value={restaurantName}
                                    onChange={(e) => {
                                        // setRestaurantName(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    {categoryList?.map((data) => {
                        const filteredCuisines = itemData.filter(
                            (dat) => dat.FLD_Cat_Id === data.fld_category_id
                        );
                        return (
                            <>
                                <Collapse accordion>
                                    <Panel header={
                                        <div className="d-flex justify-content-between">
                                            <p>{data.FLD_Name}</p>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                className="restaurant-name-input"
                                                label="Max Selection">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Max Selection"

                                                    // value={restaurantName}
                                                    onChange={(e) => {
                                                        // setRestaurantName(e.target.value);
                                                    }}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    } key={data.FLD_Category_Id}>
                                        <div className="row">
                                            {itemData.length > 0 ? itemData.filter((item) => {
                                                if (data.FLD_Category_Id == item.fld_cat_id) {
                                                    return item;
                                                }
                                            }).map((itemDat) => {
                                                return (
                                                    <div className="col-4">
                                                        <div className="d-flex">
                                                            <div className="me-2">
                                                                <Checkbox />
                                                            </div>
                                                            <div className="catering-item-name">
                                                                {itemDat.fld_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : ""}
                                        </div>
                                    </Panel>
                                </Collapse>
                            </>
                        )
                    })}
                    <div className="col-lg-12 text-center mt-lg-4 mt-4 save-btn-bottom-fixed py-3">
                        <div className="d-lg-flex justify-content-between align-items-center border rounded p-3 p-lg-2">
                            <div className="fw-bold">
                                {/* <Form.Check
                                    type="checkbox"
                                    // value={receiveUpdate}
                                    // checked={receiveUpdate}
                                    // onChange={(e) => {
                                    //     setReceiveUpdate(e.target.value);
                                    // }}
                                    label="Yes, I would like to receive important updates and notifications from Grub Digest"
                                /> */}
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    variant="danger"
                                    className="restaurant-save-btn w-auto mt-3 mt-lg-0"
                                // onClick={() => {
                                //     OnSaveForm();
                                // }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Catering;
