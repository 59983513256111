import React from 'react';
import "./LoyaltyProgram.css";
import { Col, Container, Row } from 'react-bootstrap';

function LoyaltyProgram() {
  return (
    <section className="pb-lg-5 pt-4 margin-left">
      <Container fluid>
        <Row>
          <Col>
          <div className="dash-heading">
              <h5 className="fw-bold text-black">Loyalty Program</h5>
              <p>??</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LoyaltyProgram;