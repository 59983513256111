import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import "./SideBar.css";
// import HouseIcon from "@mui/icons-material/House";
// import SchoolIcon from "@mui/icons-material/School";
// import PersonIcon from "@mui/icons-material/Person";
// import LocalDiningIcon from "@mui/icons-material/LocalDining";
// import FolderIcon from "@mui/icons-material/Folder";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiAddToQueue, BiDish, BiReceipt, BiUser } from "react-icons/bi";
import { BsFileBarGraph } from "react-icons/bs";
import { FiPrinter } from "react-icons/fi";
import { GoStack } from "react-icons/go";
import { MdDinnerDining, MdFoodBank, MdOutlineFastfood } from "react-icons/md";
import { RiCoupon2Line } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { TfiLayoutSlider } from "react-icons/tfi";
import { Link } from "react-router-dom";

const SideBar = (props) => {
  return (
    <>
      <div className="sidenavbar d-flex flex-column justify-content-between">
        <Sidebar collapsed={props.collapsed} className="pt-3">
          <Menu>
            <Link to={"/dashboard"}>
              <MenuItem className="sidebar-menuitem">
                <div>
                  <AiOutlineDashboard className="sidebar-icon" /> Dashboard
                </div>
              </MenuItem>
            </Link>
            <Link to={"/profile"}>
              <MenuItem className="sidebar-menuitem">
                <div>
                  <BiUser className="sidebar-icon" /> Profile
                </div>
              </MenuItem>
            </Link>
            <SubMenu
              label="Menu"
              className="sidebar-menuitem"
              icon={<BiDish className="sidebar-icon" />}>
              <Link to={"/categorymaster"}>
                <MenuItem>
                  <div>
                    <GoStack className="sidebar-icon" /> Categories
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/itemmaster"}>
                <MenuItem>
                  <div>
                    <MdOutlineFastfood className="sidebar-icon" /> Items
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/addonmaster"}>
                <MenuItem>
                  <div>
                    <BiAddToQueue className="sidebar-icon" /> Addons
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/itemproperty"}>
                <MenuItem>
                  <div>
                    <MdOutlineFastfood className="sidebar-icon" /> Item Property
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/foodtypemaster"}>
                <MenuItem>
                  <div>
                    <MdFoodBank className="sidebar-icon" /> Food Type
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/servingmaster"}>
                <MenuItem>
                  <div>
                    <MdDinnerDining className="sidebar-icon" /> Serving
                  </div>
                </MenuItem>
              </Link>
              {/* <MenuItem> Pdf</MenuItem> */}
            </SubMenu>
            <Link to={"/customers"}>
              <MenuItem className="sidebar-menuitem">
                <div>
                  <BiUser className="sidebar-icon" /> Customers
                </div>
              </MenuItem>
            </Link>
            <Link to={"/orders"}>
              <MenuItem className="sidebar-menuitem">
                <div>
                  <BiReceipt className="sidebar-icon" /> Orders
                </div>
              </MenuItem>
            </Link>
            <Link to={"/kot"}>
              <MenuItem className="sidebar-menuitem">
                <div>
                  <FiPrinter className="sidebar-icon" /> KOT
                </div>
              </MenuItem>
            </Link>

            <Link to={"/offers"}>
              <MenuItem className="sidebar-menuitem">
                <div>
                  <RiCoupon2Line className="sidebar-icon" /> Offers
                </div>
              </MenuItem>
            </Link>
            {/* <SubMenu
              label="CRM"
              className="sidebar-menuitem"
              icon={<PiHandshake className="sidebar-icon" />}
            >
              <Link to={"/email-campaign"}>
                <MenuItem>
                  {" "}
                  <AiOutlineMail className="sidebar-icon" />
                  Email Campaign
                </MenuItem>
              </Link>
              <Link to={"/loyalty-program"}>
                <MenuItem>
                  {" "}
                  <MdOutlineLoyalty className="sidebar-icon" />
                  Loyalty Program
                </MenuItem>
              </Link>
              <Link to={"/reviews"}>
                <MenuItem>
                  {" "}
                  <MdOutlineReviews className="sidebar-icon" />
                  Reviews
                </MenuItem>
              </Link>
              <Link to={"/send-coupons"}>
                <MenuItem>
                  {" "}
                  <RiCoupon3Line className="sidebar-icon" />
                  Send Coupons
                </MenuItem>
              </Link>
            </SubMenu> */}
            {/* <SubMenu
              label="Reports"
              className="sidebar-menuitem"
              icon={<TbReportSearch className="sidebar-icon" />}>
              <Link to={"/sales-report"}>
                <MenuItem>
                  <div>
                    <BsFileBarGraph className="sidebar-icon" />
                    Sales
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/category-wise"}>
                <MenuItem>
                  <div>
                    <GoStack className="sidebar-icon" />
                    Category Wise
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/item-wise"}>
                <MenuItem>
                  <div>
                    <TbReportSearch className="sidebar-icon" />
                    Item Wise
                  </div>
                </MenuItem>
              </Link>
            </SubMenu> */}
            <SubMenu
              label="Website CMS"
              className="sidebar-menuitem"
              icon={<TbReportSearch className="sidebar-icon" />}>
              <Link to={"/keystringsmaster"}>
                <MenuItem>
                  <div>
                    <BsFileBarGraph className="sidebar-icon" />
                    Content
                  </div>
                </MenuItem>
              </Link>
              <Link to={"/bannermanagement"}>
                <MenuItem>
                  <div>
                    <TfiLayoutSlider className="sidebar-icon" />
                    Banners
                  </div>
                </MenuItem>
              </Link>
            </SubMenu>
            {/* <Link to={"/help"}>
              <MenuItem className="sidebar-menuitem">
                <MdOutlineLiveHelp className="sidebar-icon" /> Help
              </MenuItem>
            </Link>
            <Link to={"/faq"}>
              <MenuItem className="sidebar-menuitem">
                <FaQuora className="sidebar-icon" /> FAQ
              </MenuItem>
            </Link>
            <Link to={"/support"}>
              <MenuItem className="sidebar-menuitem">
                <BiSupport className="sidebar-icon" /> Support
              </MenuItem>
            </Link> */}
          </Menu>
        </Sidebar>
        <p className="version-text mx-auto">V - 1.0</p>
      </div>
    </>
  );
};

export default SideBar;
