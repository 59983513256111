import {
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import "./KeyStringsMaster.css";

const KeyStringsMaster = () => {
  let navigate = useNavigate();
  const [allKeyStringsData, setAllKeyStringsData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);

  const [sliderHeader, setSliderHeader] = useState("");
  const [keyStringId, setKeyStringId] = useState("");

  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [displayIn, setDisplayIn] = useState("Yes");

  function GetKeyStrings() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("getKeyStrings").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllKeyStringsData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  function onSaveKeyString() {
    if (name != "" && value != "") {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          id: keyStringId,
          name: name,
          value: value,
          displayIn: displayIn,
        },
        "AddKeyStrings"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Key string added successfully");
            handleCloseEdit()
            GetKeyStrings()
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }
  }


  const handleShowEdit = (headerTitle) => {
    setShowEdit(true);
    setSliderHeader(headerTitle)
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }

  useEffect(() => {
    GetKeyStrings();
  }, []);

  return (
    <>
      <Offcanvas
        show={showEdit}
        onHide={handleCloseEdit}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">{sliderHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="pb-lg-5 pt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    className="w-100"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Value"
                    variant="outlined"
                    className="w-100"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-12">
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="text-dark me-2"
                  >
                    Display In Website
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          name="contains-alcohol"
                          checked={displayIn == "Yes" ? true : false}
                          onChange={() => {
                            setDisplayIn("Yes");
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          name="contains-alcohol"
                          checked={displayIn == "No" ? true : false}
                          onChange={() => {
                            setDisplayIn("No");
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    className="default-btn btn-white border-0 btn ms-auto"
                    onClick={() => {
                      onSaveKeyString();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid inner-page-height">
          <div className="row form-container">
            <div className="d-flex justify-content-between pb-3">
              <div className="dash-heading mb-4">
                <h5 className="fw-bold text-black">Key Strings List</h5>

              </div>
              {/* <h3 class="main-text fw-bold">Key Strings List</h3> */}
              <button
                type="button"
                className="default-btn btn btn-white"
                onClick={() => {
                  setShowEdit("Add New Keystring")
                  setName(null);
                  setValue(null);
                  setDisplayIn(null)
                }}
              >
                Add New Key Strings
              </button>
            </div>

            {allKeyStringsData.map((keystring, i) => {
              return (
                <div className="col-md-6 col-lg-4 px-3 mb-5">
                  <div className="default-card shadow border-light h-100 keystring-card">
                    <div className="card-content d-flex flex-column justify-content-between h-100">
                      <div>
                        <span>{keystring.FLD_Key_Name}</span>
                        <h4>{keystring.FLD_Key_Value}</h4>
                      </div>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <span className={keystring.FLD_Display_In}>
                          {keystring.FLD_Display_In}
                        </span>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              setShowEdit("Edit Keystring")
                              setKeyStringId(keystring.FLD_Key_ID)
                              setName(keystring.FLD_Key_Name);
                              setValue(keystring.FLD_Key_Value);
                              setDisplayIn(keystring.FLD_Display_In)
                            }}
                          >
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this key strings?",

                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      Notiflix.Loading.dots();
                                      PostApiCall.postRequest(
                                        {
                                          id: keystring.FLD_Key_ID,
                                        },
                                        "DeleteKeyString"
                                      ).then((results1) =>
                                        results1.json().then((obj1) => {
                                          if (
                                            results1.status == 200 ||
                                            results1.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "Key string deleted successfully"
                                            );
                                            GetKeyStrings();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Error Occurred"
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default KeyStringsMaster;
