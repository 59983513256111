import React from "react";
import PageNotFoundImage from "../../assets/images/ERROR.jpg";
import { Link } from "react-router-dom";
const PageNotFound = () => {
    return (
        <>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="four-o-four mt-4">
                                <div className="d-flex justify-content-center">
                                    <img
                                        src={PageNotFoundImage}
                                        className="error-image img-fluid h-50"
                                        alt="Error"
                                    ></img>
                                </div>
                                <div
                                    className="center-block mt-3"
                                    style={{ display: "grid", justifyContent: "center", textAlign: "center" }}
                                >
                                    <p>The page you are looking for is not found.</p>
                                    <h1>Something's missing.</h1>
                                    <div className="d-flex justify-content-center">
                                        {/* <Link to="/" className="butn"> */}

                                        {/* btn name chnage by laxmi  -----------------------------*/}
                                        <Link to="/" className="btn default-btn text-white mt-4 w-auto">
                                            Go to Login
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PageNotFound;
