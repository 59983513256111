import React from 'react';
import "./Website.css";
import { Col, Container, Row } from 'react-bootstrap';

function Website() {
  return (
    <section className="py-lg-5">
      <Container>
        <Row>
          <Col>
            <h1>Website</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Website;