import { createContext, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
  const [activeLink, setactiveLink] = useState("Home");
  const [allCounts, setAllCounts] = useState([]);
  const [allRecentOrders, setAllRecentOrders] = useState([]);
  const [allTrendingOrders, setAllTrendingOrders] = useState([]);
  const [allOrdersHistory, setAllOrdersHistory] = useState([]);
  const [allCustomersList, setAllCustomersList] = useState([]);
  const [last10DaysCustomers, setLast10DaysCustomers] = useState([]);
  const [customerPersonalDetails, setCustomerPersonalDetails] = useState([]);
  const [customerLoyaltyDetails, setCustomerLoyaltyDetails] = useState([]);
  const [customerAddressDetails, setCustomerAddressDetails] = useState([]);
  const [customerFrequentlyOrder, setCustomerFrequentlyOrder] = useState([]);
  const [kotChipsCount, setKotChipsCount] = useState([]);
  const [allKotDetails, setAllKotDetails] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderDateTime, setOrderDateTime] = useState("");
  const [orderType, setOrderType] = useState("");
  const [restaurantType, setRestaurantType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [address, setAddress] = useState("");
  const [dish, setDish] = useState("");
  const [quantity, setQuantity] = useState("");
  const [prices, setPrices] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [itemTotal, setItemTotal] = useState("");
  const [couponName, setCouponName] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [offerDiscount, setOfferDiscount] = useState("");
  const [taxes, setTaxes] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [packingCharges, setPackingCharges] = useState("");
  const [tips, setTips] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [totalOrderSavings, setTotalOrderSavings] = useState("");
  const [allOffersDetails, setAllOfferDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [landmark, setLandmark] = useState("");
  const [cartId, setCartId] = useState(null);
  const [filterDashboard, setFilterDashBoard] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const [allItemMasterData, setAllItemMasterData] = useState([]);
  const [sortType, setSortType] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [currencyCode, setCurrencyCode] = useState([]);
  const [kotFilterVal, setKotFilterVal] = useState("NEW");
  const [chartHourlySales, setChartHourlySales] = useState({
    options: {
      chart: {
        height: 70,
        // width: '100%',
        type: "bar",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: 0,
          columnWidth: "50%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#eb1c24", "#faebd7"],
      fill: {
        colors: ["#eb1c24", "#faebd7"],
      },
      stroke: {
        curve: "smooth",
      },
      // responsive: {
      //     breakpoint: undefined,
      //     options: {},
      // }
    },
    series: [
      {
        name: "series1",
        data: [],
      },
    ],
  });
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        // width: '100%',
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#eb1c24", "#faebd7"],
      fill: {
        colors: ["#eb1c24", "#faebd7"],
      },
      stroke: {
        curve: "smooth",
      },
      // responsive: {
      //     breakpoint: undefined,
      //     options: {},
      // }
    },
    series: [
      {
        name: "Total Sales",
        data: [],
      },
    ],
  });
  const [chartDataCustomer, setChartDataCustomer] = useState({
    options: {
      chart: {
        height: 280,
        // width: '100%',
        type: "bar",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: 0,
          columnWidth: "50%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#eb1c24", "#faebd7"],
      fill: {
        colors: ["#eb1c24", "#faebd7"],
      },
      stroke: {
        curve: "smooth",
      },
      // responsive: {
      //     breakpoint: undefined,
      //     options: {},
      // }
    },
    series: [
      {
        name: "Customers",
        data: [],
      },
    ],
  });
  return (
    <store.Provider
      value={{
        activeLink,
        setactiveLink,
        allCounts,
        setAllCounts,
        allRecentOrders,
        setAllRecentOrders,
        allTrendingOrders,
        setAllTrendingOrders,
        allOrdersHistory,
        setAllOrdersHistory,
        customerPersonalDetails,
        setCustomerPersonalDetails,
        customerLoyaltyDetails,
        setCustomerLoyaltyDetails,
        customerAddressDetails,
        setCustomerAddressDetails,
        customerFrequentlyOrder,
        setCustomerFrequentlyOrder,
        kotChipsCount,
        setKotChipsCount,
        allKotDetails,
        setAllKotDetails,
        orderNumber,
        setOrderNumber,
        orderDateTime,
        setOrderDateTime,
        orderType,
        setOrderType,
        customerName,
        setCustomerName,
        customerMobile,
        setCustomerMobile,
        paymentDetails,
        setPaymentDetails,
        address,
        setAddress,
        dish,
        setDish,
        quantity,
        setQuantity,
        prices,
        setPrices,
        additionalInstructions,
        setAdditionalInstructions,
        itemTotal,
        setItemTotal,
        couponName,
        setCouponName,
        couponAmount,
        setCouponAmount,
        offerDiscount,
        setOfferDiscount,
        taxes,
        setTaxes,
        deliveryCharges,
        setDeliveryCharges,
        packingCharges,
        setPackingCharges,
        tips,
        setTips,
        grandTotal,
        setGrandTotal,
        totalOrderSavings,
        setTotalOrderSavings,
        allOffersDetails,
        setAllOfferDetails,
        orderDetails,
        setOrderDetails,
        landmark,
        setLandmark,
        cartId,
        setCartId,
        sortType,
        setSortType,
        filterDashboard,
        setFilterDashBoard,
        chartHourlySales,
        setChartHourlySales,
        totalSales,
        setTotalSales,
        chartData,
        setChartData,
        chartDataCustomer,
        setChartDataCustomer,
        allCustomersList,
        setAllCustomersList,
        searchBy,
        setSearchBy, last10DaysCustomers, setLast10DaysCustomers,
        restaurantType, setRestaurantType, allItemMasterData, setAllItemMasterData,
        kotFilterVal, setKotFilterVal,
        currencyCode, setCurrencyCode
      }}
    >
      {children}
    </store.Provider>
  );
};
export default Provider;
