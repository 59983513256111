import Notiflix from "notiflix";
import { useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import PostApiCall from "../../helper/Api";
import "../../pages/Dashboard/Dashboard.css";
import { store } from "../Context/store";
export default function HourlySales() {
  const { chartHourlySales, setChartHourlySales } = useContext(store);
  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      PostApiCall.postRequest(
        {
          WhereClause: "",
        },
        "Get_DashboardHourlySales"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            var hour = [];
            var value = [];
            obj.data.map((data1, i) => {
              value.push(data1.total_sales);
              hour.push(data1.month);
            });
            setChartHourlySales({
              options: {
                chart: {
                  height: 280,
                  // width: '100%',
                  type: "area",
                  stacked: !0,
                  toolbar: {
                    show: 0,
                  },
                  zoom: {
                    enabled: !0,
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: !0,
                    columnWidth: "10%",
                    endingShape: "rounded",
                  },
                },
                dataLabels: {
                  enabled: 0,
                },
                xaxis: {
                  categories: hour,
                },
                legend: {
                  position: "bottom",
                  show: 0,
                },
                colors: ["#eb1c24", "#faebd7"],
                fill: {
                  colors: ["#eb1c24", "#faebd7"],
                },
                stroke: {
                  curve: "smooth",
                },
                // responsive: {
                //     breakpoint: undefined,
                //     options: {},
                // }
              },
              series: [
                {
                  name: "Total Sales",
                  data: value,
                },
              ],
            });

            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, []);
  return (
    <>
      <Card className="shadow border-light">
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Hourly Sales</h5>
                <p>Hourly Breakup Sales Summary</p>
              </div>
            </div>
            <ReactApexChart
              options={chartHourlySales.options}
              series={chartHourlySales.series}
              type="bar"
              height={220}
              className="w-100"
            />
            {/* <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100px" }}
                  >
                    <p>No record available.</p>
                  </div> */}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
