import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./StaticMediaContentAction.css";
const StaticMediaContentAction = () => {
    let params = useParams();
    let location = useLocation();
    let navigate = useNavigate();
    const [contentId, setcontentId] = useState(null);
    const [originalImage, setoriginalImage] = useState([]);
    const [updatedItemsData, setUpdatedItemsData] = useState(false)
    const [pageName, setPageName] = useState("");
    const [pageHeading, setPageHeading] = useState("");
    const [pageSequence, setPageSequence] = useState("");
    const [pageDescription, setPageDescription] = useState("");
    const [outputFileName, setoutputFileName] = useState("");
    const [VariantImage, setVariantImage] = useState("");
    const [displayIn, setDisplayIn] = useState("Yes");
    const [ImageApiUrl, setImageApiUrl] = useState("https://api.grubdigest.com/api-grub/AddImage");
    const [previewUrl, setPreviewUrl] = useState(
        "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png"
    );
    const ImgUpload = ({ onChange, src }) => (
        <label htmlhtmlFor="photo-upload" className="custom-file-upload fas">
            <div className="img-wrap img-upload">
                <img
                    htmlFor="photo-upload"
                    src={src}
                    style={{ width: "100%", height: "100%", borderRadius: "5%" }}
                />
            </div>
            <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
        </label>
    );
    useEffect(() => {
        if (params.action == 'edit') {
            getcontentList(location.state)
        }
    }, []);
    const getcontentList = (id) => {
        PostApiCall.postRequest(
            {
                WhereClause: `where fld_static_page_id = ${id}`,
                columns: "*"
            },
            "getStaticContent"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    setVariantImage(obj.message[0].FLD_Image)
                    setPreviewUrl(obj.message[0].FLD_Image)
                    setcontentId(obj.message[0].fld_static_page_id)
                    setPageHeading(obj.message[0].fld_page_heading)
                    setPageName(obj.message[0].fld_page_name)
                    setPageDescription(obj.message[0].fld_description)
                    setPageSequence(obj.message[0].fld_sequence)
                }
            })
        );
    }
    async function onUploadImage(obj) {
        Notiflix.Loading.dots("Uploading Images...");
        let response;

        if (outputFileName != undefined) {

            var options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }



            const form = new FormData();

            form.append("file", originalImage);
            form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
                ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
                : "");
            form.append("foldername", "Images");
            form.append(
                "filename",
                originalImage.name

            );
            response = fetch(ImageApiUrl, {
                method: "POST",
                body: form,
            }).then(response => response.json())
                .then(data => {
                    Notiflix.Loading.remove()
                    Notiflix.Notify.success("Image successfully uploaded.")
                    setUpdatedItemsData(!updatedItemsData)
                    setPreviewUrl("https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png")
                    // setName("")
                    setcontentId(null)
                }

                )
        }
    }
    function onSaveContent() {
        PostApiCall.postRequest(
            {
                staticId: contentId,
                image: previewUrl != VariantImage ? "https://grubdigest.com/images/item/" + outputFileName : VariantImage,
                pageName: pageName,
                pageHeading: pageHeading,
                sequence: pageSequence,
                description: pageDescription,
                status: displayIn,
            },
            "updatestaticcontent"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("content added successfully");
                    if (contentId == null || previewUrl != VariantImage) {
                        onUploadImage();
                        navigate("/staticmediacontent")
                    } else {
                        setUpdatedItemsData(!updatedItemsData)
                        setPreviewUrl("https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png")
                        setcontentId(null)
                        setPageName("");
                        setPageDescription("");
                        setPageHeading("");
                        setPageSequence("");
                        navigate('/staticmediacontent')
                    }
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Error Occurred");
                }
            })
        );
    }
    return (
        <>
            <div className="container py-5">
                <div className="form-container row ">
                    <div className="col-md-8 mx-auto shadow p-4">
                        <div className="col-3">
                            <div>
                                <label htmlFor="sw-arrows-first-name">
                                    Item Image
                                </label>
                                <div className="div1">
                                    <ImgUpload
                                        onChange={(e) => {
                                            e.preventDefault();
                                            const imageFile = e.target.files[0];
                                            setPreviewUrl(URL.createObjectURL(imageFile))
                                            setoriginalImage(imageFile)
                                            setoutputFileName(imageFile.name)

                                        }}
                                        src={previewUrl}
                                    />
                                </div>
                                (Upload image in png/jpg/webp format <br />
                                maximum size of 1MB*
                                )
                            </div>
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Page Name
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="text"
                                value={pageName}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setPageName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Page Heading
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="text"
                                value={pageHeading}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setPageHeading(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Page Sequence
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="text"
                                value={pageSequence}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setPageSequence(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Page Description
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="text"
                                value={pageDescription}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setPageDescription(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>Display In Website</label>
                            </div>
                            <div className="d-flex">
                                <span className="d-flex me-2">
                                    <input
                                        type="radio"
                                        name="status"
                                        className="ms-2 me-1"
                                        checked={displayIn == "Yes" ? true : false}
                                        onChange={() => {
                                            setDisplayIn("Yes");
                                        }}
                                    />
                                    Yes
                                </span>
                                <span className="d-flex">
                                    <input
                                        type="radio"
                                        name="status"
                                        className="me-1"
                                        checked={displayIn == "No" ? true : false}
                                        onChange={() => {
                                            setDisplayIn("No");
                                        }}
                                    />
                                    No
                                </span>
                            </div>
                        </div>
                        <div className="text-end">
                            <button
                                type="button"
                                className="btn ms-auto"
                                onClick={() => {
                                    onSaveContent();
                                }}
                            >
                                submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default StaticMediaContentAction;