import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import "./Dashboard.css";

import { store } from "../../components/Context/store";
import CustomerSummaryChart from "../../components/CustomerSummaryChart/CustomerSummaryChart";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import HourlySales from "../../components/HourlySaleschart/HourlySaleschart";
import LatestCustomerList from "../../components/LatestCustomerList/LatestCustomerList";
import RecentOrdersChart from "../../components/RecentOrdersChart/RecentOrdersChart";
import Revenue from "../../components/RevenueChart/RevenueChart";
import TrendingItemsChart from "../../components/TrendingItemsChart/TrendingItemsChart";
import dashboardImage from "../../assets/images/dashboard-image.png";
import lockIcon from "../../assets/images/penguin-lock-icon.png"
import { RiInformationLine } from "react-icons/ri";
import DialogBox from "../../assets/images/Dialogue-box.png";
import PostApiCall from "../../helper/Api";
import Notiflix from "notiflix";
function Dashboard() {
  const { activeLink, setActiveLink } = useContext(store);
  const [premiumNotification, setPremiumNotification] = useState("")
  let restauranDetails = JSON.parse(localStorage.getItem("LoginDetail"))
  useEffect(() => {
    setPremiumNotification(`${sessionStorage.getItem("PremiumNotification")}`)
  }, [])
  const NotifyPremiumFeatures = () => {
    PostApiCall.postRequest(
      {
        companyName: restauranDetails?.fld_name,
        contactPerson: "",
        mobileNumber: restauranDetails?.fld_mobilenumber,
        email: restauranDetails?.client_mail,
      },
      "NotifyPremiumFeatures"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          sessionStorage.setItem("PremiumNotification", "success")
          setPremiumNotification("success")
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Authentication Failed!");
        }
      })
    );
  }
  const tooltip = (
    <Tooltip id="tooltip">
      To change the name of restaurant please contact on
      <strong><a href="tel:+7827795895">+91 78277-95895</a></strong>
    </Tooltip>
  );
  return (
    <section className="py-lg-4 margin-left">
      <Container fluid>
        {JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype ==
          1 ||
          JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype ==
          3 ? (
          <Row>
            <Col lg={12} className="mb-4">
              <DashboardFilter />
            </Col>
            <DashboardCard />
            <Col lg={12} className="mt-4">
              <HourlySales />
            </Col>
            <Col lg={6} className="mt-4">
              <Revenue />
            </Col>
            <Col lg={6} className="mt-4">
              <CustomerSummaryChart />
            </Col>
            <Col lg={8} className="mt-4">
              <RecentOrdersChart />
            </Col>
            <Col lg={4} className="mt-4">
              <TrendingItemsChart />
            </Col>
            <Col lg={12} className="mt-4">
              <LatestCustomerList />
            </Col>
          </Row>
        ) : (
          <>
            {/* <div class="image">
                <div class="overlay"></div>
              </div> */}
            <img
              src={dashboardImage}
              alt=""
              className="customer-dashboard-image"
            />
            <div className="dashboard-lock-icon">
              <img alt="" src={lockIcon} className="img-fluid" />
              <div className="lock-card">
                <img src={DialogBox} className="img-fluid" />
                {premiumNotification == "success" ?
                  <div className="thankyou-text">
                    <p>
                      Thank You !
                    </p>
                  </div> :
                  <div>
                    <p>
                      To unlock premium features
                    </p>
                    <Button variant="white" className="btn btn-white" onClick={() => { NotifyPremiumFeatures() }}>Click me</Button>
                  </div>
                }
              </div>
            </div>
            {/* <div className="dashboard-i-icon">
              <OverlayTrigger delayShow={300} delayHide={150} placement="bottom" overlay={tooltip}>
                <span bsStyle="danger"><RiInformationLine /></span>
              </OverlayTrigger>
            </div> */}

          </>
        )}
      </Container>
    </section>
  );
}

export default Dashboard;
