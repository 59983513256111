import moment from 'moment';
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./TimeMasterAction.css";
const TimeMasterAction = () => {
    let params = useParams();
    let location = useLocation();
    const [timeId, setTimeId] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [discount, setDiscount] = useState("");
    const [discountPer, setDiscountPer] = useState("");
    const [defaultVal, setDefaultVal] = useState("Yes");
    const [minDiscount, setMinDiscount] = useState(null);
    const [displayIn, setDisplayIn] = useState("Yes");

    useEffect(() => {
        if (params.action == 'edit') {
            getTimeList(location.state)
        }
    }, []);
    const getTimeList = (id) => {
        PostApiCall.postRequest(
            {
                WhereClause: `where fld_static_page_id = ${id}`,
                columns: "*"
            },
            "getStaticContent"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    setTimeId(obj.message[0].fld_static_page_id)
                    setEndTime(obj.message[0].fld_page_heading)
                    setStartTime(obj.message[0].fld_page_name)
                    setDiscountPer(obj.message[0].fld_description)
                    setDiscount(obj.message[0].fld_sequence)
                }
            })
        );
    }
    function onSaveTime() {
        PostApiCall.postRequest(
            {
                timeId: timeId,
                starttime: moment(startTime, "hh:mm:ss").format("hh:mm[:ss]"),
                endtime: endTime,
                status: displayIn,
                discount: discount,
                discountpercentage: discountPer,
                mincartdiscountamt: minDiscount,
                defalut: defaultVal
            },
            "updateTimeMaster"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("added successfully");
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Error Occurred");
                }
            })
        );
    }
    return (
        <>
            <div className="container py-5">
                <div className="form-container row ">
                    <div className="col-md-8 mx-auto shadow p-4">
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Start time
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="time"
                                step="2"
                                value={startTime}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setStartTime(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    End time
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="time"
                                step="2"
                                value={endTime}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setEndTime(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Discount
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="text"
                                value={discount}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setDiscount(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Dicount percentage
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="number"
                                value={discountPer}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setDiscountPer(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>
                                    Min discount
                                </label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                                type="number"
                                value={minDiscount}
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                    setMinDiscount(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>Default</label>
                            </div>
                            <div className="d-flex">
                                <span className="d-flex me-2">
                                    <input
                                        type="radio"
                                        name="status"
                                        className="ms-2 me-1"
                                        checked={defaultVal == "Yes" ? true : false}
                                        onChange={() => {
                                            setDefaultVal("Yes");
                                        }}
                                    />
                                    Yes
                                </span>
                                <span className="d-flex">
                                    <input
                                        type="radio"
                                        name="status"
                                        className="me-1"
                                        checked={defaultVal == "No" ? true : false}
                                        onChange={() => {
                                            setDefaultVal("No");
                                        }}
                                    />
                                    No
                                </span>
                            </div>
                        </div>
                        <div className="form-box align-items-center d-md-flex border-light mb-4">
                            <div className="field-name">
                                <label>Display In Website</label>
                            </div>
                            <div className="d-flex">
                                <span className="d-flex me-2">
                                    <input
                                        type="radio"
                                        name="status"
                                        className="ms-2 me-1"
                                        checked={displayIn == "Yes" ? true : false}
                                        onChange={() => {
                                            setDisplayIn("Yes");
                                        }}
                                    />
                                    Yes
                                </span>
                                <span className="d-flex">
                                    <input
                                        type="radio"
                                        name="status"
                                        className="me-1"
                                        checked={displayIn == "No" ? true : false}
                                        onChange={() => {
                                            setDisplayIn("No");
                                        }}
                                    />
                                    No
                                </span>
                            </div>
                        </div>
                        <div className="text-end">
                            <button
                                type="button"
                                className="btn ms-auto"
                                onClick={() => {
                                    onSaveTime();
                                }}
                            >
                                submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TimeMasterAction;