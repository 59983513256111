import {
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Sequentialize from "../../assets/images/Sequentialize.png";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import DragDropList from "./DragDropList/DragDropList";
import "./ServingMaster.css";

const ServingMaster = () => {
  let navigate = useNavigate();
  const [allServingData, setAllServingData] = useState([]);
  const [sliderHeader, setSliderHeader] = useState("");
  const [serveId, setServeId] = useState("");
  const [allActiveServingData, setAllActiveServingData] = useState([]);
  const [show, setShow] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);

  const [serve, setServe] = useState("");
  const [enabled, setEnabled] = useState("Yes");

  function GetServingDetails() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("getServingDetails").then((resultdes) => {
      if (resultdes.status == 200 || resultdes.status == 201) {
        resultdes.json().then((obj) => {
          if (obj.data.length != 0) {
            var allactiveserving = [];
            obj.data.map((serving, i) => {
              if (serving.fld_status == "Yes") {
                allactiveserving.push(serving);
              }
            });
            setAllActiveServingData(allactiveserving);
            setAllServingData(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        });
      }
    });
  }

  function onSaveServing() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: serveId,
        serve: serve,
        enabledFlag: enabled,
        createdOn: new Date(),
        createdBy: 1,
        updatedOn: new Date(),
        updatedBy: 1,
      },
      "UpdateServing"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Serving added successfully");
          GetServingDetails()
          handleCloseEdit()
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  const handleShowEdit = (headerTitle) => {
    setShowEdit(true);
    setSliderHeader(headerTitle)
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }

  useEffect(() => {
    GetServingDetails();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas
        show={showEdit}
        onHide={handleCloseEdit}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">{sliderHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="pb-lg-5 pt-4">
            <div className="container-fluid">
              <div className="row form-container">
                <div className="col-12 pe-4">
                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        id="outlined-basic"
                        label="Serve"
                        variant="outlined"
                        className="w-100"
                        value={serve}
                        onChange={(e) => {
                          setServe(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="text-dark me-2"
                      >
                        Display In Website
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "Yes" ? true : false}
                              onChange={() => {
                                setEnabled("Yes");
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              name="contains-alcohol"
                              checked={enabled == "No" ? true : false}
                              onChange={() => {
                                setEnabled("No");
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="default-btn btn-white border-0 btn ms-auto"
                      onClick={() => {
                        onSaveServing();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid className="inner-page-height">
          <Row>
            <Col lg={12} className="mt-4">
              <div className="mb-3 d-flex justify-content-between pb-3 mb-5">
                {/* <h3 class="main-text fw-bold">Serving List</h3> */}
                <div className="dash-heading mb-4">
                  <h5 className="fw-bold text-black">Serving List</h5>

                </div>
                <button
                  type="button"
                  className="kot-btn btn btn-white ms-auto sequence-btn me-3"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <img
                    alt="sequential-img"
                    src={Sequentialize}
                    className="sequentialize-btn img-fluid"
                  />
                </button>
                <button
                  type="button"
                  className="default-btn btn btn-white"
                  onClick={() => {
                    handleShowEdit("Add New Serving");
                    setServe(null)
                    setServeId(null)
                    setEnabled(null)
                  }}
                >
                  Add New Serving
                </button>
              </div>
            </Col>
            {allServingData.map((serving, i) => {
              return (
                <div className="col-md-3 px-3 mb-5">
                  <div className="default-card serving-card shadow border-light">
                    <div className="card-content">
                      <h4>{serving.fld_serve}</h4>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <span className={serving.fld_status}>
                          {serving.fld_status}
                        </span>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              handleShowEdit("Add New Serving");
                              setServe(serving.fld_serve)
                              setServeId(serving.fld_id)
                              setEnabled(serving.fld_status)
                            }}
                          >
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this serving?",
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      PostApiCall.postRequest(
                                        {
                                          id: serving.fld_id,
                                          deleteFrom: "ServingMaster",
                                        },
                                        "DeleteMaster"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "successfully deleted."
                                            );
                                            GetServingDetails();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Row>
        </Container>
      </section>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="sequence-offcanvas"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropList
            verticalData={allActiveServingData}
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ServingMaster;
