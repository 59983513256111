import { Route, Routes } from "react-router-dom";
import BannerManagementAction from "./components/BannerManagementAction/BannerManagementAction";
import CategoryMasterAction from "./components/CategoryMasterAction/CategoryMasterAction";
import FoodTypeAction from "./components/FoodTypeAction/FoodTypeAction";
import ImageUpload from "./components/ImageUploader/ImageUpload";
import ItemAction from "./components/ItemAction/ItemAction";
import ItemPropertyAction from "./components/ItemPropertyMapping/ItemPropertyAction";
import KeyStringsAction from "./components/KeyStringsAction/KeyStringsAction";
import Navbar from "./components/Navbar/SiteNavbar";
import ServingAction from "./components/ServingAction/ServingAction";
import StaticMediaContentAction from "./components/StaticMediaContentAction/StaticMediaContentAction";
import SubCategoryMasterAction from "./components/SubCategoryMasterAction/SubCategoryMasterAction";
import TimeMasterAction from "./components/TimeMasterAction/TimeMasterAction";
import AddOns from "./pages/AddOns/AddOns";
import BannerManagement from "./pages/BannerManagement/BannerManagement";
import CategoryMaster from "./pages/CategoryMaster/CategoryMaster";
import CustomerDashboard from "./pages/CustomerDashboard/CustomerDashboard";
import Customers from "./pages/Customers/Customers";
import Dashboard from "./pages/Dashboard/Dashboard";
import EmailCampaign from "./pages/EmailCampaign/EmailCampaign";
import FAQ from "./pages/FAQ/FAQ";
import FoodTypeMaster from "./pages/FoodTypeMaster/FoodTypeMaster";
import Help from "./pages/Help/Help";
import ItemMaster from "./pages/ItemMaster/ItemMaster";
import ItemMasterOffline from "./pages/ItemMaster/ItemMasterOffline";
import ItemPropertyMaster from "./pages/ItemPropertyMaster/ItemPropertyMaster";
import ItemsPage from "./pages/Items/Items";
import KeyStringsMaster from "./pages/KeyStringsMaster/KeyStringsMaster";
import Kot from "./pages/Kot/Kot";
import LoginPage from "./pages/Login/LoginPage";
import LoyaltyProgram from "./pages/LoyaltyProgram/LoyaltyProgram";
import Menu from "./pages/Menu/Menu";
import Offers from "./pages/Offers/Offers";
import Orders from "./pages/Orders/Orders";
import Profile from "./pages/Profile/Profile";
import CategoryWise from "./pages/Reports/CategoryWise/CategoryWise";
import ItemWise from "./pages/Reports/ItemWise/ItemWise";
import Reports from "./pages/Reports/Reports";
import SalesReport from "./pages/Reports/SalesReport/SalesReport";
import { default as Master, default as Reviews } from "./pages/Reviews/Master";
import SendCoupons from "./pages/SendCoupons/SendCoupons";
import ServingMaster from "./pages/ServingMaster/ServingMaster";
import StaticMediaContent from "./pages/StaticMediaContent/StaticMediaContent";
import SubCategoryMaster from "./pages/SubCategoryMaster/SubCategoryMaster";
import Support from "./pages/Support/Support";
import Website from "./pages/Website/Website";
import WebsiteCMS from "./pages/WebsiteCMS/WebsiteCMS";
import "./style.css";

// **** Prashant Component ********
import { useLocation } from "react-router-dom";
import AddonAction from "./components/AddonAction/AddonAction";
import Footer from "./components/Footer/Footer";
import AddonMaster from "./pages/AddonMaster/AddonMaster";
import Catering from "./pages/Catering/Catering";
import OrderForm from "./pages/OrderForm/OrderForm";
import SalesDashboard from "./pages/Sales/SalesDashboard";
import PageNotFound from "./pages/Support/PageNotFound";

function App() {
  let location = useLocation();
  if (
    localStorage.getItem("access") == null ||
    localStorage.getItem("access") == undefined
  ) {
    return (
      <>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
        </Routes>
      </>
    );
  } else {
    if (
      localStorage.getItem("access") != null ||
      localStorage.getItem("access") != undefined
    ) {
      if (
        JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype == 1 ||
        JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype == 3
      ) {
        return (
          <>
            {window.location.pathname == "/" || window.location.pathname == "*" ? (
              <></>
            ) : (
              <Navbar />
            )}
            <Routes>
              <Route exact path="/" element={<LoginPage />} />
              <Route exact path="/invoice" element={<OrderForm />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/categories" element={<Menu />} />
              <Route exact path="/customers" element={<Customers />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/orders" element={<Orders />} />
              <Route exact path="/kot" element={<Kot />} />
              <Route exact path="/offers" element={<Offers />} />
              <Route exact path="/reports" element={<Reports />} />
              <Route exact path="/reviews" element={<Reviews />} />
              <Route exact path="/master" element={<Master />} />
              <Route exact path="/website" element={<Website />} />
              <Route exact path="/itemmaster" element={<ItemMaster />} />
              <Route exact path="/imageupload" element={<ImageUpload />} />
              <Route
                exact
                path="/keystringsmaster"
                element={<KeyStringsMaster />}
              />
              <Route exact path="/items" element={<ItemsPage />} />
              <Route
                exact
                path="/loyalty-program"
                element={<LoyaltyProgram />}
              />
              <Route exact path="/email-campaign" element={<EmailCampaign />} />
              <Route exact path="/send-coupons" element={<SendCoupons />} />
              <Route exact path="/sales-report" element={<SalesReport />} />
              <Route exact path="/category-wise" element={<CategoryWise />} />
              <Route exact path="/item-wise" element={<ItemWise />} />
              <Route exact path="/add-ons" element={<AddOns />} />
              <Route exact path="/help" element={<Help />} />
              <Route exact path="/faq" element={<FAQ />} />
              <Route exact path="/support" element={<Support />} />
              <Route
                exact
                path="/customer-dashboard"
                element={<CustomerDashboard />}
              />
              <Route exact path="/website-cms" element={<WebsiteCMS />} />

              <Route
                exact
                path="/keystringsmaster/:action"
                element={<KeyStringsAction />}
              />
              <Route
                exact
                path="/bannermanagement/:action"
                element={<BannerManagementAction />}
              />
              <Route
                exact
                path="/bannermanagement"
                element={<BannerManagement />}
              />
              <Route
                exact
                path="/keystringaction"
                element={<KeyStringsAction />}
              />
              <Route
                exact
                path="/categorymaster"
                element={<CategoryMaster />}
              />
              <Route
                exact
                path="/categorymaster/:action"
                element={<CategoryMasterAction />}
              />
              <Route
                exact
                path="/subcategorymaster"
                element={<SubCategoryMaster />}
              />
              <Route
                exact
                path="/subcategorymaster/:action"
                element={<SubCategoryMasterAction />}
              />
              <Route
                exact
                path="/staticmediacontent/:action"
                element={<StaticMediaContentAction />}
              />
              <Route
                exact
                path="/staticmediacontent"
                element={<StaticMediaContent />}
              />
              <Route
                exact
                path="/timemaster/:action"
                element={<TimeMasterAction />}
              />
              <Route exact path="/imageupload" element={<ImageUpload />} />
              <Route
                exact
                path="/itemproperty/:action"
                element={<ItemPropertyAction />}
              />
              <Route
                exact
                path="/itemproperty"
                element={<ItemPropertyMaster />}
              />

              {/* **** Prashant Component ***** */}
              <Route
                exact
                path="/addonmaster/:action"
                element={<AddonAction />}
              />
              <Route exact path="/addonmaster" element={<AddonMaster />} />
              <Route
                exact
                path="/foodtypemaster"
                element={<FoodTypeMaster />}
              />
              <Route
                exact
                path="/foodtypemaster/:action"
                element={<FoodTypeAction />}
              />
              <Route exact path="/servingmaster" element={<ServingMaster />} />
              <Route
                exact
                path="/servingmaster/:action"
                element={<ServingAction />}
              />
              <Route
                exact
                path="/itemmaster/:action"
                element={<ItemAction />}
              />
              <Route exact path="/catering" element={<Catering />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            {window.location.pathname == "/" ? <></> : <Footer />}
          </>
        );
      } else if (
        JSON.parse(localStorage.getItem("LoginDetail"))?.fld_companytype == 2
      ) {
        if (JSON.parse(localStorage.getItem("LoginDetail"))?.fld_email == "Sales@globaltrendz.com") {
          return (
            <>
              {window.location.pathname == "/" ? <></> : <Navbar />}
              <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route
                  exact
                  path="/SalesDashboard"
                  element={<SalesDashboard />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              {window.location.pathname == "/" ? <></> : <Footer />}
            </>
          )
        } else {
          return (
            <>
              {window.location.pathname == "/" ? <></> : <Navbar />}
              <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/categories" element={<Menu />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route
                  exact
                  path="/categorymaster"
                  element={<CategoryMaster />}
                />
                <Route
                  exact
                  path="/categorymaster/:action"
                  element={<CategoryMasterAction />}
                />
                <Route
                  exact
                  path="/itemmasteroffline"
                  element={<ItemMasterOffline />}
                />
                <Route exact path="/imageupload" element={<ImageUpload />} />
                <Route exact path="/addonmaster" element={<AddonMaster />} />
                <Route
                  exact
                  path="/itemmasteroffline/:action"
                  element={<ItemAction />}
                />

                <Route path="*" element={<PageNotFound />} />
              </Routes>
              {window.location.pathname == "/" ? <></> : <Footer />}
            </>
          );
        }
      }
    }
  }
}

export default App;
