import { DatePicker } from 'antd';
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFilterSquare } from "react-icons/bs";
import { FaDownload } from 'react-icons/fa';
import { store } from "../../components/Context/store";
import RecentOrdersChart from "../../components/RecentOrdersChart/RecentOrdersChart";
import PostApiCall from "../../helper/Api";
import "./Orders.css";
const { RangePicker } = DatePicker;

function Orders() {
  const [customRange, setCustomRange] = useState(false)
  const {
    allRecentOrders,
    setAllRecentOrders,
    setSortType,
    searchBy,
    setSearchBy,
  } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") != null && (JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 1 ||
      JSON.parse(localStorage.getItem("LoginDetail")).fld_companytype == 3)) {
      PostApiCall.postRequest(
        {
          filter: "",
        },
        "GetOrdersListFiltered"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          if (obj.message.length != 0) {
            setAllRecentOrders(obj.message);

            Notiflix.Loading.remove();
          } else {
            console.obj(obj.message.length)
            setAllRecentOrders(obj.message);
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Records Exits");
          }
        })
      );
    }
  }, []);
  function updateOrdersDetails(filter, fromdate, todate) {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        filter: filter,
        fromDate: fromdate,
        toDate: todate
      },
      "GetOrdersListFiltered"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.message.length != 0) {
          setAllRecentOrders(obj.message);

          Notiflix.Loading.remove();
        } else {
          setAllRecentOrders(obj.message);
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  function onSearch(e) {
    setSearchBy(e.target.value);
  }
  const DATE_FORMAT = 'MM-DD-YYYY';
  const handleChange = (date, dateArray) => {
    // console.log(date)
    if (dateArray.length == 2) {
      updateOrdersDetails("CustomRange", dateArray[0], dateArray[1]);
    }
    // setSelectedDates(dateArray);
  };

  const csvdata = {
    reportcolumns: [
      {
        title: "Order Number",
        label: "Order Number",
        field: "fld_order_number",
        width: 150,
        dataIndex: "fld_order_number",
        key: "fld_order_number",
      },
      {
        title: "Order Date",
        label: "Order Date",
        field: "fld_order_date_org",
        width: 150,
        dataIndex: "fld_order_date_org",
        key: "fld_order_date_org"
      },
      {
        title: "Customer Name",
        label: "Customer Name",
        field: "customer_name",
        width: 400,
        dataIndex: "customer_name",
        key: "customer_name"
      },
      {
        title: "Customer Address",
        label: "Customer Address",
        field: "fld_address_1",
        width: 400,
        dataIndex: "fld_address_1",
        key: "fld_address_1"
      },
      {
        title: "Order Amount",
        label: "Order Amount",
        field: "Total_Inclusive_Tax",
        dataIndex: "Total_Inclusive_Tax",
        key: "Total_Inclusive_Tax"
      },
      {
        title: "Order Type",
        label: "Order Type",
        field: "fld_order_type",
        width: 150,
        dataIndex: "fld_order_type",
        key: "fld_order_type",
      }
    ],
    paymentcolumns: [
      {
        title: "Order Number",
        label: "Order Number",
        field: "fld_order_number",
        width: 150,
        dataIndex: "fld_order_number",
        key: "fld_order_number",
      },
      {
        title: "Order Date",
        label: "Order Date",
        field: "fld_order_date_org",
        width: 150,
        dataIndex: "fld_order_date_org",
        key: "fld_order_date_org"
      },
      ,
      {
        title: "Order Time",
        label: "Order Time",
        field: "fld_time",
        width: 150,
        dataIndex: "fld_time",
        key: "fld_time"
      },
      {
        title: "Customer Name",
        label: "Customer Name",
        field: "customer_name",
        width: 400,
        dataIndex: "customer_name",
        key: "customer_name"
      },
      {
        title: "Order Amount",
        label: "Order Amount",
        field: "Total_Inclusive_Tax",
        dataIndex: "Total_Inclusive_Tax",
        key: "Total_Inclusive_Tax"
      }
    ],
  };

  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid className="inner-page-height">
          <Row>
            <Col>
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Orders</h5>
                <p>List of Orders</p>
              </div>
            </Col>
            <Col lg={12} className="mt-4">
              <Card className="shadow border-light">
                <Card.Body>
                  <Card.Text>
                    <div className="row justify-content-lg-between align-items-lg-center">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <h5 className="fw-bold text-black">
                          Filter Data by Date
                        </h5>
                        <p className="text-black">Select Date Range</p>
                      </div>
                      <div className="tab-filter col-lg-9 ps-0">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="first"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("today");
                                }}
                              >
                                Today
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="second"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("tilldate");
                                }}
                              >
                                Till Date
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="third"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("yesterday");
                                }}
                              >
                                Yesterday
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="forth"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("last7days");
                                }}
                              >
                                Last 7 days
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="fifth"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("last30days");
                                }}
                              >
                                Last 30 days
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="sixth"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("thisweek");
                                }}
                              >
                                This Week
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="seventh"
                                onClick={() => {
                                  setCustomRange(false);
                                  updateOrdersDetails("thismonth");
                                }}
                              >
                                This Month
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="eighth"
                                onClick={() => {
                                  setCustomRange(true);
                                }}
                              >
                                Custom Date Range
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Tab.Container>
                      </div>
                    </div>
                  </Card.Text>
                  {customRange &&
                    <div className='text-end mt-3'>
                      <RangePicker
                        format={DATE_FORMAT}
                        onChange={handleChange}
                      />
                    </div>
                  }
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12} className="mt-4">
              <div className="shadow border-light order-inner-page">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <form class="form-inline d-flex w-75 me-2">
                    <input
                      class="form-control mr-sm-2"
                      type="search"
                      placeholder="Search by, Order No., Name, Date, Location, Pincode"
                      aria-label="Search"
                      onChange={onSearch}
                    />
                    <button
                      class="btn ms-2 search-bar-icon my-2 my-sm-0"
                      type="submit"
                    >
                      <AiOutlineSearch />
                    </button>
                  </form>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="white"
                      className="customers-filter"
                      id="dropdown-basic"
                    >
                      <BsFilterSquare />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Alphabetical Order");
                        }}
                      >
                        Alphabetical Order
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Newest");
                        }}
                      >
                        Newest
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Oldest");
                        }}
                      >
                        Oldest
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Highest Order Value");
                        }}
                      >
                        Highest Order Value
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Lowest Order Value");
                        }}
                      >
                        Lowest Order Value
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Cash on Delivery Orders");
                        }}
                      >
                        Cash on Delivery Orders
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Prepaid Orders");
                        }}
                      >
                        Prepaid Orders
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Take Away");
                        }}
                      >
                        Take Away
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSortType("Delivery");
                        }}
                      >
                        Delivery
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* Download Payment Details */}

                  <CSVLink
                    data={allRecentOrders}
                    filename="PaymentDetails.csv"
                    class="btn ms-2 search-bar-icon my-2 my-sm-0 download-report"
                    headers={csvdata.paymentcolumns}
                    target="_blank"
                  >
                    <FaDownload />  Payment Details

                  </CSVLink>
                  <CSVLink
                    data={allRecentOrders}
                    filename="OrdersReport.csv"
                    class="btn ms-2 search-bar-icon my-2 my-sm-0 download-report"
                    headers={csvdata.reportcolumns}
                    target="_blank"
                  >
                    <FaDownload /> Report
                  </CSVLink>
                </div>
                <RecentOrdersChart />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Orders;
