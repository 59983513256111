import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CSVLink } from "react-csv";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { store } from "../../components/Context/store";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import "./ItemMaster.css";

const ItemMaster = () => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { allItemMasterData, setAllItemMasterData } = useContext(store);
  const [allItemPropertyData, setAllItemPropertyData] = useState([]);
  const [allItemAddonData, setAllItemAddonData] = useState([]);
  const [allProperty, setAllProperty] = useState([]);
  const [allAddon, setAllAddon] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedAddon, setSelectedAddon] = useState("");
  const [selectedFoodType, setSelectedFoodType] = useState("");
  const [uom, setUom] = useState("");
  const [price, setPrice] = useState("");
  const [minCount, setMinCount] = useState("");
  const [maxCount, setMaxCount] = useState("");
  const [allFoodType, setAllFoodType] = useState([]);
  const [value, setValue] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [showAddonDetails, setShowAddonDetails] = useState(false);
  const [showAddAddon, setShowAddAddon] = useState(false);
  const [offCanvasHeader, setOffCanvasHeader] = useState("Property Details");
  const [addOnId, setAddOnId] = useState(0);
  const [propertyId, setPropertyId] = useState(0);

  const [maxAddons, setMaxAddon] = useState(0);
  const [maxProperty, setMaxProperty] = useState(0);

  const [filterRecord, setFilterRecord] = useState(null);
  const [data, setData] = useState(allItemMasterData);

  const [directAddOnEdit, setDirectAddOnEdit] = useState(true);

  const [offCanvasAddBtnLabel, setOffCanvasAddBtnLabel] =
    useState("Add Property");

  function GetItemDetails() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "getAllItemDetails"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllItemMasterData(obj.data);
          setData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  useEffect(() => {
    if (allItemMasterData.length <= 0) {
      GetItemDetails();
    }
  }, []);

  function getItemPropertyDetails() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_Item_Id=${selectedItemId}`,
        columns: ` * `,
      },
      "GetItemProperty"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (obj1.length != 0) {
            setAllItemPropertyData(obj1.data);
          } else {
            setAllItemPropertyData([]);
          }
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function getItemAddonDetails() {
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_Property_id=${selectedPropertyId} and fld_Item_Id=${selectedItemId}`,
        columns: "*",
      },
      "getItemAddOnMaster"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          setOffCanvasHeader("Addon Details");
          setShowAddonDetails(true);
          setShowAddProperty(false);
          setShowAddAddon(false);
          setOffCanvasAddBtnLabel("Add Addon");
          if (obj1.length != 0) {
            setAllItemAddonData(obj1.data);
          } else {
            setAllItemAddonData([]);
          }
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  const csvdata = {
    reportcolumns: [
      {
        title: "Category Name",
        label: "Category Name",
        field: "cat_name",
        width: 150,
        dataIndex: "cat_name",
        key: "cat_name"
      },
      {
        title: "Item Name",
        label: "Item Name",
        field: "fld_name",
        width: 150,
        dataIndex: "fld_name",
        key: "fld_name",
      },
      {
        title: "Description",
        label: "Description",
        field: "fld_description",
        dataIndex: "fld_description",
        key: "fld_description"
      },
      {
        title: "Selling Price",
        label: "Selling Price",
        field: "fld_discount_price",
        width: 400,
        dataIndex: "fld_discount_price",
        key: "fld_discount_price"
      },
      {
        title: "Show On Website",
        label: "Show On Website",
        field: "fld_enabled_flag",
        width: 400,
        dataIndex: "fld_enabled_flag",
        key: "fld_enabled_flag"
      }
    ]
  };
  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid inner-page-height">
          <div className="row form-container">
            <div className="d-flex justify-content-between align-items mb-4">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Item List</h5>
                <p>List of Items</p>
              </div>
              <div>
                <CSVLink
                  data={data}
                  filename="ItemList.csv"
                  headers={csvdata.reportcolumns}
                  target="_blank"
                >
                  <button
                    class="default-btn btn btn-white me-2 text white"
                  >
                    <FaDownload />  Items Detail
                  </button>
                </CSVLink>

                <button
                  type="button"
                  className="default-btn btn btn-white"
                  onClick={() => {
                    navigate("/itemmaster/add");
                  }}>
                  Add New Item
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-4">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Search by Name"
                aria-label="Search"
                onChange={(e) => {
                  const userInput = e.target.value;
                  setFilterRecord(userInput);

                  // Filter the data based on user input
                  const filteredData = allItemMasterData.filter((item) =>
                    item.fld_name
                      ?.toLowerCase()
                      .includes(userInput.toLowerCase())
                  );

                  setData(filteredData);
                }}
              />
            </div>
            {data.map((item, i) => {
              return (
                <div className="col-md-6 col-lg-3 px-3 mb-4 pb-2">
                  <Card className="shadow h-100 item-card">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <div className="card-content">
                        <img className="img-fluid" src={item.fld_image} />
                        <h4>{item.fld_name}</h4>
                        <b>{item.cat_name}</b>
                        <p>{item.fld_description}</p>
                      </div>

                      <div className="mt-3 border-top pt-2">
                        <b>Active : </b>
                        <span className={item.fld_enabled_flag}>
                          {item.fld_enabled_flag}
                        </span>
                        <div className="border-top pt-3 d-flex mt-2 justify-content-between align-items-center">
                          {item.fld_max_properties > 0 ? (
                            <button
                              type="button"
                              className="default-btn btn btn-white"
                              onClick={() => {

                                PostApiCall.postRequest(
                                  {
                                    WhereClause: `where fld_Item_Id=${item.fld_item_id}`,
                                    columns: ` *   `,
                                  },
                                  "GetItemProperty"
                                ).then((results1) =>
                                  results1.json().then((obj1) => {
                                    if (
                                      results1.status == 200 ||
                                      results1.status == 201
                                    ) {
                                      if (obj1.length != 0) {
                                        setAllItemPropertyData(obj1.data);
                                      } else {
                                        setAllItemPropertyData([]);
                                      }
                                      handleShow();
                                      setSelectedItemId(item.fld_item_id);
                                      setOffCanvasHeader("Update Property");
                                      Notiflix.Loading.dots();
                                      setMaxAddon(item.fld_max_addon);
                                      setMaxProperty(item.fld_max_properties);
                                      setOffCanvasAddBtnLabel("Add Addon");
                                      setDirectAddOnEdit(false);
                                      Notiflix.Loading.remove();
                                    } else {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.failure("Error Occurred");
                                    }
                                  })
                                );
                              }}>
                              Property
                            </button>
                          ) : ""} {item.fld_max_addon > 0 ? (
                            <button
                              type="button"
                              className="default-btn btn btn-white mx-2"
                              onClick={() => {
                                Notiflix.Loading.dots();
                                PostApiCall.postRequest(
                                  {
                                    WhereClause: `where FLD_Item_Id=${item.fld_item_id} and FLD_Property_Id = 0`,
                                    columns: "*",
                                  },
                                  "getItemAddOnMaster"
                                ).then((results1) =>
                                  results1.json().then((obj1) => {
                                    if (
                                      results1.status == 200 ||
                                      results1.status == 201
                                    ) {
                                      setOffCanvasHeader("Update Addon");
                                      setMaxAddon(item.fld_max_addon);
                                      setMaxProperty(item.fld_max_properties);
                                      setShowAddonDetails(true);
                                      setShowAddProperty(false);
                                      setShowAddAddon(false);
                                      setSelectedItemId(item.fld_item_id);
                                      setDirectAddOnEdit(true);
                                      setSelectedPropertyId(
                                        0
                                      );
                                      setOffCanvasAddBtnLabel("Add Addon");
                                      handleShow();
                                      if (obj1.length != 0) {
                                        setAllItemAddonData(obj1.data);
                                      } else {
                                        setAllItemAddonData([]);
                                      }
                                      Notiflix.Loading.remove();
                                    } else {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.failure("Error Occurred");
                                    }
                                  })
                                );
                              }}>
                              Addon
                            </button>
                          ) : (
                            ""
                          )}
                          <div className="ms-auto">
                            <Button
                              variant="primary"
                              className="btn kot-btn kot-btn-2 mx-2 p-2 "
                              onClick={() => {
                                navigate("/itemmaster/update", {
                                  state: item.fld_item_id,
                                });
                              }}>
                              <AiOutlineEdit />
                            </Button>
                            <Button
                              variant="primary"
                              className="btn kot-btn kot-btn-2 p-2 "
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to Delete",
                                  message:
                                    "Are you sure you want to delete this item?",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        PostApiCall.postRequest(
                                          {
                                            id: item.fld_item_id,
                                            deleteFrom: "ItemMaster",
                                          },
                                          "DeleteMaster"
                                        ).then((results) =>
                                          results.json().then((obj) => {
                                            if (
                                              results.status == 200 ||
                                              results.status == 201
                                            ) {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.success(
                                                "successfully deleted."
                                              );
                                              GetItemDetails();
                                            } else {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.failure(
                                                "Something went wrong, try again later."
                                              );
                                            }
                                          })
                                        );
                                      },
                                    },
                                    {
                                      label: "No",
                                    },
                                  ],
                                });
                              }}>
                              <RiDeleteBin6Line />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Offcanvas
        show={show}
        onHide={() => {
          handleClose();
          setShowAddProperty(false);
          setShowAddonDetails(false);
          setShowAddAddon(false);
          setOffCanvasHeader("Property Details");
        }}
        placement="end"
        className="item-property-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold">{offCanvasHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Property Card */}
          {showAddProperty == false &&
            showAddonDetails == false &&
            showAddAddon == false ? (
            allItemPropertyData.length != 0 ? (
              allItemPropertyData.map((itemProperty, i) => {
                return (
                  <Card className="shadow">
                    <Card.Body>
                      <div className="property-card-content position-relative">
                        <Button
                          variant="primary"
                          className="btn kot-btn kot-btn-2 p-2 property-card-edit-icon"
                          onClick={() => {
                            GetApiCall.getRequest("GetProperty").then(
                              (resultdes) =>
                                resultdes.json().then((obj) => {
                                  setPropertyId(itemProperty.FLD_ID);
                                  setAllProperty(obj.data);
                                  setShowAddProperty(true);
                                  setOffCanvasHeader("Update Property");
                                  setOffCanvasAddBtnLabel("Update Property");
                                  setUom(itemProperty.FLD_UOM);
                                  setSelectedProperty(
                                    itemProperty.FLD_ID +
                                    "-" +
                                    itemProperty.FLD_Property_Name
                                  );
                                  setPrice(itemProperty.FLD_Price);
                                  Notiflix.Loading.remove();
                                })
                            );
                          }}>
                          <AiFillEdit />
                        </Button>
                        <Button
                          variant="primary"
                          className="btn kot-btn kot-btn-2 p-2 property-card-left-delete-icon "
                          onClick={() => {
                            confirmAlert({
                              title: "Confirm to Remove",
                              message:
                                "Are you sure you want to remove this item?",
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: () => {
                                    PostApiCall.postRequest(
                                      {
                                        id: itemProperty.FLD_ID,
                                        status: "InActive",
                                        type: "property"
                                      },
                                      "removeItemAddOnPropertyMapping"
                                    ).then((results) =>
                                      results.json().then((obj) => {
                                        if (
                                          results.status == 200 ||
                                          results.status == 201
                                        ) {
                                          Notiflix.Loading.remove();
                                          Notiflix.Notify.success(
                                            "successfully removed."
                                          );
                                        } else {
                                          Notiflix.Loading.remove();
                                          Notiflix.Notify.failure(
                                            "Something went wrong, try again later."
                                          );
                                        }
                                      })
                                    );
                                  },
                                },
                                {
                                  label: "No",
                                },
                              ],
                            });
                          }}>
                          <RiDeleteBin6Line />
                        </Button>
                        <h5>{itemProperty.FLD_Property_Name}</h5>
                        <h5>{itemProperty.FLD_UOM}</h5>
                        <h5>{parseFloat(itemProperty.FLD_Price).toFixed(2)}</h5>
                        <span className={itemProperty.FLD_Status}>
                          <p className={itemProperty.FLD_Status}>{itemProperty.FLD_Status}</p>
                          {itemProperty.FLD_Status === 'InActive' ?
                            <p className={itemProperty.FLD_Status}>Edit the Property to activate.</p> : ""}
                        </span>
                        {maxAddons > 0 ? (
                          <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                            <div className="ms-auto">
                              <button
                                type="button"
                                className="default-btn btn btn-white"
                                onClick={() => {
                                  Notiflix.Loading.dots();
                                  PostApiCall.postRequest(
                                    {
                                      WhereClause: `where FLD_Property_id=${itemProperty.property_id} and FLD_Item_Id=${selectedItemId}`,
                                      columns: "*",
                                    },
                                    "getItemAddOnMaster"
                                  ).then((results1) =>
                                    results1.json().then((obj1) => {
                                      if (
                                        results1.status == 200 ||
                                        results1.status == 201
                                      ) {
                                        setOffCanvasHeader("Addon Details");
                                        setShowAddonDetails(true);
                                        setShowAddProperty(false);
                                        setShowAddAddon(false);
                                        setOffCanvasAddBtnLabel("Add Addon");
                                        setSelectedPropertyId(
                                          itemProperty.property_id
                                        );
                                        if (obj1.length != 0) {
                                          setAllItemAddonData(obj1.data);
                                        } else {
                                          setAllItemAddonData([]);
                                        }
                                        Notiflix.Loading.remove();
                                      } else {
                                        Notiflix.Loading.remove();
                                        Notiflix.Notify.failure(
                                          "Error Occurred"
                                        );
                                      }
                                    })
                                  );
                                }}>
                                View Addon
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                );
              })
            ) : (
              <>No Property For this Item</>
            )
          ) : (
            <></>
          )}
          {/* Addon Card */}
          {showAddProperty == false &&
            showAddonDetails == true &&
            showAddAddon == false ? (
            allItemAddonData.length != 0 ? (
              allItemAddonData.map((itemAddon, i) => {
                return (
                  <Card className="shadow mb-4">
                    <Card.Body>
                      <div className="property-card-content">
                        <Button
                          variant="primary"
                          className="btn kot-btn kot-btn-2 p-2 property-card-edit-icon"
                          onClick={() => {
                            GetApiCall.getRequest("GetAddon").then(
                              (resultdes) =>
                                resultdes.json().then((obj) => {
                                  if (obj.data.length != 0) {
                                    GetApiCall.getRequest(
                                      "getFoodTypeMaster"
                                    ).then((resultdes) =>
                                      resultdes.json().then((obj1) => {
                                        if (obj1.data.length != 0) {
                                          var allActiveFoodType = [];
                                          obj1.data.map((foodType) => {
                                            if (foodType.FLD_status = 'Active') {
                                              allActiveFoodType.push(foodType);
                                            }
                                          });

                                          setAddOnId(itemAddon.FLD_ID);
                                          setAllFoodType(allActiveFoodType);
                                          setAllAddon(obj.data);
                                          setShowAddProperty(false);
                                          setShowAddAddon(true);
                                          setShowAddonDetails(false);
                                          setOffCanvasHeader("Update Addon");
                                          setOffCanvasAddBtnLabel(
                                            "Update Addon"
                                          );
                                          setSelectedAddon(
                                            itemAddon.FLD_Addon_ID +
                                            "-" +
                                            itemAddon.FLD_Addon_Name
                                          );
                                          setMinCount(itemAddon.FLD_Min_Count);
                                          setMaxCount(itemAddon.FLD_Max_count);
                                          setSelectedFoodType(
                                            itemAddon.FLD_Food_type
                                          );
                                          setValue(itemAddon.FLD_Addon_Name);
                                          setAddonPrice(itemAddon.FLD_Amount);
                                          Notiflix.Loading.remove();
                                        } else {
                                          Notiflix.Loading.remove();
                                          Notiflix.Notify.failure(
                                            "No Records Exits"
                                          );
                                        }
                                      })
                                    );
                                    Notiflix.Loading.remove();
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure("No Records Exits");
                                  }
                                })
                            );
                          }}>
                          <AiFillEdit />
                        </Button>
                        <Button
                          variant="primary"
                          className="btn kot-btn kot-btn-2 p-2 property-card-delete-icon "
                          onClick={() => {
                            confirmAlert({
                              title: "Confirm to Remove",
                              message:
                                "Are you sure you want to remove this item?",
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: () => {
                                    PostApiCall.postRequest(
                                      {
                                        id: itemAddon.FLD_ID,
                                        status: "InActive",
                                        type: "addon"
                                      },
                                      "removeItemAddOnPropertyMapping"
                                    ).then((results) =>
                                      results.json().then((obj) => {
                                        if (
                                          results.status == 200 ||
                                          results.status == 201
                                        ) {
                                          Notiflix.Loading.remove();
                                          Notiflix.Notify.success(
                                            "successfully removed."
                                          );
                                        } else {
                                          Notiflix.Loading.remove();
                                          Notiflix.Notify.failure(
                                            "Something went wrong, try again later."
                                          );
                                        }
                                      })
                                    );
                                  },
                                },
                                {
                                  label: "No",
                                },
                              ],
                            });
                          }}>
                          <RiDeleteBin6Line />
                        </Button>
                        <h4 className="mb-0">{itemAddon.FLD_Addon_Name}</h4>
                        <p>{itemAddon.FLD_Addon_Group}</p>
                        <p>{parseFloat(itemAddon.FLD_Amount).toFixed(2)}</p>
                        <span className={itemAddon.FLD_Status}>
                          <p className={itemAddon.FLD_Status}>{itemAddon.FLD_Status}</p>
                        </span>

                      </div>
                    </Card.Body>
                  </Card>
                );
              })
            ) : (
              <>No Addon For this Item</>
            )
          ) : (
            <></>
          )}

          {showAddProperty == true &&
            showAddonDetails == false &&
            showAddAddon == false ? (
            <div>
              {/* <div className="form-container row ">
                <div className="col-md-12 shadow p-4"> */}
              {/* <div className="form-box align-items-center d-md-flex border-light mb-4">
                    <select
                      className="me-3 w-100"
                      value={selectedProperty}
                      onChange={(e) => {
                        setSelectedProperty(e.target.value);
                      }}>
                      Property
                      <option>Select Property</option>
                      {allProperty.map((property, i) => {
                        return (
                          <option
                            value={
                              property.FLD_ID + "-" + property.FLD_Property_Name
                            }>
                            {property.FLD_Property_Name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Property</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProperty}
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                  }}
                  label="Property">
                  {allProperty.map((property, i) => {
                    return (
                      <MenuItem
                        className="addon-option"
                        value={
                          property.FLD_ID + "-" + property.FLD_Property_Name
                        }>
                        {property.FLD_Property_Name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* <div className="form-box align-items-center d-md-flex border-light mb-4">
                    <label className="me-3">UOM</label>
                    <input
                      className="w-100"
                      type="text"
                      value={uom}
                      onChange={(e) => {
                        setUom(e.target.value);
                      }}
                    />
                  </div> */}
              <TextField
                id="outlined-basic"
                label="UOM"
                variant="outlined"
                className="mt-3 w-100"
                value={uom}
                onChange={(e) => {
                  setUom(e.target.value);
                }}
              />
              {/* <div className="form-box align-items-center d-md-flex border-light mb-4">
                    <label className="me-3">Price</label>
                    <input
                      className="w-100"
                      type="text"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div> */}
              <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                className="mt-3 w-100"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
              <div className="text-end">
                <button
                  type="button"
                  className="default-btn btn -btn-white border-0 mt-3"
                  onClick={() => {
                    Notiflix.Loading.dots();
                    PostApiCall.postRequest(
                      {
                        id: propertyId,
                        itemId: selectedItemId,
                        uom: uom,
                        propertyId: selectedProperty.split("-")[0],
                        propertyValue: selectedProperty.split("-")[1],
                        enabledFlag: "",
                        createdOn: new Date(),
                        createdBy: 1,
                        updatedOn: new Date(),
                        updatedBy: 1,
                        price: price,
                      },
                      "updateitempropertymapping"
                    ).then((results1) =>
                      results1.json().then((obj1) => {
                        if (results1.status == 200 || results1.status == 201) {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.success(
                            "Item Property added successfully"
                          );
                          setUom("");
                          setPrice("");
                          setShowAddProperty(false);
                          setOffCanvasHeader("Property Details");
                          getItemPropertyDetails();
                        } else {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("Error Occurred");
                        }
                      })
                    );
                  }}>
                  Submit
                </button>
              </div>
              {/* </div>
              </div> */}
            </div>
          ) : (
            <></>
          )}

          {showAddProperty == false &&
            showAddonDetails == false &&
            showAddAddon == true ? (
            <>
              {/* // <div className="container py-5 mt-0 pt-0"> */}
              {/* //   <div className="form-container row "> */}
              {/* //     <div className="col-md-12 shadow p-4"> */}
              {/* <div className="form-box align-items-center d-md-flex border-light mb-4">
                <select
                  className="me-3 w-100"
                  value={selectedAddon}
                  onChange={(e) => {
                    setSelectedAddon(e.target.value);
                  }}>
                  Addon
                  <option>Select Addon</option>
                  {allAddon.map((addon, i) => {
                    return (
                      <option
                        value={addon.FLD_Addon_ID + "-" + addon.FLD_Addon_Name}>
                        {addon.FLD_Addon_Name}
                      </option>
                    );
                  })}
                </select> */}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Addon</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAddon}
                  onChange={(e) => {
                    setSelectedAddon(e.target.value);
                  }}
                  label="Addon">
                  {allAddon.map((addon, i) => {
                    return (
                      <MenuItem
                        className="addon-option"
                        value={addon.FLD_Addon_ID + "-" + addon.FLD_Addon_Name}>
                        {" "}
                        <span className="addon-option">
                          <img src={addon.FLD_Addon_Image} className="me-2" />
                          {addon.FLD_Addon_Name}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* </div> */}
              {/* <div className="form-box align-items-center d-md-flex border-light mb-4"> */}
              {/* <label className="me-3">Min Count</label>
                <input
                  className="w-100"
                  type="text"
                  value={minCount}
                  onChange={(e) => {
                    setMinCount(e.target.value);
                  }}
                /> */}
              <TextField
                id="outlined-basic"
                label="Min Count"
                variant="outlined"
                className="mt-3 w-100"
                value={minCount}
                onChange={(e) => {
                  setMinCount(e.target.value);
                }}
              />
              {/* </div>
              <div className="form-box align-items-center d-md-flex border-light mb-4"> */}
              {/* <label className="me-3">Max Count</label>
                <input
                  className="w-100"
                  type="text"
                  value={maxCount}
                  onChange={(e) => {
                    setMaxCount(e.target.value);
                  }}
                /> */}
              <TextField
                id="outlined-basic"
                label="Max Count"
                variant="outlined"
                className="my-3 w-100"
                value={maxCount}
                onChange={(e) => {
                  setMaxCount(e.target.value);
                }}
              />
              {/* </div>
              <div className="form-box align-items-center d-md-flex border-light mb-4"> */}
              {/* <select
                className="me-3 w-100"
                value={selectedFoodType}
                onChange={(e) => {
                  setSelectedFoodType(e.target.value);
                }}>
                Food Type
                <option>Select Food Type</option>
                {allFoodType.map((foodType, i) => {
                  return (
                    <option
                      value={foodType.fld_id + "-" + foodType.fld_food_type}>
                      {foodType.fld_food_type}
                    </option>
                  );
                })}
              </select> */}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Food Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFoodType}
                  onChange={(e) => {
                    setSelectedFoodType(e.target.value);
                  }}
                  label="Food Type">
                  {allFoodType.map((foodType, i) => {
                    return (
                      <MenuItem
                        value={foodType.fld_food_type}>
                        {foodType.fld_food_type}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* </div>
              <div className="form-box align-items-center d-md-flex border-light mb-4"> */}
              {/* <label className="me-3">Value</label>
                <input
                  className="w-100"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                /> */}

              <TextField
                id="outlined-basic"
                label="Value"
                variant="outlined"
                className="mt-3 w-100"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
              {/* </div> */}
              <div className="form-box align-items-center d-md-flex border-light mb-4">
                {/* <label className="me-3">Price</label>
                <input
                  className="w-100"
                  type="text"
                  value={addonPrice}
                  onChange={(e) => {
                    setAddonPrice(e.target.value);
                  }}
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  className="mt-3 w-100"
                  value={addonPrice}
                  onChange={(e) => {
                    setAddonPrice(e.target.value);
                  }}
                />
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="default-btn btn-white btn ms-auto"
                  onClick={() => {
                    Notiflix.Loading.dots();
                    PostApiCall.postRequest(
                      {
                        id: addOnId,
                        addOnId: selectedAddon.split("-")[0],
                        propertyId: selectedPropertyId,
                        itemId: selectedItemId,
                        minCount: minCount,
                        maxCount: maxCount,
                        foodType: selectedFoodType,
                        value: value,
                        amount: addonPrice,
                      },
                      "updateItemAddOnMapping"
                    ).then((results1) =>
                      results1.json().then((obj1) => {
                        if (results1.status == 200 || results1.status == 201) {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.success(
                            "Item Addon added successfully"
                          );
                          setMinCount("");
                          setMaxCount("");
                          setAllFoodType([]);
                          setSelectedFoodType("");
                          setValue("");
                          setAddonPrice("");
                          setShowAddAddon(false);
                          setOffCanvasHeader("Addon Details");
                          getItemAddonDetails();
                        } else {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("Error Occurred");
                        }
                      })
                    );
                  }}>
                  Submit
                </button>
              </div>
              {/* // </div> */}
              {/* // </div> */}
              {/* // </div> */}
            </>
          ) : (
            <></>
          )}
        </Offcanvas.Body>
        <div className="mt-3 d-flex justify-content-between item-offcanvas-add-btn">
          <Button
            type="button"
            className="default-btn btn btn-white border-0"
            style={{
              display: offCanvasHeader == "Property Details" ? "none" : "block",
            }}
            onClick={() => {
              if (offCanvasHeader == "Addon Details") {
                if (directAddOnEdit) {
                  handleClose();
                } else {
                  setOffCanvasHeader("Property Details");
                  setOffCanvasAddBtnLabel("Add Property");
                  setShowAddonDetails(false);
                  setShowAddProperty(false);
                  getItemPropertyDetails();
                }
              } else if (
                offCanvasHeader == "Add Addon" ||
                offCanvasHeader == "Update Addon"
              ) {
                if (directAddOnEdit && offCanvasHeader == "Add Addon") {
                  handleClose();
                } else {
                  setOffCanvasHeader("Addon Details");
                  setOffCanvasAddBtnLabel("Add Addon");
                  setShowAddonDetails(true);
                  setShowAddAddon(false);
                  setShowAddProperty(false);
                  getItemAddonDetails();
                }
              } else if (
                offCanvasHeader == "Add Property" ||
                offCanvasHeader == "Update Property"
              ) {
                setOffCanvasHeader("Property Details");
                setOffCanvasAddBtnLabel("Add Property");
                setShowAddonDetails(false);
                setShowAddProperty(false);
                getItemPropertyDetails();
              }
            }}>
            Back
          </Button>
          <Button
            type="button"
            className="default-btn ms-auto border-0"
            onClick={() => {
              if (offCanvasAddBtnLabel == "Add Property") {
                setShowAddProperty(true);
                setOffCanvasHeader("Add Property");
                Notiflix.Loading.dots();
                GetApiCall.getRequest("GetProperty").then((resultdes) =>
                  resultdes.json().then((obj) => {
                    if (obj.data.length != 0) {
                      setAllProperty(obj.data);
                      Notiflix.Loading.remove();
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("No Records Exits");
                    }
                  })
                );
              } else {
                setShowAddProperty(false);
                setShowAddAddon(true);
                setShowAddonDetails(false);
                setOffCanvasHeader("Add Addon");
                Notiflix.Loading.dots();
                GetApiCall.getRequest("GetAddon").then((resultdes) =>
                  resultdes.json().then((obj) => {
                    if (obj.data.length != 0) {
                      GetApiCall.getRequest("getFoodTypeMaster").then(
                        (resultdes) =>
                          resultdes.json().then((obj1) => {
                            if (obj1.data.length != 0) {
                              var allActiveFoodType = [];
                              obj1.data.map((foodType) => {
                                if (foodType.fld_status = 'Active') {
                                  allActiveFoodType.push(foodType);
                                }
                              });

                              setAllFoodType(allActiveFoodType);
                              setAllAddon(obj.data);
                              Notiflix.Loading.remove();
                            } else {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure("No Records Exits");
                            }
                          })
                      );
                      Notiflix.Loading.remove();
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("No Records Exits");
                    }
                  })
                );
              }
            }}>
            {offCanvasAddBtnLabel}
          </Button>
        </div>
      </Offcanvas>


    </>
  );
};

export default ItemMaster;
