import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import "./AddonMaster.css";
import uploadImage from '../../assets/images/upload-pang.png';
import noImage from "../../assets/images/no-image.png";

const AddonMaster = () => {
  let navigate = useNavigate();
  const [allAddonData, setAllAddonData] = useState([]);
  const [filterRecord, setFilterRecord] = useState(null);
  const [data, setData] = useState(allAddonData);
  const [sliderHeader, setSliderHeader] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function GetAddonGroup() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetAddonData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          const AddonGroupData = [
            ...new Map(
              obj.data.map((item) => [item["FLD_Addon_Group"], item])
            ).values(),
          ];

          setAllAddonGroup(AddonGroupData);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  const handleShow = (headerTitle) => {
    setShow(true);
    setSliderHeader(headerTitle)
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }

  function GetAddon() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetAddonData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllAddonData(obj.data);
          setData(obj.data)
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }


  // add on master

  const [allCategoryId, setAllCategoryId] = useState([]);
  const [addOnId, setAddOnId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [originalImage, setOriginalImage] = useState([]);
  const [outputFileName, setOutputFileName] = useState(null);
  const [addonName, setAddonName] = useState("");
  const [addonGroup, setAddonGroup] = useState("");
  const [enabled, setEnabled] = useState("Yes");
  const [previewUrl, setPreviewUrl] = useState("");
  const [ImageApiUrl, setImageApiUrl] = useState(
    "http://api.grubdigest.com/api-grub/AddImage"
  );
  const [allAddonGroup, setAllAddonGroup] = useState([]);
  const [selectedAddonGroup, setSelectedAddonGroup] = useState("");
  const [isAddNew, setIsAddNew] = useState(false);

  const ImgUpload = ({ onChange, src }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload fas w-100 d-flex justify-content-center"
    >
      <div className="img-wrap img-upload">
        <img for="photo-upload" src={src} style={{ borderRadius: "5%" }} />
      </div>
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        onChange={onChange}
      />
    </label>
  );
  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
  };

  function OpenAddOnMaster(addOnId) {
    setAddOnId(addOnId)
    if (addOnId != null) {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_Addon_ID=${addOnId}`,
        },
        "GetAddonDataById"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            GetApiCall.getRequest("GetCategoryId").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  setAllCategoryId(obj.data);
                  Notiflix.Loading.remove();
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
            setSelectedCategoryId(obj1.data[0].FLD_Cat_Id);
            setAddonName(obj1.data[0].FLD_Addon_Name);
            setSelectedAddonGroup(obj1.data[0].FLD_Addon_Group);
            setEnabled(obj1.data[0].FLD_Enabled_Flag);
            setPreviewUrl(obj1.data[0].FLD_Addon_Image == "" ? noImage : obj1.data[0].FLD_Addon_Image)
            handleShow("Add New Addon")
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    } else {
      handleShow("Add New Addon")
    }
  }

  function onSaveAddon() {
    if (outputFileName != null) {
      Notiflix.Loading.dots();
      var randomnumber = Math.floor(100000 + Math.random() * 900000);
      var fileName = outputFileName.split(".").slice(0, -1).join(".") + ".webp";
      const form = new FormData();
      form.append("file", originalImage);
      form.append("foldername", "addon");
      form.append("schema", JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
        ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
        : "");
      form.append("filename", randomnumber + "-" + outputFileName);
      let response;
      response = fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((res) => {
        res.json().then((res1) => {

          PostApiCall.postRequest(
            {
              addOnId: addOnId,
              catId: selectedCategoryId,
              addOnName: addonName,
              addOnGroup: selectedAddonGroup,
              addonImage: res1.Message,
              enabledFlag: enabled,
              createdOn: new Date(),
              createdBy: 1,
              updatedOn: new Date(),
              updatedBy: 1,
            },
            "updatedaddon"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {

                GetAddon();
                handleClose()
                Notiflix.Loading.remove();
                Notiflix.Notify.success("Addon added successfully");

              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
        })
      });
    } else {
      PostApiCall.postRequest(
        {
          addOnId: addOnId,
          catId: selectedCategoryId,
          addOnName: addonName,
          addOnGroup: selectedAddonGroup,
          addonImage: previewUrl,
          enabledFlag: enabled,
          createdOn: new Date(),
          createdBy: 1,
          updatedOn: new Date(),
          updatedBy: 1,
        },
        "updatedaddon"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {

            GetAddon();
            handleClose()
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Addon added successfully");

          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Error Occurred");
          }
        })
      );
    }




  }


  // end add on master

  useEffect(() => {
    GetAddon();
    GetAddonGroup();
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="kot-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">{sliderHeader}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="">
            <div className="container-fluid">
              <div className="row form-container">

                <div className="col-12 pe-4">
                  <div className="row">
                    <div className="col-12">
                      <ImgUpload onChange={photoUpload} src={previewUrl != "" && previewUrl != undefined ? previewUrl : uploadImage} />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        {/* <div className="col-12 mt-3">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedCategoryId}
                              onChange={(e) => {
                                setSelectedCategoryId(e.target.value);
                              }}
                              label="Category"
                            >
                              {allCategoryId.map((category, i) => {
                                return (
                                  <MenuItem value={category.FLD_Category_Id}>
                                    {category.FLD_Name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div> */}

                        <div className="col-12 mt-3">
                          {/* <label className="me-3">Addon Name</label>
                    <input
                      className="w-100"
                      type="text"
                      value={addonName}
                      onChange={(e) => {
                        setAddonName(e.target.value);
                      }}
                    /> */}

                          <TextField
                            id="outlined-basic"
                            label="Addon Name"
                            variant="outlined"
                            className="w-100"
                            value={addonName}
                            onChange={(e) => {
                              setAddonName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          {/* <label className="me-3">Addon Group</label>
                      <input
                        className="w-100"
                        type="text"
                        value={addonGroup}
                        onChange={(e) => {
                          setAddonGroup(e.target.value);
                        }}
                      /> */}

                          <FormControl
                            fullWidth
                            style={{ display: isAddNew == true ? "none" : "block" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Addon Group
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="w-100"
                              value={selectedAddonGroup}
                              onChange={(e) => {
                                if (e.target.value == "Add New") {
                                  setIsAddNew(true);
                                  setSelectedAddonGroup("");
                                  OpenAddOnMaster(null)

                                }
                                setSelectedAddonGroup(e.target.value);
                              }}
                              label="Addon Group"
                            >
                              {allAddonGroup.map((addon, i) => {
                                return (
                                  <MenuItem value={addon.FLD_Addon_Group}>
                                    {addon.FLD_Addon_Group}
                                  </MenuItem>
                                );
                              })}
                              <MenuItem
                                value="Add New"
                                className="bg-secondary text-white"
                              >
                                Add New
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Addon Group"
                            variant="outlined"
                            className="w-100"
                            style={{ display: isAddNew == true ? "block" : "none" }}
                            value={selectedAddonGroup}
                            onChange={(e) => {
                              setSelectedAddonGroup(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            className="text-dark me-2"
                          >
                            Active
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Yes"
                              control={
                                <Radio
                                  name="contains-alcohol"
                                  checked={enabled == "Yes" ? true : false}
                                  onChange={() => {
                                    setEnabled("Yes");
                                  }}
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={
                                <Radio
                                  name="contains-alcohol"
                                  checked={enabled == "No" ? true : false}
                                  onChange={() => {
                                    setEnabled("No");
                                  }}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="default-btn btn-white border-0 btn ms-auto"
                      onClick={() => {
                        onSaveAddon();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <section className="pb-lg-5 pt-4 margin-left">
        <div className="container-fluid inner-page-height">
          <div className="row form-container">
            <div className="mb-3 d-flex justify-content-between pb-3 mb-2a">
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Addon List</h5>

              </div>
              <button
                type="button"
                className="default-btn btn btn-white"
                onClick={() => {
                  setSelectedCategoryId(null);
                  setAddonName(null);
                  setSelectedAddonGroup(null);
                  setEnabled(null);
                  setAddOnId(null)
                  handleShow("Add New Addon")
                }}>
                Add New Addon
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Search by Name"
                aria-label="Search"
                onChange={(e) => {
                  const userInput = e.target.value;
                  setFilterRecord(userInput);

                  // Filter the data based on user input
                  const filteredData = allAddonData.filter(item =>
                    item.FLD_Addon_Name?.toLowerCase().includes(userInput.toLowerCase())
                  );

                  setData(filteredData);
                }}
              />
            </div>

            {data.map((addon, i) => {
              return (
                <div className="col-md-6 col-lg-3 px-3 mb-5">
                  <div className="default-card addon-card shadow border-light">
                    <div className="card-content category-card">
                      <div className="d-flex align-item-center justify-content-center">
                        <img
                          className="img-fluid"
                          src={addon.FLD_Addon_Image == "" ? noImage : addon.FLD_Addon_Image}
                          alt=""
                        ></img>
                      </div>
                      <h4>{addon.FLD_Addon_Name}</h4>
                      <h4>{addon.FLD_Addon_Group}</h4>
                      <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                        <span className={addon.FLD_Enabled_Flag}>
                          {addon.FLD_Enabled_Flag}
                        </span>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2 me-2"
                            onClick={() => {
                              OpenAddOnMaster(addon.FLD_Addon_ID)
                            }}>
                            <AiOutlineEdit />
                          </button>
                          <button
                            type="button"
                            className="btn kot-btn kot-btn-2 p-2"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete this addon?",

                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      Notiflix.Loading.dots();
                                      PostApiCall.postRequest(
                                        {
                                          id: addon.FLD_Addon_ID,
                                          deleteFrom: "AddonMaster",
                                        },
                                        "DeleteMaster"
                                      ).then((results1) =>
                                        results1.json().then((obj1) => {
                                          if (
                                            results1.status == 200 ||
                                            results1.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "Addon deleted successfully"
                                            );
                                            GetAddon();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Error Occurred"
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default AddonMaster;
