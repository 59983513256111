 var urlString = "https://api.grubdigest.com/api-grub/";
// var urlString = "https://test.globaltrendz.online/moi-api/";
//var urlString = "http://localhost:8033/api-grub/";
var schema = "grubdigest_master";
if (localStorage.getItem("LoginDetail") != null) {
  schema = JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema;
}

var PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("access"),
        schema: schema,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        // //
        return response;
      })
      .catch((error) => {
        //
        return error;
      });
  },
};

export default PostApiCall;
