import React, { useState, useCallback } from "react";
import ShowImage from "./DropBoxUploader/ShowImage";
import DropBoxUploader from "./DropBoxUploader/DropBoxUploader";
import "./ImageUpload.css";

const ImageUpload = () => {
  const [images, SetImages] = useState([]);
  const [imageListHide, setImageListHide] = useState(false);

  let onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        SetImages((prevState) => [
          ...prevState,
          {
            id: index,
            src: e.target.result,
            name: file.name,
            file: file,
          },
        ]);
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  return (
    <div className="image-uploader">
      <DropBoxUploader onDrop={onDrop} imageListHide={imageListHide} />
      <ShowImage images={images} />
    </div>
  );
};

export default ImageUpload;
