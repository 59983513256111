import { Space, TimePicker } from "antd";
import "cropperjs/dist/cropper.css";
import { EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import Notiflix from "notiflix";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, FloatingLabel, Form, Modal, OverlayTrigger, ProgressBar, Row, Tooltip, } from "react-bootstrap";
import Cropper from "react-cropper";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneVolume, FaPinterestP, FaWhatsapp, } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import "react-multi-carousel/lib/styles.css";
import { useNavigate, useParams } from "react-router";
import "react-tabs/style/react-tabs.css";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import PostApiCall from "../../helper/Api";
import GetApiCall from "../../helper/GETAPI";
import "./Profile.css";
import ThankyouIcon from "../../assets/images/thankyou.png";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { IoClose } from "react-icons/io5";
import CateringIcon from "../../assets/images/catering.png";
import LogoIcon from "../../assets/images/restaurant_type/logo.png";
import GalleryIcon from "../../assets/images/restaurant_type/photo-gallery.png";
import { RiInformationLine } from "react-icons/ri";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { MdOutlineCancel } from "react-icons/md";
function Profile() {
  let navigate = useNavigate();
  let params = useParams();
  let schema = JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema != null
    ? JSON.parse(localStorage.getItem("LoginDetail")).fld_dbschema
    : ""
  let googleApiKey = "AIzaSyA4UpIpBjkSzH8T280zKffdTVdnt170Ke4";
  let restaurantId = JSON.parse(
    localStorage.getItem("LoginDetail")
  ).fld_client_id;
  let modifiedData = [];
  let menuType = [];
  const [ImageApiUrl, setImageApiUrl] = useState(
    "https://api.grubdigest.com/api-grub/AddImage"
  );
  const [imageurl, setImageurl] = useState("https://grubdigest.com/images/");
  const [restaurantFacilities, setRestaurantFacilities] = useState([]);
  const [socialMediaInput, setSocialMediaInput] = useState("");
  const [croppedImageType, setCroppedImageType] = useState("");
  const [cropperheight, setCropperheight] = useState(1080);
  const [cropperwidth, setCropperwidth] = useState(1080);
  const [cropData, setCropData] = useState("#");


  const [uploadProgress, setUploadProgress] = useState({});
  //restaurant details
  const [restaurantName, setRestaurantName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [image, setImage] = useState(null);
  const [croppedImageName, setCroppedImageName] = useState(null);
  const [croppedData, setCroppedData] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = React.createRef();
  const [imageCropperModal, setImageCropperModal] = useState(false);

  const [cuisines, setCuisines] = useState([]);
  const [workingHours, setWorkingHours] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [contact1, setContact1] = useState("");
  const [contact2, setContact2] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [directionButton, setDirectionButton] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [ogurl, setOgurl] = useState("");
  const [ogImage, setOgImage] = useState("");
  const [canonicaltag, setCanonicaltag] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [restaurantLogo, setRestaurantLogo] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [instaURL, setInstaURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [pintrustURL, setPintrustURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");

  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [restaurantNumber, setRestaurantNumber] = useState("");

  const [foodMenuImage, setFoodMenuImage] = useState("");
  const [barMenuImage, setBarMenuImage] = useState("");
  const [restaurantImages, setRestaurantImages] = useState([]);
  const [imageType, setImageType] = useState([]);
  // const [menuType, setMenuType] = useState([]);
  const [menuTypeName, setMenuTypeName] = useState([]);
  const [menuTypeId, setMenuTypeId] = useState([]);
  const [cuisineSuggestion, setCuisineSuggestion] = useState([]);
  const [menu, setMenu] = useState([]);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [clientId, setClientId] = useState(0);
  const [imageId, setImageId] = useState(0);
  const [selectedImageId, setSelectedImageId] = useState(0);
  const [menuModal, setMenuModal] = useState(false);
  const [gallaryModal, setGallaryModal] = useState(false);
  const [mondayOpeningTime, setMondayOpeningTime] = useState(0);
  const [mondayClosingTime, setMondayClosingTime] = useState(0);
  const [mondayClosed, setMondayClosed] = useState(false);
  const [tuesdayOpeningTime, setTuesdayOpeningTime] = useState(0);
  const [tuesdayClosingTime, setTuesdayClosingTime] = useState(0);
  const [tuesdayClosed, setTuesdayClosed] = useState(false);
  const [wednesdayOpeningTime, setWednesdayOpeningTime] = useState(0);
  const [wednesdayClosingTime, setWednesdayClosingTime] = useState(0);
  const [wednesdayClosed, setWednesdayClosed] = useState(false);
  const [thrusdayOpeningTime, setThrusdayOpeningTime] = useState(0);
  const [thrusdayClosingTime, setThrusdayClosingTime] = useState(0);
  const [thrusdayClosed, setThrusdayClosed] = useState(false);
  const [fridayOpeningTime, setFridayOpeningTime] = useState(0);
  const [fridayClosingTime, setFridayClosingTime] = useState(0);
  const [fridayClosed, setFridayClosed] = useState(false);
  const [saturdayOpeningTime, setSaturdayOpeningTime] = useState(0);
  const [saturdayClosingTime, setSaturdayClosingTime] = useState(0);
  const [saturdayClosed, setSaturdayClosed] = useState(false);
  const [sundayOpeningTime, setSundayOpeningTime] = useState(0);
  const [sundayClosingTime, setSundayClosingTime] = useState(0);
  const [sundayClosed, setSundayClosed] = useState(false);
  const [typeCards, setTypeCards] = useState([]);
  const [finaltypeCards, setFinaltypeCards] = useState([]);
  const [cuisinesId, setCuisinesId] = useState([]);
  const [geolocation, setGeolocation] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [receiveUpdate, setReceiveUpdate] = useState(false);
  const [isCatering, setIsCatering] = useState(true);
  const [averageCost, setAverageCost] = useState(0);
  const [restaurantType, setRestaurantType] = useState([]);
  const [resTypeList, setResTypeList] = useState([]);
  const [gst, setGst] = useState("");
  const [fssai, setFssai] = useState("");
  const [thankYouModal, setThankYouModal] = useState(false)
  const handleCloseGallaryModal = () => {
    setGallaryModal(false);
    GetImageRestaurantType();
  };
  const handleCloseMenuModal = () => {
    setMenuModal(false);
  };
  const handleCloseImageCropperModal = () => {
    setImageCropperModal(false);
  };
  const inputRef = useRef(null);
  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      setCroppedImageName(file.name);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setImageCropperModal(true)
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      setImage(cropper.Image);
      setCropData(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setCroppedImage(blob);
      });
    }
  };

  const [restaurantTimings, setRestaurantTimings] = useState({});
  const ImgUpload = ({ onChange, src, id }) => (
    <label
      htmlFor="photo-upload"
      className="custom-file-upload border-0 w-100 p-0">
      <div className="upload-image rounded position-relative">
        <img
          alt=""
          htmlFor="photo-upload"
          src={src}
          style={{ borderRadius: "5%" }}
        />
        <div className="restuarant-docs-p">
          {src ? (
            <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon">
              <img src={src} className="img-fluid" />
            </p>
          ) : (
            <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon">
              <img src={LogoIcon} className="img-fluid" />
            </p>
          )}
          {/* <p>Click here to upload logo</p> */}

          <div className="add-restaurant-upload-btn">
            <Button
              variant="danger"
              className="crop-btn text-capitalize position-relative">
              {src
                ? "Replace Restaurant Logo"
                : "Upload Restaurant Logo "}
              <input
                accept="image/*"
                id="photo-upload"
                className="w-100 h-100 opacity-0"
                type="file"
                onChange={onChange}
              />
            </Button>
          </div>
        </div>
      </div>
    </label>
  );

  useEffect(() => {
    GetApiCall.getRequest("getRestaurantFacilities").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setRestaurantFacilities(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
    GetApiCall.getRequest("getrestauranttype").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setResTypeList(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
    getCuisine();
  }, []);

  useEffect(() => {
    getRestaurantDetails();
  }, []);

  const getRestaurantDetails = () => {
    Notiflix.Loading.dots("Getting Restaurant Data.");
    GetImageRestaurantType();
    GetApiCall.getRequest("getCountry").then((results) =>
      results?.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCountries(obj.message);
        }
      })
    );
    PostApiCall.postRequest(
      {
        restaurantId: restaurantId,
      },
      "getRestaurantMasterFilter"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setRestaurantName(obj1.message[0].fld_restaurantname);
            setKeyWords(obj1.message[0].fld_keyword);
            setMetaTitle(obj1.message[0].fld_meta_title);
            setMetaDescription(obj1.message[0].fld_meta_description);
            setCanonicaltag(obj1.message[0].fld_canonical);
            setOgImage(obj1.message[0].fld_og_Image);
            setOgurl(obj1.message[0].fld_og_url);
            setRestaurantName(obj1.message[0].fld_restaurantname);
            setEmail(obj1.message[0].fld_email);
            setGeolocation(obj1.message[0].fld_geo_location);
            setAddress1(obj1.message[0].fld_address_1);
            setAddress2(obj1.message[0].fld_address_2);
            setLandmark(obj1.message[0].fld_landmark);
            setPincode(obj1.message[0].fld_pincode);
            setCompanyName(obj1.message[0].fld_companyname);
            setContact1(obj1.message[0].fld_mobilenumber);
            setContact2(obj1.message[0].fld_mobilenumber_2);
            setWebsite(obj1.message[0].fld_website);
            setFacebookURL(obj1.message[0].fld_facebook);
            setInstaURL(obj1.message[0].fld_instagram);
            setYoutubeURL(obj1.message[0].fld_youtube);
            setPintrustURL(obj1.message[0].fld_pinterest);
            setLinkedinURL(obj1.message[0].fld_linkedin);
            setWhatsappNumber(obj1.message[0].fld_whatsappnumber);
            setRestaurantNumber(obj1.message[0].fld_phonenumber);
            setAverageCost(obj1.message[0].fld_min_amount);
            setRestaurantLogo(obj1.message[0].fld_image);
            setIsCatering(obj1.message[0].fld_is_catering);
            setReceiveUpdate(obj1.message[0].fld_receive_updates);
            setGst(obj1.message[0]?.fld_gst);
            setFssai(obj1.message[0]?.fld_fssai_number)
            setCuisinesId(
              obj1.message[0].fld_cuisines != null
                ? obj1.message[0].fld_cuisines.split(",")
                : []
            );
            setFacilities(
              obj1.message[0].fld_facilities != null
                ? obj1.message[0].fld_facilities.split(",")
                : []
            );
            setRestaurantType(
              obj1.message[0].fld_restaurant_type != null
                ? obj1.message[0].fld_restaurant_type.split(",")
                : []
            );
            setDirectionButton(obj1.message[0].fld_location_link);
            setCountry(obj1.message[0].fld_country);
            setState(obj1.message[0].fld_state);
            setCity(obj1.message[0].fld_city);
            if (obj1.message[0].fld_country != null) {
              PostApiCall.postRequest(
                {
                  whereClause: ` where Country_Id = ${obj1.message[0].fld_country}`,
                },
                "getCountryById"
              ).then((results) =>
                results?.json().then((obj2) => {
                  if (results.status == 200 || results.status == 201) {
                    getStates(obj1.message[0].fld_country);
                    getCities(obj1.message[0].fld_state);
                  }
                })
              );
            }
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const handleSubmit = (event) => { };

  const [showReview, setShowReview] = useState(false);
  const handleClose = () => setShowReview(false);
  const handleShow = () => setShowReview((s) => !s);

  useEffect(() => {
    GetRestaurantMenuType();
  }, [menuModal]);

  const getCuisine = () => {
    GetApiCall.getRequest("getCuisines").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length > 0) {
            setCuisineSuggestion(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const GetRestaurantMenuType = () => {
    GetApiCall.getRequest("getRestaurantMenuType").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          const modifiedData = obj1.message.map((item) => ({
            ...item,
            fld_image_url: "",
          }));
          menuType = modifiedData;
          // setMenuType(modifiedData);
          getRestaurantMenuImages();
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const GetImageRestaurantType = () => {
    GetApiCall.getRequest("getRestaurantImageType").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setImageType(obj1.message);
          modifiedData = obj1.message.map((item) => ({
            ...item,
            fld_image_url: "",
            fld_image_id: null,
          }));
          setTypeCards(modifiedData);
          GetRestaurantImages();
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const GetRestaurantImages = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_restaurant_id = ${restaurantId}`,
      },
      "getRestaurantImages"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setRestaurantImages(obj1.message);
          if (obj1.message.length > 0 && modifiedData.length > 0) {
            const cardsWithImage = modifiedData.map((obj) => {
              if (obj.fld_image_url == "" && obj.fld_type) {
                const matchedObj = obj1.message.find(
                  (data) => data.fld_type === obj.fld_type
                );
                if (matchedObj) {
                  return {
                    ...obj,
                    fld_image_url: matchedObj.fld_image_url,
                    fld_image_id: matchedObj.fld_id,
                  };
                }
              }
              return obj;
            });
            if (cardsWithImage) {
              setFinaltypeCards(cardsWithImage);
            }
          } else {
            setFinaltypeCards(modifiedData);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const AddRestaurantImage = (imageid, type, imageurl) => {
    PostApiCall.postRequest(
      {
        imageid: imageid,
        restaurantid: restaurantId,
        imagetype: type,
        imageurl: imageurl,
        status: "",
        showontop: "Yes"
      },
      "updateRestaurantImages"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          GetImageRestaurantType();
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const getRestaurantMenuImages = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_restaurant_id = ${restaurantId}`,
        columns: "*",
      },
      "getRestaurantMenuImages"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length > 0 && menuType.length > 0) {
            const cardsWithImage = menuType.map((obj) => {
              if (obj.fld_image_url == "" && obj.fld_menu) {
                const matchedObj = obj1.message.find(
                  (data) => data.fld_menu === obj.fld_menu
                );
                if (matchedObj) {
                  return {
                    ...obj,
                    fld_image_url: matchedObj.fld_image_url,
                    fld_image_id: matchedObj.fld_id,
                  };
                }
              }
              return obj;
            });
            if (cardsWithImage) {
              setMenu(cardsWithImage);
            }
            // setFoodMenuImage(obj1.message[0].food_menu_image)
            // setPhotos(obj1.message)
          } else {
            setMenu(menuType);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  // const OnSubmitForm = () =>{
  //   if (finaltypeCards){}
  // }
  const OnSaveForm = () => {
    Notiflix.Loading.dots("Saving Data");
    PostApiCall.postRequest(
      {
        clientid: restaurantId,
        // companyid: clientId,
        restaurantname: restaurantName,
        image: restaurantLogo,
        logo: restaurantLogo,
        facilities: facilities.length > 0 ? facilities : "",
        address: address1,
        address2: address2,
        country: country,
        state: state,
        city: city,
        landmark: landmark,
        pincode: pincode,
        mobilenumber: contact1,
        mobile2: contact2,
        phonenumber: restaurantNumber,
        email: email,
        website: website,
        mapaddress: location,
        locationlink: directionButton,
        whatsappnumber: whatsappNumber,
        facebook: facebookURL,
        instagram: instaURL,
        linkedin: linkedinURL,
        pinterest: pintrustURL,
        youtube: youtubeURL,
        cuisines: cuisinesId.length > 0 ? cuisinesId : "",
        workinghours: workingHours,
        ischef: "No",
        // otherinfo: editorState,
        geolocation: geolocation,
        averageCost: averageCost,
        restaurantType: restaurantType.length > 0 ? restaurantType : "",
        metatitle: metaTitle,
        metadescription: metaDescription,
        ogurl: ogurl,
        ogimage: ogImage,
        canonical: canonicaltag,
        keywords: keyWords,
        isCatering: isCatering,
        receiveUpdate: receiveUpdate,
        companyname: companyName,
        gst: gst,
        fssai: fssai
      },
      "UpdateRestaurantMaster"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          getRestaurantDetails();
          Notiflix.Notify.success("Restaurant Added Successfully");
          setThankYouModal(true)
        }
      })
    );
  };

  const handleCheckboxChange = (event) => {
    const id = event.target.getAttribute("value");
    const isSelected = facilities.includes(id);
    //const id = event.target.attributes.getNamedItem('checked').value;
    if (isSelected == false) {
      setFacilities((prevIds) => [...prevIds, id]);
    } else {
      setFacilities((prevIds) => prevIds.filter((prevId) => prevId !== id));
    }
  };
  const handleTypeCheckboxChange = (event) => {
    const id = event.target.getAttribute("value");
    const isSelected = restaurantType.includes(id);
    //const id = event.target.attributes.getNamedItem('checked').value;
    if (isSelected == false) {
      setRestaurantType((prevIds) => [...prevIds, id]);
    } else {
      setRestaurantType((prevIds) => prevIds.filter((prevId) => prevId !== id));
    }
  };
  const handleCusineChange = (event) => {
    const id = event.target.getAttribute("value");
    const isSelected = cuisinesId.includes(id);
    //const id = event.target.attributes.getNamedItem('checked').value;
    if (isSelected == false) {
      setCuisinesId((prevIds) => [...prevIds, id]);
    } else {
      setCuisinesId((prevIds) => prevIds.filter((prevId) => prevId !== id));
    }
  };

  function getStates(country) {
    PostApiCall.postRequest({ countryid: country }, "getState").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setStates(obj.message);
          }
        })
    );
  }

  function getCities(state) {
    PostApiCall.postRequest({ stateid: state }, "getCity").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setCities(obj.message);
          }
        })
    );
  }
  const tooltip = (
    <Tooltip id="tooltip">
      To change the name of restaurant please contact on
      <strong><a href="tel:+7827795895">+91 78277-95895</a></strong>
    </Tooltip>
  );

  const DeleteImage = (id, type) => {
    Notiflix.Loading.dots("");
    PostApiCall.postRequest(
      {
        id: id,
        deletefrom: type
      }
      ,
      "deleteRestaurantImage").then((results) =>
        results?.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            if (obj1?.message?.length != 0) {
              GetImageRestaurantType();
              Notiflix.Notify.success("Image Successfully deleted")
              Notiflix.Loading.remove();
            }
            Notiflix.Loading.remove();
          }
        })
      );
  }
  return (
    <section className="services py-lg-4 margin-left" data-scroll-index="3">
      {/* <Form noValidate onSubmit={handleSubmit}> */}
      <div className="container-fluid px-lg-4 cuisines">
        <div className="row">
          <div class="col-lg-12 text-center mb-2 mt-4 mt-lg-0">
            <h4 class="extra-text">
              Create Your <b>Restaurant's</b> Profile Page
            </h4>
            <p class="px-lg-0 px-0 mx-auto about-des">
              You can add information about your Restaurant which will be
              published on GrubDigest.com, The profile page you create will be
              made public after we approve the content. Once Published the page
              will be promoted for you to receive traffic for your business
              through our Platform and Social Media.
            </p>
          </div>
          <div className="col-12 my-3">
            <div className="dash-heading">
              <h5>Showcase your Restaurant's Ambience & Food</h5>
              <div>
                <p className="mb-2">
                  Upload clear images of your restaurant in .png or .jpg format {" "}(<b>All photos are mandatory</b>)
                </p>
              </div>
            </div>

            {/* <p className="mb-3">Please click the icons to provide the necessary details.</p> */}
          </div>
          {finaltypeCards
            .filter((type) => {
              if (
                type.fld_status == "Active" &&
                type.fld_type != "gallery images"
              ) {
                return type;
              }
            })
            .map((data) => {
              return (
                <Col lg={3} md={6} className="mb-3" key={data.fld_id}>
                  <div className="upload-image rounded position-relative">
                    {data.fld_image_url && (
                      <>
                        <button
                          className="img-delete-btn main-images"
                          style={{
                            display: uploadProgress[data.fld_id] != undefined && uploadProgress[data.fld_id] < 100 ?
                              "none" : "block"
                          }}
                          onClick={() => {
                            DeleteImage(data.fld_image_id, "RestaurantImages")
                          }}
                        ><MdOutlineCancel />
                        </button>
                        <img
                          src={data.fld_image_url}
                          className="img-fluid w-100 rounded"
                          alt=""
                        />
                      </>
                    )}
                    <div className="text-center">
                      {/* <div
                        className="text-dark restuarant-docs"
                        id={data.fld_id}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}>
                        <div>
                          {/* <img
                            alt=""
                            htmlFor="photo-upload"
                            src={
                              data.fld_image_url != ""
                                ? data.fld_image_url
                                : previewUrl
                            }
                            style={{
                              width: "auto",
                              height: "100%",
                              borderRadius: "5%",
                              objectFit: "cover",
                            }}
                          />
                          <Button
                            variant="danger"
                            className="crop-btn text-capitalize"
                            onClick={() => {
                              setImageCropperModal(true);
                              setSelectedImageId(data.fld_id);
                              setImageId(data.fld_image_id);
                            }}>
                            Click to upload image
                          </Button>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "block" }}
                            onChange={(e) => {
                              e.preventDefault();
                              const imageFile = e.target.files[0];
                              Notiflix.Loading.dots();
                              const form = new FormData();
                              let filename = `${Math.floor(
                                Math.random() * 10000
                              )}-${data.fld_type.replace(/ /g, "")}${imageFile.name
                                }`;
                              form.append("file", imageFile);
                              form.append("foldername", "restaurant");
                              form.append("filename", filename);
                              let response;
                              response = fetch(ImageApiUrl, {
                                method: "POST",
                                body: form,
                              })
                                .then((response) => response.json())
                                .then((data1) => {
                                  AddRestaurantImage(
                                    data.fld_image_id,
                                    data.fld_id,
                                    imageurl +
                                    "restaurant/" +
                                    filename
                                      .split(".")
                                      .slice(0, -1)
                                      .join(".") +
                                    ".webp"
                                  );
                                  Notiflix.Loading.remove();
                                  Notiflix.Notify.success(
                                    "Image successfully uploaded."
                                  );
                                });
                            }}
                            id={data.fld_id}
                          />
                        </div>
                      </div> */}
                      <div className="restuarant-docs-p" style={{
                        backgroundColor: uploadProgress[data.fld_id] != undefined && uploadProgress[data.fld_id] < 100 ?
                          "#ffffffa8" : "transparent"
                      }}>
                        {
                          uploadProgress[data.fld_id] != undefined &&
                          uploadProgress[data.fld_id] < 100 &&
                          <ProgressBar now={uploadProgress[data.fld_id]} label={`${uploadProgress[data.fld_id]}%`} id={data.fld_id} className="w-75 mx-auto" />}
                        <p
                          className="fw-bold text-capitalize text-dark restaurant-image-cards add-restaurant-upload-icon flex-column justify-content-center"
                          style={{
                            display: data.fld_image_url == "" ? "flex" : "none",
                          }}>
                          <img className="img-fluid mb-2" src={data.fld_icon} />{" "}
                          {data.fld_type}
                        </p>
                        <div
                          className="add-restaurant-upload-btn"
                          id={data.fld_id}
                          style={{
                            display: uploadProgress[data.fld_id] != undefined && uploadProgress[data.fld_id] < 100 ?
                              "none" : "block"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          {data.fld_image_url == "" ? (
                            <Button
                              variant="danger"
                              className="crop-btn text-capitalize"
                              onClick={() => {
                                setCropperheight(1080);
                                setCropperwidth(1080);
                                // setImageCropperModal(true);
                                setSelectedImageId(data.fld_id);
                                setImageId(data.fld_image_id);
                              }}>
                              <input
                                accept="image/*"
                                id="photo-upload"
                                className="w-100 h-100 opacity-0"
                                type="file"
                                onChange={(e) => {
                                  handleImageChange(e)
                                }}
                              />
                              Click to upload image
                            </Button>
                          ) : (
                            <Button
                              variant="danger"
                              className="crop-btn text-capitalize"
                              onClick={() => {
                                setCropperheight(1080);
                                setCropperwidth(1080);
                                // setImageCropperModal(true);
                                setSelectedImageId(data.fld_id);
                                setImageId(data.fld_image_id);
                              }}>
                              <input
                                accept="image/*"
                                id="photo-upload"
                                className="w-100 h-100 opacity-0"
                                type="file"
                                onChange={(e) => {
                                  handleImageChange(e)
                                }}
                              />
                              Click to replace image
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <img
                    src={TenTwentyTwo}
                    className="img-fluid w-100 rounded d-none"
                    alt=""
                  /> */}
                </Col>
              );
            })}
        </div>
        <div className="row mt-lg-5">
          <>
            <div className="col-lg-12 position-relative">
              <div className="row">
                <div className="col-lg-3 d-lg-flex justify-content-center align-items-start">
                  <div className="upload-image rounded d-block border">
                    <div className="text-center">
                      <div className="upload-image rounded position-relative">
                        <img
                          alt=""
                          htmlFor="photo-upload"
                          src={restaurantLogo}
                          style={{ borderRadius: "5%" }}
                        />
                        <div className="restuarant-docs-p">
                          {restaurantLogo ? (
                            <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon">
                              <img src={restaurantLogo} className="img-fluid" />
                            </p>
                          ) : (
                            <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon">
                              <img src={LogoIcon} className="img-fluid" />
                            </p>
                          )}
                          <div className="add-restaurant-upload-btn">
                            <Button
                              variant="danger"
                              className="crop-btn text-capitalize position-relative"
                              onClick={() => {
                                setCropperheight(388)
                                setCropperwidth(718)
                                setCroppedImageType("Logo")
                                setImageCropperModal(true)
                              }}>
                              {restaurantLogo
                                ? "Replace Restaurant Logo"
                                : "Upload Restaurant Logo "}
                              {/* <input
                                accept="image/*"
                                id="photo-upload"
                                className="w-100 h-100 opacity-0"
                                type="file"
                              // onChange={onChange}
                              /> */}
                            </Button>
                          </div>
                        </div>
                      </div>
                      {/* <ImgUpload
                        className="img-fluid w-100"
                        onChange={(e) => {
                          e.preventDefault();
                          const imageFile = e.target.files[0];
                          setSelectedLogo(URL.createObjectURL(imageFile));
                          const form = new FormData();
                          let filename = `${Math.floor(Math.random() * 10000)}-RestLogo-${imageFile.name.replace(/ /g, "")}` +
                            localStorage.getItem("restaurantid");
                          form.append("file", imageFile);
                          form.append("foldername", "restaurant");
                          form.append("schema", schema);
                          form.append("filename", filename);
                          let response;
                          response = fetch(ImageApiUrl, {
                            method: "POST",
                            body: form,
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              setRestaurantLogo(
                                imageurl + `/${schema}/` +
                                "restaurant/" +
                                filename.split(".").slice(0, -1).join(".") +
                                ".webp"
                              );
                              Notiflix.Loading.remove();
                              Notiflix.Notify.success(
                                "Image successfully uploaded."
                              );
                            });
                        }}
                        src={restaurantLogo}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="p-0">
                    <FloatingLabel
                      controlId="floatingInput"
                      className="restaurant-name-input"
                      label="Restaurant Name">
                      <Form.Control
                        type="text"
                        placeholder="Restaurant Name"
                        disabled
                        value={restaurantName}
                        onChange={(e) => {
                          setRestaurantName(e.target.value);
                        }}
                      />
                      <span className="restaurant-name-ibutton">
                        <OverlayTrigger
                          delayShow={300}
                          delayHide={150}
                          placement="bottom"
                          overlay={tooltip}>
                          <span bsStyle="danger">
                            <RiInformationLine />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </FloatingLabel>
                    <div className="mb-2"></div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="dash-heading p-0 mb-md-3 ms-2">
                          <h5>Average Cost</h5>
                          <p>Enter the average expense for a party of two.</p>
                        </div>
                        <div className="col-md-12">
                          {/* <Form.Control
                    type="text"
                    placeholder="Average Cost For 2"
                    value={averageCost}
                    onChange={(e) => {
                      setAverageCost(e.target.value);
                    }}
                  /> */}

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Enter Average Cost"
                            className="mb-3">
                            <Form.Control
                              type="text"
                              // placeholder="Average Cost For 2"
                              value={averageCost}
                              onChange={(e) => {
                                setAverageCost(e.target.value);
                              }}
                            />
                          </FloatingLabel>
                        </div>
                        {/* <div onClick={focusEditor}>
                  <Editor
                    ref={editor}
                    editorState={editorState}
                    onChange={editorState => setEditorState(editorState)}
                  />
                </div> */}
                      </div>
                    </div>
                    <div className="col-lg-8 mt-3 mt-lg-0 border-start border-danger border-2 ps-lg-5">
                      <div className="dash-heading">
                        <h5>Provide Social Media and Contact Details</h5>
                        <p className="mb-3">
                          Please click the icons to provide the necessary
                          details.
                        </p>
                      </div>
                      <div className="d-lg-flex align-items-center social-block mb-lg-4">
                        <div className="d-flex flex-wrap flex-lg-nowrap mb-lg-4 mb-3 add-restaurant-sm-icon pe-lg-3">
                          <div>
                            <a
                              id="1"
                              className="bg-light"
                              onClick={(e) => {
                                if (socialMediaInput != "facebook") {
                                  setSocialMediaInput("facebook");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}>
                              <FaFacebookF
                                className={
                                  facebookURL
                                    ? "social-share"
                                    : "social-media-share"
                                }
                              />
                            </a>

                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "facebook"
                                    ? "block"
                                    : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Facebook Link"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={facebookURL}
                                    onChange={(e) => {
                                      setFacebookURL(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div>
                            <a
                              id="2"
                              className="bg-light"
                              onClick={(e) => {
                                if (socialMediaInput != "instagram") {
                                  setSocialMediaInput("instagram");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}>
                              <FaInstagram
                                className={
                                  instaURL
                                    ? "social-share"
                                    : "social-media-share"
                                }
                              />
                            </a>
                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "instagram"
                                    ? "block"
                                    : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Instagram Link"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={instaURL}
                                    onChange={(e) => {
                                      setInstaURL(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div>
                            <a
                              id="3"
                              className="bg-light"
                              onClick={(e) => {
                                if (socialMediaInput != "youtube") {
                                  setSocialMediaInput("youtube");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}>
                              <TfiYoutube
                                className={
                                  youtubeURL
                                    ? "social-share"
                                    : "social-media-share"
                                }
                              />
                            </a>

                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "youtube"
                                    ? "block"
                                    : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Youtube Link"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={youtubeURL}
                                    onChange={(e) => {
                                      setYoutubeURL(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div>
                            <a
                              id="4"
                              className="bg-light"
                              onClick={(e) => {
                                if (socialMediaInput != "pinterest") {
                                  setSocialMediaInput("pinterest");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}>
                              <FaPinterestP
                                className={
                                  pintrustURL
                                    ? "social-share"
                                    : "social-media-share"
                                }
                              />
                            </a>

                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "pinterest"
                                    ? "block"
                                    : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Pintrest Link"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={pintrustURL}
                                    onChange={(e) => {
                                      setPintrustURL(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div>
                            <a
                              id="5"
                              className="bg-light"
                              onClick={(e) => {
                                if (socialMediaInput != "linkedin") {
                                  setSocialMediaInput("linkedin");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}>
                              <FaLinkedinIn
                                className={
                                  linkedinURL
                                    ? "social-share"
                                    : "social-media-share"
                                }
                              />
                            </a>

                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "linkedin"
                                    ? "block"
                                    : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter LinkedIn Link"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={linkedinURL}
                                    onChange={(e) => {
                                      setLinkedinURL(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div>
                            <a
                              id="6"
                              onClick={(e) => {
                                if (socialMediaInput != "whatsapp") {
                                  setSocialMediaInput("whatsapp");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}
                              className="bg-light d-flex text-decoration-none text-dark align-items-center pt-0 flex-grow-1">
                              <FaWhatsapp
                                className={
                                  whatsappNumber
                                    ? "social-share"
                                    : "social-media-share"
                                }
                              />
                              {/* <span>WhatsApp</span> */}
                            </a>

                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "whatsapp"
                                    ? "block"
                                    : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter Whatsapp Number"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={whatsappNumber}
                                    onChange={(e) => {
                                      setWhatsappNumber(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div>
                            <a
                              id="7"
                              onClick={(e) => {
                                if (socialMediaInput != "call") {
                                  setSocialMediaInput("call");
                                } else {
                                  setSocialMediaInput("");
                                }
                              }}
                              className="bg-light d-flex text-decoration-none text-dark align-items-center pt-0 flex-grow-1">
                              <FaPhoneVolume
                                className={
                                  restaurantNumber
                                    ? "social-share action-btn rotate-icon"
                                    : "social-media-share action-btn rotate-icon"
                                }
                              />
                              {/* <span>Contact Restaurant</span> */}
                            </a>

                            <div
                              className="social-media-link-update"
                              style={{
                                display:
                                  socialMediaInput == "call" ? "block" : "none",
                              }}>
                              <span className="arrow-top"></span>
                              <Row>
                                <Col xs={10} className="pe-0">
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter Contact Number"
                                    id="inputsocialmedia"
                                    aria-describedby="passwordHelpBlock"
                                    value={restaurantNumber}
                                    onChange={(e) => {
                                      setRestaurantNumber(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="ps-2 pe-0">
                                  <Button
                                    variant="danger"
                                    onClick={() => setSocialMediaInput("")}>
                                    <IoClose />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex mb-lg-3 mb-3 justify-content-between">

                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4 my-3 mb-lg-0 mt-lg-2">
                      <div className="d-flex">
                        <div className="w-25 me-3">
                          <img src={CateringIcon} className="img-fluid" />
                        </div>
                        <div>
                          <div className="dash-heading p-0 mb-3">
                            <h5>Do you provide catering services?</h5>
                          </div>
                          <div>
                            <span>Yes</span>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              checked={isCatering}
                              onChange={() => {
                                setIsCatering((prev) => !prev);
                              }}
                              className="mx-3"
                            />
                            <span>No</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 border-start border-danger border-2 ps-lg-5">
                      <div className="dash-heading p-0 mb-3">
                        <h5>Restaurant Working Hours</h5>
                        <p>Set your restaurant's working hours</p>
                      </div>

                      <div>
                        <Button
                          className="btn btn-danger text-white text-capitalize"
                          onClick={(e) => {
                            if (socialMediaInput != "Workinghours") {
                              setSocialMediaInput("Workinghours");
                            } else {
                              setSocialMediaInput("");
                            }
                          }}>
                          Set timings
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="working-hours-dropdown social-media-link-update p-3"
                    style={{
                      display:
                        socialMediaInput == "Workinghours" ? "block" : "none",
                    }}>
                    <span className="arrow-top"></span>
                    <span
                      className="arrow-close"
                      onClick={() => {
                        setSocialMediaInput("");
                        setWorkingHours(
                          `monday,${mondayClosed == true
                            ? "closed"
                            : `${mondayOpeningTime}` +
                            "," +
                            `${mondayClosingTime}`
                          }#tuesday,${tuesdayClosed == true
                            ? "closed"
                            : `${tuesdayOpeningTime}` +
                            "," +
                            `${tuesdayClosingTime}`
                          }#wednesday,${wednesdayClosed == true
                            ? "closed"
                            : `${wednesdayOpeningTime}` +
                            "," +
                            `${wednesdayClosingTime}`
                          }#thursday,${thrusdayClosed == true
                            ? "closed"
                            : `${thrusdayOpeningTime}` +
                            "," +
                            `${thrusdayClosingTime}`
                          }#friday,${fridayClosed == true
                            ? "closed"
                            : `${fridayOpeningTime}` +
                            "," +
                            `${fridayClosingTime}`
                          }#saturday,${saturdayClosed == true
                            ? "closed"
                            : `${saturdayOpeningTime}` +
                            "," +
                            `${saturdayClosingTime}`
                          }#sunday,${sundayClosed == true
                            ? "closed"
                            : `${sundayOpeningTime}` +
                            "," +
                            `${sundayClosingTime}`
                          }`
                        );
                      }}>
                      <IoClose />
                    </span>
                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Monday</p>
                      <Space wrap>
                        <TimePicker
                          changeOnBlur={true}
                          use12Hours
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setMondayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setMondayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={mondayClosed}
                          onChange={(e) => {
                            setMondayClosed(!mondayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>
                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Tuesday</p>
                      <Space wrap>
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setTuesdayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setTuesdayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={tuesdayClosed}
                          onChange={(e) => {
                            setTuesdayClosed(!tuesdayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>
                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Wednesday</p>
                      <Space wrap>
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setWednesdayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setWednesdayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={wednesdayClosed}
                          onChange={(e) => {
                            setWednesdayClosed(!wednesdayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>
                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Thrusday</p>
                      <Space wrap>
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setThrusdayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setThrusdayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={thrusdayClosed}
                          onChange={(e) => {
                            setThrusdayClosed(!thrusdayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>

                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Friday</p>
                      <Space wrap>
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setFridayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setFridayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={fridayClosed}
                          onChange={(e) => {
                            setFridayClosed(!fridayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>
                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Saturday</p>
                      <Space wrap>
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setSaturdayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setSaturdayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={saturdayClosed}
                          onChange={(e) => {
                            setSaturdayClosed(!saturdayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>
                    <div className="d-flex working-hours mb-2">
                      <p className="mt-3">Sunday</p>
                      <Space wrap>
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setSundayOpeningTime(timeString);
                          }}
                        />
                      </Space>
                      <Space wrap className="mx-3">
                        <TimePicker
                          use12Hours
                          changeOnBlur={true}
                          format="h:mm a"
                          onChange={(time, timeString) => {
                            setSundayClosingTime(timeString);
                          }}
                        />
                      </Space>
                      <div key="checkbox" className="mt-3">
                        <Form.Check
                          type="checkbox"
                          value={sundayClosed}
                          onChange={(e) => {
                            setSundayClosed(!sundayClosed);
                          }}
                          label="Closed"
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-12 text-center mt-lg-4 mt-4">
                      <Button
                        type="submit"
                        variant="danger"
                        className="restaurant-save-btn"
                        onClick={() => {
                          setSocialMediaInput("");
                          setWorkingHours(
                            `monday,${
                              mondayClosed == true
                                ? "closed"
                                : `${mondayOpeningTime}` +
                                  "," +
                                  `${mondayClosingTime}`
                            }#tuesday,${
                              tuesdayClosed == true
                                ? "closed"
                                : `${tuesdayOpeningTime}` +
                                  "," +
                                  `${tuesdayClosingTime}`
                            }#wednesday,${
                              wednesdayClosed == true
                                ? "closed"
                                : `${wednesdayOpeningTime}` +
                                  "," +
                                  `${wednesdayClosingTime}`
                            }#thrusday,${
                              thrusdayClosed == true
                                ? "closed"
                                : `${thrusdayOpeningTime}` +
                                  "," +
                                  `${thrusdayClosingTime}`
                            }#friday,${
                              fridayClosed == true
                                ? "closed"
                                : `${fridayOpeningTime}` +
                                  "," +
                                  `${fridayClosingTime}`
                            }#saturday,${
                              saturdayClosed == true
                                ? "closed"
                                : `${saturdayOpeningTime}` +
                                  "," +
                                  `${saturdayClosingTime}`
                            }#sunday,${
                              sundayClosed == true
                                ? "closed"
                                : `${sundayOpeningTime}` +
                                  "," +
                                  `${sundayClosingTime}`
                            }`
                          );
                        }}>
                        Save Timings
                      </Button>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-5"></div>
              </div>
            </div>
          </>
        </div>
        <div className="mt-4">
          <div className="dash-heading">
            <h5>Restaurant Cuisines</h5>
            <div>
              <p className="mb-3">
                Choose from a variety of cuisines that your restaurant offers. {" "}(<b>Select one or more</b>)
              </p>
            </div>
          </div>
          <div className="hero">
            <div className="row">
              {cuisineSuggestion.map((data) => (
                <div className="restaurant-type-wrap px-3 position-relative d-block">
                  <div
                    className={`item min-height-auto text-center overflow-hidden ${cuisinesId.indexOf(data.fld_id.toString()) > -1
                      ? "selected"
                      : ""
                      }`}
                    value={data.fld_id}
                    onClick={(e) => {
                      handleCusineChange(e);
                    }}>
                    <img
                      src={data.fld_image}
                      className="services-icon"
                      value={data.fld_id}
                      alt=""
                    // onClick={(e) => {
                    //   handleCusineChange(e);
                    // }}
                    />
                    <h6 value={data.fld_id} className="mb-0">{data.fld_name}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-4">
              <div className="dash-heading">
                <h5>Food and Beverages Menu</h5>
                <div>
                  <p className="mb-3">
                    Upload clear images of your menu in .png or .jpg format (<b>Please upload at least one menu</b>)
                  </p>
                </div>
              </div>
              <div className="row">
                {menu
                  .filter((type) => {
                    if (type.fld_status == "Active") {
                      return type;
                    }
                  })
                  .map((data) => {
                    return (
                      <>
                        <div className="col-lg-3 col-md-6 mb-3">
                          <div
                            className="upload-image rounded"
                          // onClick={() => {
                          //   setMenuModal(true);
                          //   setMenuTypeName(data.fld_menu);
                          //   setMenuTypeId(data.fld_id);
                          // }}
                          >
                            <div className="text-center gallary-upload-btn h-100">
                              {data.fld_image_url && (
                                <img
                                  alt=""
                                  src={data.fld_image_url}
                                  className="img-fluid w-100 h-100 mb-0 rounded"
                                />
                              )}
                              <div className="restuarant-docs-p">
                                {/* <div className="add-image"> */}
                                {/* <div className="text-dark cursor-pointer">
                                  <MdAddCircleOutline className="upload-imge-btn" />
                                </div>
                                <p className="fw-bold text-capitalize">
                                  upload {data.fld_menu}
                                </p>
                                <p>Click here to upload images</p> */}
                                {data.fld_image_url === "" ? (
                                  <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon">
                                    <img
                                      src={data.fld_icon}
                                      className="img-fluid"
                                    />
                                  </p>
                                ) : (
                                  ""
                                )}
                                {/* <p>Click here to upload logo</p> */}
                                <div className="add-restaurant-upload-btn">
                                  <Button
                                    variant="danger"
                                    className="crop-btn text-capitalize"
                                    onClick={() => {
                                      setMenuModal(true);
                                      setMenuTypeName(data.fld_menu);
                                      setMenuTypeId(data.fld_id);
                                    }}>
                                    upload {data.fld_menu}
                                  </Button>
                                </div>
                              </div>
                              {data.fld_image_url && (
                                <div
                                  className="image-overlay"
                                  onClick={() => {
                                    setMenuModal(true);
                                    setMenuTypeName(data.fld_menu);
                                    setMenuTypeId(data.fld_id);
                                  }}></div>
                              )}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setMenuModal(true);
                            }}
                            className="d-block overflow-hidden d-none">
                            <img
                              alt=""
                              src={foodMenuImage}
                              className="img-fluid w-100 mb-2 rounded"
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>

            <div className="">
              <div className="dash-heading">
                <h5>Facilities</h5>
                <div>
                  <p className="mb-3">
                    Choose from a variety of facilities that your restaurant
                    offers.{" "}(<b>Select one or more</b>)
                  </p>
                </div>
              </div>
              <div className="hero">
                <div className="row">
                  {restaurantFacilities.map((data) => (
                    <div
                      className="restaurant-type-wrap facilities-type-wrap px-3 position-relative d-block"
                      value={data.fld_id}
                      alt=""
                      onClick={(e) => {
                        handleCheckboxChange(e);
                      }}>
                      <div
                        className={`item min-height-auto text-center overflow-hidden ${facilities.indexOf(data.fld_id.toString()) > -1
                          ? "selected"
                          : ""
                          }`}
                        value={data.fld_id}
                      >
                        <div className="w-100 bg-white">
                          <img
                            src={data.fld_image}
                            className="services-icon"
                            value={data.fld_id}
                            alt=""
                          />
                        </div>
                        <h6 value={data.fld_id} className="mb-0">{data.fld_display_name}</h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row featured-events">
              <div className="mb-0">
                <div className="dash-heading">
                  <h5>Type of Restaurant</h5>
                  <div>
                    <p className="mb-3">
                      Select the restaurant types that fits your food business.{" "}(<b>Select one or more</b>)
                    </p>
                  </div>
                </div>
                <div className="hero facilities-type-wrap">
                  <div className="row">
                    {resTypeList.map((data) => (
                      <div
                        className="restaurant-type-wrap facilities-type-wrap px-3 position-relative d-block"
                        value={data.fld_id}
                      // onClick={(e) => {
                      //   handleTypeCheckboxChange(e);
                      // }}
                      >
                        <div
                          className={`item min-height-auto text-center overflow-hidden ${restaurantType.indexOf(data.fld_id.toString()) > -1
                            ? "selected"
                            : ""
                            }`}
                          value={data.fld_id}
                          onClick={(e) => {
                            handleTypeCheckboxChange(e);
                          }}
                        >
                          <div className="w-100 bg-white">
                            <img
                              src={data.fld_image}
                              className="services-icon"
                              value={data.fld_id}
                              alt=""
                            />
                          </div>
                          <h6 value={data.fld_id} className="mb-0">{data.fld_display_name}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row featured-events">
              <div className="col-12">
                <div className="d-lg-flex justify-content-between align-items-center">
                  <div className="dash-heading">
                    <h5>Photo Gallery</h5>
                    <p className="mb-3">
                      Upload clear images of your restaurant in .png or .jpg
                      format
                    </p>
                  </div>
                  <p>
                    <b>
                      Images Uploaded (
                      {
                        restaurantImages.filter((dat) => {
                          if (dat.fld_type === "gallery images") {
                            return dat;
                          }
                        }).length
                      }{" "}
                      of 25)
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  {finaltypeCards
                    .filter((type) => {
                      if (
                        type.fld_status == "Active" &&
                        type.fld_type == "gallery images"
                      ) {
                        return type;
                      }
                    })
                    .map((data) => {
                      return (
                        <div
                          className="col-lg-3"
                          onClick={() => {
                            setGallaryModal(true);
                            setMenuTypeId(data.fld_id);
                            setMenuTypeName(data.fld_type);
                          }}>
                          <div className="upload-image rounded border">
                            <div className="text-center gallary-upload-btn h-100">
                              {data.fld_image_url && (
                                <img
                                  alt=""
                                  src={data.fld_image_url}
                                  className="img-fluid w-100 h-100 mb-0 rounded"
                                />
                              )}
                              {/* <div className="add-image">
                                <div className="text-dark cursor-pointer">
                                  <MdAddCircleOutline className="upload-imge-btn" />
                                </div>
                                <p className="fw-bold text-capitalize text-dark">
                                  upload {data.fld_type}
                                </p>
                                <p>Click here to upload images</p>
                              </div> */}
                              <div className="restuarant-docs-p">
                                <p className="fw-bold text-capitalize text-dark add-restaurant-upload-icon flex-column align-items-center"
                                  style={{
                                    display: data.fld_image_url == "" ? "flex" : "none",
                                  }}>
                                  <img
                                    src={GalleryIcon}
                                    className="img-fluid"
                                  />
                                  Upload Restaurant & Food Images
                                </p>
                                {/* <p>Click here to upload logo</p> */}
                                <div className="add-restaurant-upload-btn">
                                  <Button
                                    variant="danger"
                                    className="crop-btn text-capitalize">
                                    {/* upload {data.fld_type} */}
                                    Click To Upload Image
                                  </Button>
                                </div>
                              </div>
                              {data.fld_image_url && (
                                <div className="image-overlay"></div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="quick-contact mb-5 pb-4 mb-lg-0 pb-lg-0 mt-3">
              <div className="row">
                <div className="col-12">
                  <div className="dash-heading mb-4">
                    <h5>Contact Information</h5>
                  </div>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Company Name"
                    className="mb-2 w-100">
                    <Form.Control
                      type="text"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      placeholder="Company Name"
                    />
                  </FloatingLabel>
                </div>
                <div className="col-lg-6">
                  <div className="contact-address contact-detail d-block">
                    <div className="tab-section-title">Contact Number *</div>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Contact Number *"
                      className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="Contact Number *"
                        value={contact1}
                        onChange={(e) => {
                          setContact1(e.target.value);
                        }}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="contact-address contact-detail d-block ">
                    <div className="tab-section-title">Phone Number</div>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Phone Number"
                      className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        value={contact2}
                        onChange={(e) => {
                          setContact2(e.target.value);
                        }}
                      />
                    </FloatingLabel>
                  </div>


                  {/* <iframe src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=place_id:ChIJuY2H2IrhDDkRjLM4o5FGb-A`}></iframe> */}
                  {/* <div style={{ width: "100%", marginTop: "20px" }}>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" ref={bootstrapRef} />
                      </Form.Group>
                    </Form>
                  </div> */}
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contact-address contact-detail d-block">
                        <div className="tab-section-title">Email *</div>
                        <div className="row">
                          <div className="col-lg-12">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Email Address *">
                              <Form.Control
                                type="text"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                            </FloatingLabel>
                          </div>
                        </div>
                      </div>
                      <div className="contact-address contact-detail d-block">
                        <div className="tab-section-title">Website</div>
                        <div className="row">
                          <div className="col-lg-12">
                            <FloatingLabel
                              controlId="floatingWebsite"
                              label="Website"
                              className="mb-2">
                              <Form.Control
                                type="text"
                                placeholder="Website"
                                value={website}
                                onChange={(e) => {
                                  setWebsite(e.target.value);
                                }}
                              />
                            </FloatingLabel>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-12">
                      <p className="tab-section-title">
                        Search engine optimization
                      </p>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Meta Title"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Meta Title"
                          value={metaTitle}
                          onChange={(e) => {
                            setMetaTitle(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Meta Description"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Meta Description"
                          value={metaDescription}
                          onChange={(e) => {
                            setMetaDescription(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Canonical Tag URL"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Canonical Tag URL"
                          value={canonicaltag}
                          onChange={(e) => {
                            setCanonicaltag(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Og URL"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Og URL"
                          value={ogurl}
                          onChange={(e) => {
                            setOgurl(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Og Image URL"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Og Image URL"
                          value={ogImage}
                          onChange={(e) => {
                            setOgImage(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Keywords"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Og Image URL"
                          value={keyWords}
                          onChange={(e) => {
                            setKeyWords(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div> */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-address contact-detail gap-0 row">
                    <div className="col-lg-12">
                      <p className="tab-section-title">Location</p>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Search Location"
                        className="mb-2">
                        <Autocomplete
                          style={{ width: "100%" }}
                          ref={inputRef}
                          apiKey={googleApiKey}
                          className="form-control"
                          onPlaceSelected={(selected, a, c) => {
                            setDirectionButton(
                              `https://www.google.com/maps/place/?q=place_id:${selected?.place_id}`
                            );
                            setGeolocation(
                              `https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=place_id:${selected?.place_id}`
                            );
                            setAddress1(selected.formatted_address);
                            setPincode(
                              selected?.address_components?.filter((value) =>
                                value.types.includes("postal_code")
                              )[0]?.long_name
                            );
                            // console.log(selected);
                            // console.log(selected?.address_components?.filter((value) => value.types.includes("subpremise"))[0]?.long_name)
                          }}
                          options={{
                            types: ["geocode", "establishment"],
                            componentRestrictions: { country: "in" },
                          }}
                          placeholder="Search Location"
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-section-title">Google Map URL</p>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Google Map URL"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Google Map URL"
                          value={geolocation}
                          onChange={(e) => {
                            setGeolocation(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-section-title">Direction Button URL</p>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Direction Button URL"
                        className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Direction Button URL"
                          value={directionButton}
                          onChange={(e) => {
                            setDirectionButton(e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>

                  </div>
                  <div className="contact-address contact-detail d-block">
                    <div className="tab-section-title">Address</div>
                    <div className="row">
                      <div className="col-12">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Address"
                          className="mb-2 w-100">
                          <Form.Control
                            type="text"
                            placeholder="Address"
                            value={address1}
                            onChange={(e) => {
                              setAddress1(e.target.value);
                            }}
                          />
                        </FloatingLabel>

                        {/* <Box className="mt-3 w-100">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Country
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Country"
                              variant="outlined"
                              value={country}
                              onChange={(e) => {
                                setCountry(e.target.value);
                                PostApiCall.postRequest(
                                  { countryid: e.target.value },
                                  "getState"
                                ).then((resultcategory) =>
                                  resultcategory.json().then((obj) => {
                                    if (
                                      resultcategory.status == 200 ||
                                      resultcategory.status == 201
                                    ) {
                                      setStates(obj.message);
                                    }
                                  })
                                );
                              }}>
                              {countries.length > 0 ? (
                                countries.map((country, i) => {
                                  return (
                                    <MenuItem value={country.value}>
                                      {country.label}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box> */}



                        {/* <Box className="mt-3 w-100">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="State"
                              variant="outlined"
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                                getCities(e.target.value);
                              }}>
                              {states.length > 0 ? (
                                states.map((state, i) => {
                                  return (
                                    <MenuItem value={state.value}>
                                      {state.label}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box> */}


                        {/* <Box className="mt-3 w-100">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              City
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="City"
                              variant="outlined"
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}>
                              {cities.length > 0 ? (
                                cities.map((city, i) => {
                                  return (
                                    <MenuItem value={city.value}>
                                      {city.label}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box> */}

                      </div>
                      <div className="col-lg-6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Landmark"
                          className="my-2">
                          <Form.Control
                            type="text"
                            placeholder="Landmark"
                            value={landmark}
                            onChange={(e) => {
                              setLandmark(e.target.value);
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col-lg-6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Pincode"
                          className="my-2">
                          <Form.Control
                            type="text"
                            placeholder="Address 4"
                            value={pincode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col-lg-4">
                        <FloatingLabel
                          className="w-100 mt-2"
                          controlId="floatingSelect"
                          label="Country">
                          <Form.Select
                            aria-label="Country"
                            value={country}
                            onChange={(e) => {
                              setCountry(e.target.value);
                              PostApiCall.postRequest(
                                { countryid: e.target.value },
                                "getState"
                              ).then((resultcategory) =>
                                resultcategory.json().then((obj) => {
                                  if (
                                    resultcategory.status == 200 ||
                                    resultcategory.status == 201
                                  ) {
                                    setStates(obj.message);
                                  }
                                })
                              );
                            }}>
                            <option>Select Country</option>
                            {countries.length > 0 ? (
                              countries.map((country, i) => {
                                return (
                                  <option value={country.value}>
                                    {country.label}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                      <div className="col-lg-4">
                        <FloatingLabel
                          className="mt-2 w-100"
                          controlId="floatingSelect"
                          label="State">
                          <Form.Select
                            aria-label="State"
                            value={state}
                            onChange={(e) => {
                              setState(e.target.value);
                              getCities(e.target.value);
                            }}>
                            <option>Select State</option>
                            {states.length > 0 ? (
                              states.map((state, i) => {
                                return (
                                  <option value={state.value}>
                                    {state.label}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                      <div className="col-lg-4">
                        <FloatingLabel
                          className="mt-2 w-100"
                          controlId="floatingSelect"
                          label="City">
                          <Form.Select
                            aria-label="City"
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}>
                            <option>Select City</option>
                            {cities.length > 0 ? (
                              cities.map((city, i) => {
                                return (
                                  <option value={city.value}>
                                    {city.label}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="tab-section-title">GSTIN</p>
                  <div
                  // className="d-lg-flex"
                  >
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter GST Number"
                    // className="w-50"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter GST Number"
                        value={gst}
                        onChange={(e) => { setGst(e.target.value) }}
                      />
                    </FloatingLabel>
                    {/* <Upload {...props} className="ms-3 mt-1">
                      <Button
                        variant="danger"
                        className="restaurant-save-btn w-auto">
                        <UploadOutlined />
                      </Button>
                    </Upload> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="tab-section-title">FSSAI Lic. No.</p>
                  <div className="d-lg-flex">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter FSSAI No."
                    // className="w-50"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter FSSAI No."
                        value={fssai}
                        onChange={(e) => { setFssai(e.target.value) }}
                      />
                    </FloatingLabel>
                    {/* <Upload {...props} className="ms-3 mt-1">
                      <Button
                        variant="danger"
                        className="restaurant-save-btn w-auto">
                        <UploadOutlined />
                      </Button>
                    </Upload> */}
                  </div>
                </div>
              </div>


              <div className="col-lg-12 text-center mt-lg-4 mt-4 save-btn-bottom-fixed py-3">
                <div className="d-lg-flex justify-content-between align-items-center border rounded p-3 p-lg-2">
                  <div className="fw-bold">
                    <Form.Check
                      type="checkbox"
                      value={receiveUpdate}
                      checked={receiveUpdate}
                      onChange={(e) => {
                        setReceiveUpdate(e.target.value);
                      }}
                      label="Yes, I would like to receive important updates and notifications from Grub Digest"
                    />
                  </div>

                  <div>
                    <Button
                      type="submit"
                      variant="danger"
                      className="restaurant-save-btn w-auto mt-3 mt-lg-0"
                      onClick={() => {
                        OnSaveForm();
                      }}>
                      Save My Restaurant Profile
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "200px" }}>{/* <ImageCrop /> */}</div>
          {/* <span className="font-weight-bold">
                  ₹ {data.minAmount} for two
                </span> */}
        </div>
      </div>
      {/* </div> */}
      {/* </Form> */}
      <Modal
        show={menuModal}
        onHide={handleCloseMenuModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className="profile-view">
        <Modal.Header className="mb-3" closeButton>
          {menuTypeName}
        </Modal.Header>

        <Modal.Body>
          <div>
            <ImageUpload
              // images={menu}
              UploadApiUrl={ImageApiUrl}
              SaveApiUrl="updateRestaurantMenuImages"
              menuTypeName={menuTypeName}
              menuTypeId={menuTypeId}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={gallaryModal}
        onHide={handleCloseGallaryModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className="profile-view">
        <Modal.Header className="mb-3 text-capitalize" closeButton>
          {menuTypeName}
        </Modal.Header>

        <Modal.Body>
          <div>
            <ImageUpload
              images=""
              UploadApiUrl={ImageApiUrl}
              SaveApiUrl="updateRestaurantImages"
              menuTypeName={menuTypeName}
              menuTypeId={menuTypeId}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* </Image Cropper> */}
      <Modal
        show={imageCropperModal}
        onHide={handleCloseImageCropperModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="croper-modal profile-view">
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <div className="crop-input">
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {image && (
              <div className="croper-main">
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  ref={cropperRef}
                  initialAspectRatio={4}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  aspectRatio={cropperwidth / cropperheight}
                  background={false}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  guides={true}
                />
                <Button variant="danger" onClick={handleCrop} className="mt-3">
                  Crop
                </Button>
              </div>
            )}
            {cropData !== "#" && (
              <div>
                <h4 className="tab-section-title">Cropped Image Preview</h4>
                <img src={cropData} alt="Cropped Image" />
                <div className="mt-3 text-center">
                  {/* <Button variant="danger" className="me-3">
                  Crop
                </Button> */}
                  <Button
                    variant="danger"
                    onClick={(e) => {
                      // e.preventDefault()
                      // Notiflix.Loading.dots();
                      setImageCropperModal(false);
                      const form = new FormData();
                      let filename = croppedImageType === "Logo" ?
                        `${Math.floor(Math.random() * 10000)}-RestLogo-${croppedImageName.replace(/ /g, "")}` + localStorage.getItem("restaurantid") :
                        `${Math.floor(Math.random() * 10000)}-${croppedImageName.replace(/ /g, "")}`;
                      form.append("file", croppedImage);
                      form.append("foldername", "restaurant");
                      form.append("schema", schema);
                      form.append("filename", filename);
                      const xhr = new XMLHttpRequest();

                      xhr.upload.onprogress = (e) => {
                        if (e.lengthComputable) {
                          const progress = Math.round((e.loaded / e.total) * 100);
                          setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [selectedImageId]: progress,
                          }));
                        }
                      };
                      xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                          if (xhr.status === 200 || xhr.status === 201) {
                            // Handle the successful response
                            const response = JSON.parse(xhr.responseText);
                            croppedImageType === "Logo" ?
                              setRestaurantLogo(
                                imageurl + `/${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp"
                              ) :
                              AddRestaurantImage(
                                imageId,
                                selectedImageId,
                                imageurl + `/${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp"
                              );
                            Notiflix.Loading.remove();
                            Notiflix.Notify.success(
                              "Image successfully uploaded."
                            );
                            setCropData("#");
                          } else {
                            // Handle errors
                            console.error('Error uploading image:', xhr.statusText);
                          }
                        }
                      };

                      xhr.open('POST', ImageApiUrl, true);
                      xhr.send(form);
                      // let response;
                      // response = fetch(ImageApiUrl, {
                      //   method: "POST",
                      //   body: form,
                      // })
                      //   .then((response) => response.json())
                      //   .then((data1) => {
                      //     croppedImageType === "Logo" ?
                      //       setRestaurantLogo(
                      //         imageurl + `/${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp"
                      //       ) :
                      //       AddRestaurantImage(
                      //         imageId,
                      //         selectedImageId,
                      //         imageurl + `/${schema}/` + "restaurant/" + filename.split(".").slice(0, -1).join(".") + ".webp"
                      //       );
                      //     Notiflix.Loading.remove();
                      //     Notiflix.Notify.success(
                      //       "Image successfully uploaded."
                      //     );
                      //     setImageCropperModal(false);
                      //     setCropData("#");
                      //   });
                    }}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={thankYouModal}
        onHide={() => { setThankYouModal(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="croper-modal profile-view">
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center mb-4 thankyou-img">
            <img src={ThankyouIcon} />
          </div>
          <h4 class="mb-2 text-center mb-3">
            Restaurant listing details submitted{" "}
          </h4>
          <p className="text-center">
            Our team will verify the details and update once your page is live on
            {" "}<a target="_blank" href="https://grubdigest.com/">
              <span className="theme-color">
                <b>Grub</b>
                {" "}
                <b>Digest</b>.
              </span>
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </section >
  );
}

export default Profile;
