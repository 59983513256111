import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import PostApiCall from "../../helper/Api";
import "./StaticMediaContent.css";
import noImage from "../../assets/images/no-image.png";


const StaticMediaContent = () => {
    let navigate = useNavigate();
    const [contentList, setContentList] = useState([]);
    const [visits, setVisit] = useState(false);
    const [photos, setPhotos] = useState([]);
    const handleCloseVisitForm = () => {
        setVisit(false)
    }
    useEffect(() => {
        getcontentList()

    }, []);
    useEffect(() => {
    }, [])
    const getcontentList = () => {
        PostApiCall.postRequest(
            {
                WhereClause: "",
                columns: "*"
            },
            "getStaticContent"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    setContentList(obj.message);
                }
            })
        );
    }
    return (
        <>
            <div className="container my-5 mt-5">
                <div className="row form-container">
                    <div className="mb-3 d-flex justify-content-between border-bottom pb-3 mb-5">
                        <h3 class="main-text fw-bold">Content List</h3>
                        <button
                            type="button"
                            className="default-btn btn btn-white"
                            onClick={() => {
                                navigate("/staticmediacontent/add");
                            }}
                        >
                            Add New
                        </button>
                    </div>
                    {contentList.map((data, i) => {
                        return (
                            <div className="col-md-3 px-3 mb-5">
                                <div className="default-card shadow border-light">
                                    <div className="card-content">
                                        <div className="d-flex align-item-center justify-content-center">
                                            <img
                                                className="img-fluid"
                                                src={data.fld_image == "" ? noImage : data.fld_image}
                                                alt=""
                                            ></img>
                                        </div>
                                        <h4>{data.fld_page_name}</h4>
                                        <p className="card-description">{data.fld_page_heading}</p>
                                        <p className="card-description">{data.fld_description}</p>
                                        <p className="card-description">{data.fld_sequence}</p>
                                        <p className="card-description">Display In Website- {data.fld_status}</p>
                                        <div className="col-12 border-top pt-3 text-end d-flex mt-3">
                                            <div className="ms-auto">
                                                <button
                                                    type="button"
                                                    className="default-btn ms-auto me-4"
                                                    onClick={() => {
                                                        navigate('/staticmediacontent/edit', { state: data.fld_static_page_id })
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="default-btn btn btn-white"
                                                    onClick={() => {
                                                        confirmAlert({
                                                            title: "Confirm to Delete",
                                                            message:
                                                                "Are you sure you want to delete this key strings?",
                                                            buttons: [
                                                                {
                                                                    label: "Yes",
                                                                    onClick: () => {
                                                                        PostApiCall.postRequest(
                                                                            {
                                                                                whereClause: `where fld_static_page_id = ${data.fld_static_page_id}`,
                                                                            },
                                                                            "deleteStaticContent"
                                                                        ).then((results) =>
                                                                            results.json().then((obj) => {
                                                                                if (
                                                                                    results.status == 200 ||
                                                                                    results.status == 201
                                                                                ) {
                                                                                    Notiflix.Loading.remove();
                                                                                    Notiflix.Notify.success(
                                                                                        "successfully deleted."
                                                                                    );
                                                                                    getcontentList();
                                                                                } else {
                                                                                    Notiflix.Loading.remove();
                                                                                    Notiflix.Notify.failure(
                                                                                        "Something went wrong, try again later."
                                                                                    );
                                                                                }
                                                                            })
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    label: "No",
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default StaticMediaContent;
