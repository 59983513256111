import React, { useState } from "react";
import {
  Button, Card, Col,
  Container,
  Row
} from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import "./CustomerDashboard.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import Notiflix from "notiflix";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FreeMode, Pagination } from "swiper/modules";
import femaleAvatar from "../../assets/images/femaleAvatar.jpg";
import maleAvatar from "../../assets/images/maleAvatar.jpg";
import AddressOffcanvas from "../../components/AddressOffCanvas/AddressOffcanvas";
import { store } from "../../components/Context/store";
import OrderHistoryDrawer from "../../components/OrderHistoryDrawer/OrderHistoryDrawer";
import PostApiCall from "../../helper/Api";
function CustomerDashboard() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowOrderHistory = () => setShowOrderHistory(true);
  const [profileImg, setProfileImg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [addressId, setAddressId] = useState(null);
  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [currAddressType, setCurrAddressType] = useState("home");
  const [currAddressFor, setCurrAddressFor] = useState("Myself");
  const [saveAddressAs, setSaveAddressAs] = useState("");
  const [addressName, setAddressName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [landmark, setLandMark] = useState("");
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const {
    customerPersonalDetails,
    setCustomerPersonalDetails,
    customerLoyaltyDetails,
    setCustomerLoyaltyDetails,
    customerAddressDetails,
    setCustomerAddressDetails,
    allOrdersHistory,
    setAllOrdersHistory,
    customerFrequentlyOrder,
    setCustomerFrequentlyOrder,
    currencyCode
  } = useContext(store);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id =${location.state?.id}`,
      },
      "GetProfileInfo"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (
            obj1.data[0].fld_gender == "Male" ||
            obj1.data[0].fld_gender == null
          ) {
            setProfileImg(maleAvatar);
          } else {
            setProfileImg(femaleAvatar);
          }
          localStorage.setItem("CustomerData", JSON.stringify(obj1.data[0]));
          setCustomerPersonalDetails(obj1.data);
          setName(obj1.data[0]?.fld_name);
          setPhnNo(obj1.data[0].fld_phone_number);
          setDoa(obj1.data[0].fld_doa);
          setDob(obj1.data[0].fld_dob);
          setEmail(obj1.data[0]?.fld_email_address);
          setGender(obj1.data[0].fld_gender);
          PostApiCall.postRequest(
            {
              whereClause: `where fld_customerid = ${location.state.id}`,
            },
            "getCustomerAddress"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                PostApiCall.postRequest(
                  {
                    WhereClause: `where fld_customer_id=${location.state.id} and fld_cart_status in ('New','Accepted','Dispatched','Deliver')`,
                  },
                  "getOrderHistory"
                ).then((results1) =>
                  results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                      PostApiCall.postRequest(
                        {
                          WhereClause: `where fld_customer_id = ${location.state.id} `,
                        },
                        "GetCustomerFrequentlyOrder"
                      ).then((results1) =>
                        results1.json().then((obj1) => {
                          if (
                            results1.status == 200 ||
                            results1.status == 201
                          ) {
                            setCustomerFrequentlyOrder(obj1.data);
                            Notiflix.Loading.remove();
                          }
                        })
                      );
                      setAllOrdersHistory(obj1.data);
                      Notiflix.Loading.remove();
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("Error Occurred");
                    }
                  })
                );
                setCustomerAddressDetails(obj1.message);
                Notiflix.Loading.remove();
              }
            })
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occur");
        }
      })
    );
    // GetApiCall.getRequest("GetProfileInfo").then((resultdes) =>
    //   resultdes.json().then((obj) => {
    //     if (obj.data.length != 0) {
    //       GetApiCall.getRequest("GetCustomerLoyalty").then((resultdes) =>
    //         resultdes.json().then((obj) => {
    //           if (obj.data.length != 0) {
    //             setCustomerLoyaltyDetails(obj.data[0]);
    //             Notiflix.Loading.remove();
    //           } else {
    //             Notiflix.Loading.remove();
    //             Notiflix.Notify.failure("No Records Exits");
    //           }
    //         })
    //       );
    //       GetApiCall.getRequest("GetCustomerAddressList").then((resultdes) =>
    //         resultdes.json().then((obj) => {
    //           if (obj.data.length != 0) {
    //             setCustomerAddressDetails(obj.data);
    //             Notiflix.Loading.remove();
    //           } else {
    //             Notiflix.Loading.remove();
    //             Notiflix.Notify.failure("No Records Exits");
    //           }
    //         })
    //       );
    //       GetApiCall.getRequest("GetCustomerList").then((resultdes) =>
    //         resultdes.json().then((obj) => {
    //           if (obj.data.length != 0) {
    //             setAllOrdersHistory(obj.data);
    //
    //             Notiflix.Loading.remove();
    //           } else {
    //             Notiflix.Loading.remove();
    //             Notiflix.Notify.failure("No Records Exits");
    //           }
    //         })
    //       );
    //       GetApiCall.getRequest("GetCustomerFrequentlyOrder").then(
    //         (resultdes) =>
    //           resultdes.json().then((obj) => {
    //             if (obj.data.length != 0) {
    //               setCustomerFrequentlyOrder(obj.data);
    //
    //               Notiflix.Loading.remove();
    //             } else {
    //               Notiflix.Loading.remove();
    //               Notiflix.Notify.failure("No Records Exits");
    //             }
    //           })
    //       );
    //       setCustomerPersonalDetails(obj.data[0]);
    //
    //       Notiflix.Loading.remove();
    //     } else {
    //       Notiflix.Loading.remove();
    //       Notiflix.Notify.failure("No Records Exits");
    //     }
    //   })
    // );
  }, []);

  function onSaveAddress() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        CUSTOMERID: location.state.id,
        addressid: null,
        title: saveAddressAs,
        name: addressName,
        mobileNo: mobileNumber,
        flatno: address1,
        area: address2,
        landmark: landmark,
        country: country,
        state: state,
        city: city,
        pincode: pincode,
        action: "INSERT",
      },
      "updateCustomerAddress"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Address added successfully");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <section className="pb-lg-5 pt-4 margin-left">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="dash-heading">
                <h5 className="fw-bold text-black">Profile</h5>
                <p>Personal Data</p>
              </div>
            </Col>
            <Col lg={12} className="mt-4">
              <Card className="shadow border-light customer-dashboard-card">
                <Card.Body className="d-flex align-items-center">
                  <div>
                    <Card.Img src={profileImg} />
                    <h5 className="fw-bold text-black mt-3 text-center">
                      {name}
                    </h5>
                  </div>
                  <Card.Text className="d-flex justify-content-between w-75 px-5">
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex align-items-center mb-4">
                        <h6 className="fw-bold text-black w-50 mb-0">
                          Mobile Number
                        </h6>
                        <p className="text-black w-50">{phnNo}</p>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <h6 className="fw-bold text-black w-50 mb-0">Email</h6>
                        <p className="text-black w-50">{email}</p>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <h6 className="fw-bold text-black w-50 mb-0">Gender</h6>
                        <p className="text-black w-50">{gender}</p>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <h6 className="fw-bold text-black w-50 mb-0">
                          Date of Birth
                        </h6>
                        <p className="text-black w-50">{dob}</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6 className="text-black fw-bold mb-0 w-50">
                          Date of Anniversary
                        </h6>
                        <p className="text-black w-50">{doa}</p>
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col lg={4} className="mt-4">
              <Card className="shadow customer-dashboard-card h-100 profile-box">
                <Card.Body className="d-flex justify-content-between flex-column">
                  <div className="d-flex justify-content-between ">
                    <div className="profile-box-icon">
                      <MdOutlineLoyalty />
                    </div>
                    <div className="text-end">
                      <h2 className="fw-bold text-white">Loyalty Points</h2>
                      <h4 className="fw-bold text-white">
                        {customerLoyaltyDetails.fld_loyalty_points}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <h6 className="fw-bold text-white">
                      Placed {customerLoyaltyDetails.fld_placed_orders} Orders
                      Worth {currencyCode} {" "}
                      {customerLoyaltyDetails.fld_orders_worth}
                    </h6>
                    <h4 className="fw-bold text-white">
                      Saved {currencyCode} {" "}
                      {customerLoyaltyDetails.fld_saved_amount} so far
                    </h4>
                    <p className="fw-bold text-white border-top pt-2">
                      Member Since {customerLoyaltyDetails.fld_member_since}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            <Col lg={12} className="mt-5">
              <div className="d-flex justify-content-between align-items">
                <div className="dash-heading">
                  <h5 className="fw-bold text-black">Address Book</h5>
                  <p>List of Delivery Addresses</p>
                </div>
                <Button
                  variant="white"
                  onClick={() => {
                    handleShow();
                    setAddressId(null);
                  }}
                  className="kot-btn"
                >
                  Add New Address
                </Button>
              </div>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                //   freeMode={true}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper pb-5 pt-3"
              >
                {customerAddressDetails.map((address) => {
                  return (
                    <SwiperSlide>
                      <Card className="shadow border-light customer-dashboard-card">
                        <Card.Body>
                          <Card.Text>
                            <h5 className="fw-bold text-black">
                              {address.fld_contact_name}
                            </h5>
                            <p>
                              {address.fld_address_1 +
                                " " +
                                address.fld_address_2 +
                                " " +
                                " " +
                                address.fld_pincode}
                            </p>
                            <p className="text-black fw-bold mt-1">Landmark</p>
                            <p className="border-bottom pb-2">
                              {address.fld_landmark}
                            </p>
                            <div className="cus-das-btn">
                              <p>{address.fld_address_type}</p>
                              <AiOutlineEdit
                                onClick={() => {
                                  handleShow();
                                  setAddressId(address.fld_address_id);
                                }}
                              />
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
            {allOrdersHistory?.length > 0 ?
              <Col lg={12} className="mt-4">
                <div className="dash-heading mb-3">
                  <h5 className="fw-bold text-black">Order History</h5>
                  <p>List of Orders Placed</p>
                </div>
                <Card className="shadow border-light">
                  <Card.Body>
                    <Card.Text>
                      <div>
                        {allOrdersHistory.map((orderHistory) => {
                          return (
                            <div className="order-list-view">
                              <div className="d-flex justify-content-start gap-3">
                                <div>
                                  <div className="d-flex">
                                    <h6 className="me-2">Order No.</h6>
                                    <h6 className="text-danger">
                                      {orderHistory.fld_order_number}
                                    </h6>
                                  </div>
                                  <p>{orderHistory.fld_order_date}</p>
                                </div>
                              </div>
                              <div className="recent-orders-name">
                                <h6 className="fw-bold">
                                  {orderHistory.customer_name}
                                </h6>
                                <p>
                                  {orderHistory.fld_address_1 +
                                    " " +
                                    orderHistory.fld_address_2}
                                </p>
                              </div>
                              <div>
                                <h6 className="fw-bold">
                                  {currencyCode} {" "}
                                  {orderHistory.Total_Inclusive_Tax}
                                </h6>
                                <p>{orderHistory.total_items} Items</p>
                              </div>
                              <div>
                                <h6 className="fw-bold">Order Type</h6>
                                <p>{orderHistory.fld_order_type}</p>
                              </div>
                              <div>
                                <h6 className="fw-bold">Payment Details</h6>
                                <p>{orderHistory.fld_payment_mode}</p>
                              </div>

                              <div className="order-item-options">
                                <Button
                                  variant="white"
                                  onClick={() => {
                                    setOrderId(orderHistory.fld_cart_id);
                                    handleShowOrderHistory();
                                  }}
                                  className="recent-order-icon"
                                  id="dropdown-basic"
                                >
                                  <BsFillArrowRightCircleFill />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              : ""}
            {customerFrequentlyOrder?.length > 0 ?
              <><Col lg={12} className="mt-4">
                <div className="dash-heading mb-3">
                  <h5 className="fw-bold text-black">Frequently Ordered Items</h5>
                  <p>Favorite Food Items</p>
                </div>
              </Col><Col lg={12}>
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    //   freeMode={true}
                    loop={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper pb-5 pt-3"
                  >
                    {customerFrequentlyOrder.map((frequentlyOrder) => {
                      return (
                        <SwiperSlide>
                          <Card className="shadow border-light frequently-order-card">
                            <Card.Img
                              variant="top"
                              src={frequentlyOrder.FLD_Image} />
                            <Card.Body>
                              <Card.Title className="border-bottom pb-2">
                                {frequentlyOrder.FLD_Name}
                              </Card.Title>
                              <Card.Text>
                                Bought {frequentlyOrder.fld_item_count} times
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col></>
              : ""}
          </Row>
        </Container>
      </section>

      <AddressOffcanvas
        customerAddress={customerAddressDetails}
        show={show}
        addressId={addressId}
        setShow={setShow}
      />

      <OrderHistoryDrawer
        offcanvasshow={showOrderHistory}
        offcanvasclose={setShowOrderHistory}
        orderId={orderId}
      />
    </>
  );
}

export default CustomerDashboard;
